import * as Integrations from './Integrations';

export function getIntegrationIcon(
  integrationName: string,
  mode: 'card' | 'list' | 'single' | 'filter' | 'connection',
) {
  let temp = integrationName;
  if (temp === 'FILE') temp = 'File Upload';
  else if (temp === 'SAP_C4C') temp = 'SAP C4C';

  const iconName = temp.replace(' ', '');
  const Icon: (props: { viewBox: string; style?: Record<string, string> }) => JSX.Element =
    Integrations[iconName] ?? Integrations.Default;
  let viewBox: string;
  let style: Record<string, string> | undefined;
  if (mode === 'card') {
    viewBox = '0 0 40 40';
  } else if (mode === 'list') {
    viewBox = '-12 -12 64 64';
  } else if (mode === 'single') {
    viewBox = '-10 -10 60 60';
    style = { border: '1.67px solid #DADEE6', borderRadius: '3.33px', backgroundColor: '#FAFBFC' };
  } else if (mode === 'filter') {
    viewBox = '0 0 64 64';
  } else if (mode === 'connection') {
    viewBox = '0 0 40 40';
    style = { paddingBottom: '8px' };
  }
  return () => Icon({ viewBox, style });
}

export { default as HomeSidebar } from './Sidebar/Home'; // for a future feature
export { default as CatalogSidebar } from './Sidebar/Catalog';
export { default as HubSidebar } from './Sidebar/Hub';
export { default as FlowsSidebar } from './Sidebar/Flows';
export { default as MembersSidebar } from './Sidebar/Members';
export { default as MonitorSidebar } from './Sidebar/Monitor';
export { default as IntegrationsSidebar } from './Sidebar/Integrations';
export { default as ConnectionsSidebar } from './Sidebar/Connections';
export { default as SettingsSidebar } from './Sidebar/Settings';
export { default as UsageSidebar } from './Sidebar/Usage';
export { default as InsightsSidebar } from './Sidebar/InsightsSidebar';

export { default as CatalogContent } from './Content/CatalogContent';
export { default as HubContent } from './Content/HubContent';
export { default as FlowContent } from './Content/FlowContent';
export { default as MemberContent } from './Content/MemberContent';
export { default as MonitorContent } from './Content/MonitorContent';
export { default as IntegrationContent } from './Content/IntegrationContent';
export { default as ConnectionContent } from './Content/ConnectionContent';
export { default as ShareContent } from './Content/ShareContent';

export { default as SourceTable } from './SourceTable';
export { default as NewSourceTable } from './NewSourceTable';
export { default as dataObject } from './Content/CatalogContent';
export { default as Dataset } from './OldDataset';
export { default as dataset } from './OldDataset';
export { default as datasetTest } from './DatasetTest';
export { default as NewDataset } from './Dataset';

export { default as TriggerMANUAL } from './Triggers/Manual';
export { default as TriggerSCHEDULED } from './Triggers/Scheduled';
export { default as TriggerCRON } from './Triggers/Cron';
export { default as HubTriggerManual } from './DatasetTrigger/Manual'; // currently the icons used in the Hub page are different
export { default as HubTriggerScheduled } from './DatasetTrigger/Scheduled'; // but perhaps they can be replaced with the global icons above
export { default as HubTriggerCron } from './DatasetTrigger/Cron';

export { default as DataTypeBinary } from './DataTypes/Binary';
export { default as DataTypeBoolean } from './DataTypes/Boolean';
export { default as DataTypeDate } from './DataTypes/Date';
export { default as DataTypeDefault } from './DataTypes/Default';
export { default as DataTypeNumeric } from './DataTypes/Numeric';
export { default as DataTypeString } from './DataTypes/String';
export { default as DataTypeSuper } from './DataTypes/Super';
export { default as DataTypeTime } from './DataTypes/Time';
export { default as DataTypeTimestamp } from './DataTypes/Timestamp';

export { default as Sort } from './Sort';

export { default as TableRow } from './TableRow';
export { default as TableColumn } from './TableColumn';

export { default as Edit } from './Actions/Edit';
export { default as Rename } from './Actions/Rename';
export { default as Move } from './Actions/Move';
export { default as Delete } from './Actions/Delete';
export { default as Download } from './Actions/Download';
export { default as SortAction } from './Actions/Sort';
export { default as Filter } from './Actions/Filter';
export { default as Share } from './Actions/Share';

export { default as SuccessIndicator } from './StatusIndicator/Success';
export { default as ErrorIndicator } from './StatusIndicator/Error';
export { default as WarningIndicator } from './StatusIndicator/Warning';

export { default as ModalCreate } from './Modals/Create';
export { default as ModalEdit } from './Modals/Edit';
export { default as ModalMove } from './Modals/Move';
export { default as ModalDelete } from './Modals/Delete';
export { default as ModalDownload } from './Modals/Download';
export { default as ModalShare } from './Modals/Share';
export { default as ModalLock } from './Modals/Lock';
export { default as Folder } from './Folder';
export { default as EmptyFolder } from './EmptyFolder';
export { default as EmptyDataset } from './EmptyDataset';

export { default as CsvOff } from './DownloadModal/CsvOff';
export { default as CsvOn } from './DownloadModal/CsvOn';
export { default as XlsxOff } from './DownloadModal/XlsxOff';
export { default as XlsxOn } from './DownloadModal/XlsxOn';

export { default as TableViewData } from './TableView/Data';
export { default as TableViewTestResults } from './TableView/TestResults';
export { default as TableViewSorting } from './TableView/Sorting';
export { default as TableViewFiltering } from './TableView/Filtering';
export { default as TableViewDrawerArrow } from './TableView/DrawerArrow';
export { default as TableViewAddFilter } from './TableView/AddFilter';

export { default as EditorSearch } from './Editor/Search';
export { default as EditorSftp } from './Editor/Sftp';
export { default as EditorTable } from './Editor/Table';
export { default as EditorFolder } from './Editor/Folder';
export { default as EditorDataset } from './Editor/Dataset';
export { default as EditorSql } from './Editor/Sql';
export { default as EditorRun } from './Editor/Run';

export { default as Dropdown } from './Header/Dropdown';
export { default as Run } from './Header/Run';
export { default as Save } from './Header/Save';
export { default as Deploy } from './Header/Deploy';

export { default as FlowRun } from './Flows/FlowRowRun';
export { default as FlowSwitch } from './FlowView/switch';
export { default as FlowOn } from './FlowView/ON';
export { default as FlowOff } from './FlowView/OFF';
export { default as FlowArrow } from './FlowView/arrow';
export { default as FlowDropdownStatic } from './FlowView/FlowDropdownStatic';
export { default as FlowDropdownActive } from './FlowView/FlowDropdownActive';
export { default as TerminalIcon } from './FlowView/TerminalIcon';
export { default as CloseModalEditor } from './FlowView/CloseModalEditor';
export { default as PythonLogo } from './FlowView/PythonLogo';
export { default as FileIcon } from './FlowView/FileIcon';
export { default as EmptyFlowList } from './FlowView/EmptyFlowsIcon';

export { default as RightOutlinedStatic } from './RightOutlinedStatic';
export { default as RightOutlined } from './RightOutlined';
export { default as EllipsisStatic } from './EllipsisStatic';
export { default as EllipsisActive } from './EllipsisActive';
export { default as Plus } from './Plus';
export { default as Connection } from './Connection';

export { default as ProfileUser } from './ProfileSettings/User';
export { default as ProfileLock } from './ProfileSettings/Lock';
export { default as ProfileCloseButton } from './ProfileSettings/CloseButton';
export { default as ProfileDropdownStatic } from './ProfileSettings/DropdownStatic';
export { default as ProfileDropdownActive } from './ProfileSettings/DropdownActive';

export { default as OrgSetGeneral } from './OrgSettings/General';
export { default as OrgSetWarehouseStorage } from './OrgSettings/WarehouseStorage';

export { default as ArrowBackCircle } from './ArrowBackCircle';
export { default as CredentialsError } from './CredentialsError';

export { default as WorkspaceSwitcherAdd } from './WorkspaceSwitcher/Add';

export { default as TableActionAdd } from './TableAction/Add';
export { default as TableActionMinus } from './TableAction/Minus';

export { default as WarningIcon } from './WarningIcon';
export { default as SuccessIcon } from './SuccessIcon';
export { default as ErrorIcon } from './ErrorIcon';
export { default as InfoCircleIcon } from './InfoCircleIcon';

export { default as GreyEvent } from './Monitor/GreyEvent';
export { default as RefreshIcon } from './Monitor/RefreshIcon';
export { default as FilterIcon } from './Monitor/FilterIcon';

export { default as NotificationIcon } from './Notifications/NotificationIcon';
export { default as NotificationIconActive } from './Notifications/NotificationIconActive';
export { default as ShareIcon } from './Share';
export { default as ShareModalIcon } from './ShareIcon';

export { default as PlusSmall } from './PlusSmall';
export { default as DatasetTest } from './DatasetTestIcon';
export { default as Drawer } from './DrawerIcon';
export { default as Documentation } from './Documentation';
export { default as BackArrow } from './BackArrow';

export { default as TriggerPanelIcon } from './TriggerPanel';
export { default as SearchPanelIcon } from './SearchPanel';
export { default as DatasetTestPanelIcon } from './DatasetTestPanel';

export { default as DefaultIntegrationIcon } from './DefaultIntegrationIcon';
export { default as ScheduledFlowListIcon } from './TriggerTimer';
export { default as ManualFlowListIcon } from './TriggerManual';
export { default as SuccessFlowListIcon } from './SuccessFlowList';
export { default as FailFlowListIcon } from './FailFlowList';

export { default as OpenInNewTabIcon } from './OpenInNewTab';
export { default as SyncIcon } from './SyncIcon';
export { default as SyncBar } from './SyncBar';
export { default as AddButton } from './AddButton';
export { default as AdvancedConfigurationIcon } from './AdvancedConfigurationIcon';

export { default as ConnectionsFilterIcon } from './Connections/ConnectionsFilterIcon';
export { default as ConnectionsIcon } from './Connections/ConnectionIcon';
export { default as ConnectionSuccess } from './Connections/Success';
export { default as ConnectionFailure } from './Connections/Failed';

export { default as ChevronPathTop } from './WorkspaceSwitcher/PathTop';
export { default as ChevronPathBottom } from './WorkspaceSwitcher/PathBottom';
export { default as UserIcon } from './UserIcon';
export { default as UserChevron } from './UserChevron';

export { default as DeleteIcon } from './DeleteIcon';

export { default as SortAscending } from './SortAscending';
export { default as SortDescending } from './SortDescending';

export { default as Shield } from './Shield';
export { default as Send } from './Send';
export { default as Number123 } from './123';
export { default as IconColumn } from './IconColumn';
export { default as IconObject } from './IconObject';
export { default as Data } from './Data';
export { default as Code } from './Code';
export { default as Copy } from './Copy';

export { default as AddUserIcon } from './AddUserIcon';
