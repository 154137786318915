/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
import type {
  CreateIDatasetUserInput,
  CreateOrganizationUserInput,
  CreateUserInput,
  CreateWorkspaceUserInput,
  ListIDatasetUsersOnlyAuth0MigrationQuery,
  ListOrganizationUsersOnlyAuth0MigrationQuery,
  ListUsersOnlyAuth0MigrationQuery,
  ListWorkspaceUsersOnlyAuth0MigrationQuery,
} from '@/api';
import { getDvaApp } from 'umi';
import { Auth } from '@/aws-config';
import User from '@/services/user';
import Workspace from '@/services/workspace';
// import Source from '@/services/source';
// import DataObject from '@/services/dataObject';
import Dataset from '@/services/dataset';
import { graphql } from './graphql';
import { assertIsDefined, assertIsString } from './typeChecks';

async function checkAllowedSuperAdminUser(): Promise<void> {
  const userId = (await Auth.currentAuthenticatedUser()).getUsername();
  if (
    userId !== '3ce2df7a-cd74-48d4-a366-23d03d4bfaca' &&
    userId !== 'auth0_auth0|6706f3026fa73bd80d34e01e'
  ) {
    throw new Error('checkAllowedSuperAdminUser failed');
  }
}

export async function __graphql(command: any, variables: any, rnt = false): Promise<unknown> {
  await checkAllowedSuperAdminUser();
  return graphql(command, variables, rnt);
}

export async function refreshAllDatasetsInGivenFolder(folderId: string) {
  const datasets = await Dataset.getDatasetsByFolder(folderId);
  return await Promise.all(
    datasets.map(async (dataset) => {
      return await Dataset.refresh(dataset.cloudResourceId);
    }),
  );
}

export async function updateCronExpressionForAllDatasetsWithNamePrefix(
  namePrefix: string,
  hoursToAdd: number,
  minutesToAdd: number,
  confirmed = false,
) {
  const reduxState = getDvaApp()._store.getState().organization;
  const { currentWorkspace } = reduxState;
  assertIsString(currentWorkspace, 'BEEM231103210252');
  let datasets = await Dataset.getDatasetsOwnByWorkspace(currentWorkspace);
  datasets = datasets.filter((el) => el.name.toLowerCase().startsWith(namePrefix.toLowerCase()));
  for (let i = 0; i < datasets.length; i += 1) {
    const { id, name, trigger, triggerDetails } = datasets[i];
    if (trigger === 'CRON') {
      const currentCron = triggerDetails.cron;
      const cronComponents = currentCron.split(' ');
      cronComponents[0] = String(parseInt(cronComponents[0], 10) + minutesToAdd);
      cronComponents[1] = String(parseInt(cronComponents[1], 10) + hoursToAdd);
      const newCron = cronComponents.join(' ');
      console.log(`beemTools: ${id} | ${name} | ${currentCron} | ${newCron}`);
      if (confirmed) {
        const dataset = await Dataset.getDatasetById(id);
        const sqlQuery = await dataset.getSavedCode();
        await dataset.deploy({
          sqlQuery,
          trigger,
          triggerDetails: { ...triggerDetails, cron: newCron },
        });
        console.log(`beemTools: new cron is deployed`);
      }
    }
  }
}

export async function createUserIfNotExist(email: string, workspaceId: string): Promise<void> {
  await checkAllowedSuperAdminUser();
  const workspace = await Workspace.getWorkspaceById(workspaceId);
  await User.createIfNotExist(email, workspace);
}

// vinh1518 remove
export async function processUsersList() {
  const data = {
    Users: [
      {
        Username: '020d6f71-1614-4cd6-9ef5-8cff0c95db01',
        Attributes: [
          {
            Name: 'email',
            Value: 'mgrigori@legroupemaurice.com',
          },
        ],
        UserCreateDate: '2023-09-12T14:32:13.905000-04:00',
        UserLastModifiedDate: '2023-09-14T14:06:06.815000-04:00',
        Enabled: true,
        UserStatus: 'CONFIRMED',
      },
    ],
  };

  const findEmail = (el: {
    Username: string;
    Attributes: {
      Name: string;
      Value: string;
    }[];
    UserCreateDate: string;
    UserLastModifiedDate: string;
    Enabled: boolean;
    UserStatus: string;
  }) => {
    const emailAttribute = el.Attributes.find((at) => at.Name === 'email');
    assertIsDefined(emailAttribute, 'BEEM241009215830');
    const email = emailAttribute.Value;
    return email;
  };

  const cognitoUsers = data.Users.filter((el) => !el.Username.startsWith('auth0'));
  const enabledUsers = cognitoUsers.filter((el) => el.Enabled);
  const beemUsers = enabledUsers.filter((el) => {
    const email = findEmail(el);
    return email.endsWith('@beemhq.com');
  });

  const users = beemUsers;
  const results: { email: string; cognitoId: string; auth0Id: string }[] = [];
  users.forEach((el) => {
    results.push({ email: findEmail(el), cognitoId: el.Username, auth0Id: '' });
  });
  console.log('results', results);
}

// vinh1518 remove
export async function migrateUserId(reverse = false) {
  await checkAllowedSuperAdminUser();

  const usersList: { email: string; cognitoId: string; auth0Id: string }[] = [
    {
      email: 'eric.gagnon@beemhq.com',
      cognitoId: '0433aacc-31eb-4372-978c-cfefd87f8801',
      auth0Id: 'auth0_auth0|6708030098d96dce6c96e789',
    },
    {
      email: 'kevin.ferah@beemhq.com',
      cognitoId: '0fda6951-8e74-4467-a5c3-45d7e890b843',
      auth0Id: 'auth0_auth0|670803013ca6be49f4afceaa',
    },
    {
      email: 'benoit.tanguay@beemhq.com',
      cognitoId: '11b94a54-d562-4478-8d6c-5f31b8850f05',
      auth0Id: 'auth0_auth0|67080301bcbe9ec4fa4c7e42',
    },
    {
      email: 'martin.cote@beemhq.com',
      cognitoId: '135d3fab-1fb3-4474-a3c7-4ae31f687b2a',
      auth0Id: 'auth0_auth0|670803026470e5be06b7658c',
    },
    {
      email: 'nadia.elhaili@beemhq.com',
      cognitoId: '40387ad5-35c3-41bf-86f6-e2683035033e',
      auth0Id: 'auth0_auth0|67080302d29bc06bea71ffca',
    },
    {
      email: 'steven.wong@beemhq.com',
      cognitoId: '49bd5dbf-6a2c-4f86-95a2-cdc2a9750123',
      auth0Id: 'auth0_auth0|670803026c0c877d51f317dd',
    },
    {
      email: 'taley.ahmed@beemhq.com',
      cognitoId: '5a37cfb5-f5c0-47bb-9138-3e480f2c2906',
      auth0Id: 'auth0_auth0|670803033ca6be49f4afceaf',
    },
    {
      email: 'greg.hamel@beemhq.com',
      cognitoId: '6764dd03-aa20-4eac-8a12-ea29eae819c5',
      auth0Id: 'auth0_auth0|670803038364023ade4bf777',
    },
    {
      email: 'sunil.boisvert@beemhq.com',
      cognitoId: '6fa31ccd-dfb7-4f16-a47e-ad9208edea67',
      auth0Id: 'auth0_auth0|670803036470e5be06b7658d',
    },
    {
      email: 'alexandre.lataille@beemhq.com',
      cognitoId: '91c1778c-a747-46ed-a9db-d43e9bcd2786',
      auth0Id: 'auth0_auth0|670803046c0c877d51f317e0',
    },
    {
      email: 'bao-anh.bui@beemhq.com',
      cognitoId: '9806f08d-eb83-4530-8b08-b65ab828c942',
      auth0Id: 'auth0_auth0|6708030478be191ff139d132',
    },
    {
      email: 'maxim.normandin@beemhq.com',
      cognitoId: 'c2af9d0b-10dc-4a86-a3e4-1a5734c20faa',
      auth0Id: 'auth0_auth0|6708030447da86e649292f08',
    },
    {
      email: 'maxime.gibeau@beemhq.com',
      cognitoId: 'd966a22e-2eb0-4a0f-8bd5-8bc0d0bc2c01',
      auth0Id: 'auth0_auth0|6708030576f93aadc3180213',
    },
    {
      email: 'christina.hage@beemhq.com',
      cognitoId: 'df70aed6-ec79-4233-b0ab-e723d3491160',
      auth0Id: 'auth0_auth0|6708030547dca5d30d1c3970',
    },
    {
      email: 'tyler.fitzgerald@beemhq.com',
      cognitoId: 'e64b6bb0-ca5c-472d-a924-07cea8664f54',
      auth0Id: 'auth0_auth0|67080305740b798d57dfd04b',
    },
  ];
  const userIdMap: Record<string, string> = {};
  usersList.forEach((el) => {
    if (!reverse) userIdMap[el.cognitoId] = el.auth0Id;
    else userIdMap[el.auth0Id] = el.cognitoId;
  });

  async function updateOneField(table: 'Dataset' | 'DatasetTest' | 'Connection') {
    let nextToken: string | undefined;
    do {
      const rs = await graphql(`list${table}sOnlyAuth0Migration`, { nextToken });
      const { items } = rs;
      for (let i = 0; i < items.length; i += 1) {
        const { id, ownerId } = items[i];
        if (userIdMap[ownerId]) {
          console.log(`beemTools: updateOneField ${table} id ${id} YES`);
          await graphql(`update${table}`, { input: { id, ownerId: userIdMap[ownerId] } });
        } else {
          console.log(`beemTools: updateOneField ${table} id ${id} NO`);
        }
      }
      nextToken = rs.nextToken;
    } while (nextToken);
  }

  await updateOneField('Dataset');
  await updateOneField('DatasetTest');
  await updateOneField('Connection');

  if (Date.now() > 0) {
    // config
    const table = 'User';
    const unpack = (
      item: ListUsersOnlyAuth0MigrationQuery['listUsers']['items'][0],
    ): { userId: string; createInput: CreateUserInput } => {
      const userId = item.id;
      return {
        userId,
        createInput: {
          id: userIdMap[userId],
          email: item.email,
          firstName: item.firstName ?? '',
          lastName: item.lastName ?? '',
        },
      };
    };
    // repetitive logic
    let nextToken: string | undefined;
    do {
      const rs = await graphql(`list${table}sOnlyAuth0Migration`, { nextToken });
      const { items } = rs;
      for (let i = 0; i < items.length; i += 1) {
        const { id } = items[i];
        const { userId, createInput } = unpack(items[i]);
        if (userIdMap[userId]) {
          console.log(`beemTools: updateOneField ${table} id ${id} YES`);
          await graphql(`delete${table}`, { input: { id } });
          await graphql(`create${table}`, { input: createInput });
        } else {
          console.log(`beemTools: updateOneField ${table} id ${id} NO`);
        }
      }
      nextToken = rs.nextToken;
    } while (nextToken);
  }

  if (Date.now() > 0) {
    // config
    const table = 'OrganizationUser';
    const unpack = (
      item: ListOrganizationUsersOnlyAuth0MigrationQuery['listOrganizationUsers']['items'][0],
    ): { userId: string; createInput: CreateOrganizationUserInput } => {
      const { userId } = item;
      return {
        userId,
        createInput: {
          id: `${item.organizationId}#${userIdMap[userId]}`,
          organizationId: item.organizationId,
          userId: userIdMap[userId],
          hasWarehouseCredentials: item.hasWarehouseCredentials ?? false,
        },
      };
    };
    // repetitive logic
    let nextToken: string | undefined;
    do {
      const rs = await graphql(`list${table}sOnlyAuth0Migration`, { nextToken });
      const { items } = rs;
      for (let i = 0; i < items.length; i += 1) {
        const { id } = items[i];
        const { userId, createInput } = unpack(items[i]);
        if (userIdMap[userId]) {
          console.log(`beemTools: updateOneField ${table} id ${id} YES`);
          await graphql(`delete${table}`, { input: { id } });
          await graphql(`create${table}`, { input: createInput });
        } else {
          console.log(`beemTools: updateOneField ${table} id ${id} NO`);
        }
      }
      nextToken = rs.nextToken;
    } while (nextToken);
  }

  if (Date.now() > 0) {
    // config
    const table = 'WorkspaceUser';
    const unpack = (
      item: ListWorkspaceUsersOnlyAuth0MigrationQuery['listWorkspaceUsers']['items'][0],
    ): { userId: string; createInput: CreateWorkspaceUserInput } => {
      const { userId } = item;
      return {
        userId,
        createInput: {
          id: `${item.workspaceId}#${userIdMap[userId]}`,
          workspaceId: item.workspaceId,
          userId: userIdMap[userId],
        },
      };
    };
    // repetitive logic
    let nextToken: string | undefined;
    do {
      const rs = await graphql(`list${table}sOnlyAuth0Migration`, { nextToken });
      const { items } = rs;
      for (let i = 0; i < items.length; i += 1) {
        const { id } = items[i];
        const { userId, createInput } = unpack(items[i]);
        if (userIdMap[userId]) {
          console.log(`beemTools: updateOneField ${table} id ${id} YES`);
          await graphql(`delete${table}`, { input: { id } });
          await graphql(`create${table}`, { input: createInput });
        } else {
          console.log(`beemTools: updateOneField ${table} id ${id} NO`);
        }
      }
      nextToken = rs.nextToken;
    } while (nextToken);
  }

  if (Date.now() > 0) {
    // config
    const table = 'IDatasetUser';
    const unpack = (
      item: ListIDatasetUsersOnlyAuth0MigrationQuery['listIDatasetUsers']['items'][0],
    ): { userId: string; createInput: CreateIDatasetUserInput } => {
      let objType: string;
      if (item.id.startsWith('dataObjects')) objType = 'dataObjects';
      else if (item.id.startsWith('dataset')) objType = 'dataset';
      else if (item.id.startsWith('datasetTests')) objType = 'datasetTests';
      else throw new Error(`BEEM241009212400|${item.id}`);
      const { userId } = item;
      return {
        userId,
        createInput: {
          id: `${objType}${item.datasetId}#${userIdMap[userId]}`,
          datasetId: item.datasetId,
          userId: userIdMap[userId],
          options: item.options,
        },
      };
    };
    // repetitive logic
    let nextToken: string | undefined;
    do {
      const rs = await graphql(`list${table}sOnlyAuth0Migration`, { nextToken });
      const { items } = rs;
      for (let i = 0; i < items.length; i += 1) {
        const { id } = items[i];
        const { userId, createInput } = unpack(items[i]);
        if (userIdMap[userId]) {
          console.log(`beemTools: updateOneField ${table} id ${id} YES`);
          await graphql(`delete${table}`, { input: { id } });
          await graphql(`create${table}`, { input: createInput });
        } else {
          console.log(`beemTools: updateOneField ${table} id ${id} NO`);
        }
      }
      nextToken = rs.nextToken;
    } while (nextToken);
  }
}

// disabled on 240215 => to remove after 250215
// export async function migrateConnectionsToBackfillWorkspaceId(
//   pOrgId = '',
//   pConnectionId = '',
//   confirmed = false,
// ) {
//   async function f1() {
//     const orgIds: string[] = [];
//     let nextToken: string | undefined;
//     do {
//       const rs = await graphql('listOrganizations', { nextToken });
//       const ids = rs.items.map((el) => el.id);
//       if (ids.length) orgIds.push(...ids);
//       nextToken = rs.nextToken;
//     } while (nextToken);
//     return orgIds;
//   }

//   async function f2(orgId: string) {
//     const connections: { id: string; workspaceId?: string }[] = [];
//     let nextToken2: string | undefined;
//     do {
//       const rs2 = await graphql('listConnectionsByOrganizationFivetranOnlyTwo', {
//         organizationId: orgId,
//         nextToken: nextToken2,
//       });
//       const news = rs2.items.map((el) => ({ id: el.id, workspaceId: el.workspaceId }));
//       if (news.length) connections.push(...news);
//       nextToken2 = rs2.nextToken;
//     } while (nextToken2);
//     return connections;
//   }

//   async function f3(orgId: string) {
//     const connections: { id: string; workspaceId?: string }[] = [];
//     let nextToken3: string | undefined;
//     do {
//       const rs3 = await graphql('listConnectionsByOrganizationNativeOnlyTwo', {
//         organizationId: orgId,
//         nextToken: nextToken3,
//       });
//       const news = rs3.items.map((el) => ({ id: el.id, workspaceId: el.workspaceId }));
//       if (news.length) connections.push(...news);
//       nextToken3 = rs3.nextToken;
//     } while (nextToken3);
//     return connections;
//   }

//   const defaultWorkspace = {
//     '5040ddc8-5331-48d5-9efe-b1f13f004939': 'ef040ff5-8c68-4756-b5b7-3e2c42701f9f',
//     '521f2c4a-a0d6-48de-8459-4e4d74663d93': '5afc0d4d-d902-4124-a704-c27b7f0cf698',
//     'db1ebcda-1462-4965-813e-2fdf10990083': '254c6601-25a4-4bef-a56c-1062c4deb766',
//     'f2e9ba97-f3ca-476c-bc24-8e89958c41f5': '6528da8f-f41a-4e41-8b4a-8c47782116db',
//     '8ae0194d-8b90-49eb-8847-af9bdac5bf8b': '0da1c390-d1cc-40ac-a50a-af622a8f7ad8',
//     '1ae52fd2-d036-416c-be9d-c3d60142a656': '2e003d0f-20b8-45d3-96ee-d6a2e9c7b899',
//     'bec0f9b6-8980-444c-9f96-86886175758f': '4ed777f0-688e-489e-8896-b398a812b729',
//     '5c248273-b60e-4ea0-96f3-0c11884422ee': 'bb13d430-177a-4958-a695-5d7ffe01e46f',
//     '7897454a-e995-4f2f-86c9-6c553f9aa8bc': '16a9c6da-c52b-4997-8612-a4b185c635f6',
//   };

//   const userId = (await Auth.currentAuthenticatedUser()).getUsername();
//   if (
//     userId !== '3ce2df7a-cd74-48d4-a366-23d03d4bfaca' &&
//     userId !== '7c5d65b8-c051-7002-99cf-a917ffccb7ab'
//   )
//     return;

//   const orgIds = await f1();
//   for (let i = 0; i < orgIds.length; i += 1) {
//     const orgId = orgIds[i];
//     // eslint-disable-next-line no-continue
//     if (pOrgId && pOrgId !== orgId) continue;
//     const connections = (await f2(orgId)).concat(await f3(orgId));
//     for (let j = 0; j < connections.length; j += 1) {
//       const { id, workspaceId } = connections[j];
//       // eslint-disable-next-line no-continue
//       if (pConnectionId && pConnectionId !== id) continue;
//       if (!workspaceId) {
//         const sources = await graphql('listSourcesByConnection', { connectionId: id });
//         if (sources.items.length > 1) {
//           console.log(`beemTools: ${orgId} | ${id} | INVALID | sources.items.length > 1`);
//         } else {
//           let workspaceIdToSet: string;
//           if (sources.items.length === 1) {
//             const source = await graphql('getSource', { id: sources.items[0].id });
//             workspaceIdToSet = source.workspaceId;
//           } else {
//             workspaceIdToSet = defaultWorkspace[orgId];
//           }
//           console.log(
//             `beemTools: ${orgId} | ${id} | ${workspaceIdToSet} | ${sources.items.length}`,
//           );
//           if (confirmed) {
//             await graphql('updateConnection', { input: { id, workspaceId: workspaceIdToSet } });
//             console.log(`beemTools: new workspaceId is set`);
//           }
//         }
//       }
//     }
//   }
// }

// disabled on 240325 => to remove after 250325
// export async function migrateDataObjectsToBackfillWorkspaceIdAndViewName(confirmed = false) {
//   async function f1() {
//     const doIds: string[] = [];
//     let nextToken: string | undefined;
//     do {
//       const rs = await graphql('listDataObjects', { nextToken });
//       const ids = rs.items.map((el) => el.id);
//       if (ids.length) doIds.push(...ids);
//       nextToken = rs.nextToken;
//     } while (nextToken);
//     return doIds;
//   }

//   const userId = (await Auth.currentAuthenticatedUser()).getUsername();
//   if (
//     userId !== '3ce2df7a-cd74-48d4-a366-23d03d4bfaca' &&
//     userId !== '7c5d65b8-c051-7002-99cf-a917ffccb7ab'
//   )
//     return;

//   const doIds = await f1();
//   const workspaceIdBySourceId: Record<string, string> = {};
//   for (let i = 0; i < doIds.length; i += 1) {
//     const doId = doIds[i];
//     const dataObj = await DataObject.getDataObjectById(doId);
//     const { sourceId } = dataObj;
//     const viewName = dataObj.dbViewName;
//     if (!workspaceIdBySourceId[sourceId]) {
//       const source = await Source.getSourceById(sourceId);
//       workspaceIdBySourceId[sourceId] = source.workspaceId;
//     }
//     const workspaceId = workspaceIdBySourceId[sourceId];
//     console.log(`beemTools: ${doId} | ${viewName} | ${workspaceId}`);

//     if (confirmed) {
//       await graphql('updateDataObject', { input: { id: doId, viewName, workspaceId } });
//       console.log(`beemTools: viewName and workspaceId are set`);
//     }
//   }
// }
