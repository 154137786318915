/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string,
  email: string,
  firstName?: string,
  lastName?: string,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput,
  firstName?: ModelStringInput,
  lastName?: ModelStringInput,
  and?: Array< ModelUserConditionInput >,
  or?: Array< ModelUserConditionInput >,
  not?: ModelUserConditionInput,
};

export type ModelStringInput = {
  ne?: string,
  eq?: string,
  le?: string,
  lt?: string,
  ge?: string,
  gt?: string,
  contains?: string,
  notContains?: string,
  between?: Array< string >,
  beginsWith?: string,
  attributeExists?: boolean,
  attributeType?: ModelAttributeTypes,
  size?: ModelSizeInput,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number,
  eq?: number,
  le?: number,
  lt?: number,
  ge?: number,
  gt?: number,
  between?: Array< number >,
};

export type User = {
  __typename?: "User",
  id: string,
  email: string,
  firstName?: string,
  lastName?: string,
  createdAt: string,
  updatedAt: string,
  organizations?: ModelOrganizationUserConnection,
  workspaces?: ModelWorkspaceUserConnection,
};

export type ModelOrganizationUserConnection = {
  __typename?: "ModelOrganizationUserConnection",
  items:  Array<OrganizationUser >,
  nextToken?: string,
};

export type OrganizationUser = {
  __typename?: "OrganizationUser",
  id: string,
  organizationId: string,
  userId: string,
  hasWarehouseCredentials?: boolean,
  createdAt: string,
  updatedAt: string,
  user: User,
  organization: Organization,
};

export type Organization = {
  __typename?: "Organization",
  id: string,
  name: string,
  color: string,
  workspaceLimit: number,
  warehouseRetention?: number,
  timezone: string,
  cloudResourceId: string,
  createdAt: string,
  updatedAt: string,
  users?: ModelOrganizationUserConnection,
  workspaces?: ModelWorkspaceConnection,
};

export type ModelWorkspaceConnection = {
  __typename?: "ModelWorkspaceConnection",
  items:  Array<Workspace >,
  nextToken?: string,
};

export type Workspace = {
  __typename?: "Workspace",
  id: string,
  organizationId: string,
  name: string,
  seq: number,
  createdAt: string,
  updatedAt: string,
  organization: Organization,
  users?: ModelWorkspaceUserConnection,
  sources?: ModelSourceConnection,
  sharedDatasetsFromWorkspaces?: ModelDatasetShareFromWorkspaceWithWorkspaceLinkConnection,
  sharedDatasetsWithWorkspaces?: ModelDatasetShareFromWorkspaceWithWorkspaceLinkConnection,
};

export type ModelWorkspaceUserConnection = {
  __typename?: "ModelWorkspaceUserConnection",
  items:  Array<WorkspaceUser >,
  nextToken?: string,
};

export type WorkspaceUser = {
  __typename?: "WorkspaceUser",
  id: string,
  workspaceId: string,
  userId: string,
  createdAt: string,
  updatedAt: string,
  user: User,
  workspace: Workspace,
};

export type ModelSourceConnection = {
  __typename?: "ModelSourceConnection",
  items:  Array<Source >,
  nextToken?: string,
};

export type Source = {
  __typename?: "Source",
  id: string,
  name: string,
  description?: string,
  connectionId: string,
  workspaceId: string,
  cloudResourceId: string,
  createdAt: string,
  updatedAt: string,
  workspace: Workspace,
  dataObjects?: ModelDataObjectConnection,
  connection: Connection,
};

export type ModelDataObjectConnection = {
  __typename?: "ModelDataObjectConnection",
  items:  Array<DataObject >,
  nextToken?: string,
};

export type DataObject = {
  __typename?: "DataObject",
  id: string,
  sourceId: string,
  name: string,
  viewName: string,
  workspaceId: string,
  cloudResourceId: string,
  cloudResourceInfo?: string,
  createdAt: string,
  updatedAt: string,
  source: Source,
  downloads?: ModelDownloadConnection,
};

export type ModelDownloadConnection = {
  __typename?: "ModelDownloadConnection",
  items:  Array<Download >,
  nextToken?: string,
};

export type Download = {
  __typename?: "Download",
  id: string,
  dataId: string,
  dataTimeStamp: number,
  requesterId?: string,
  requestTimeStamp: number,
  signedDownloadUrl: string,
  createdAt: string,
  updatedAt: string,
  requester: User,
};

export type Connection = {
  __typename?: "Connection",
  id: string,
  name?: string,
  organizationId: string,
  workspaceId: string,
  ownerId: string,
  fivetranConnectionId?: string,
  integrationId?: string,
  credentialsId?: string,
  isDeleted?: boolean,
  createdAt: string,
  updatedAt: string,
  owner: User,
  organization: Organization,
  workspace: Workspace,
  flows?: ModelFlowConnection,
  integration?: Integration,
  fivetranConnection?: FivetranConnection,
  credentials?: Credentials,
};

export type ModelFlowConnection = {
  __typename?: "ModelFlowConnection",
  items:  Array<Flow >,
  nextToken?: string,
};

export type Flow = {
  __typename?: "Flow",
  id: string,
  datasetId: string,
  name: string,
  enabled: boolean,
  trigger: Trigger,
  triggerDetails?: string,
  workspaceId: string,
  destinationId: string,
  cloudResourceId: string,
  cloudResourceInfo?: string,
  createdAt: string,
  updatedAt: string,
  workspace: Workspace,
  dataset: Dataset,
  destination: Connection,
};

export enum Trigger {
  MANUAL = "MANUAL",
  SCHEDULED = "SCHEDULED",
  CRON = "CRON",
  EVENT = "EVENT",
}


export type Dataset = {
  __typename?: "Dataset",
  id: string,
  folderId: string,
  name: string,
  viewName: string,
  isDraft: boolean,
  description?: string,
  comments?: string,
  columnsDocumentation?: string,
  trigger: Trigger,
  triggerDetails?: string,
  ownerId: string,
  workspaceId: string,
  sharedWithWorkspaceIds?: Array< string >,
  cloudResourceId: string,
  cloudResourceInfo?: string,
  parents?: string,
  enableSearchByAI?: boolean,
  createdAt: string,
  updatedAt: string,
  owner: User,
  workspace: Workspace,
  flows?: ModelFlowConnection,
  folder?: Folder,
  downloads?: ModelDownloadConnection,
};

export type Folder = {
  __typename?: "Folder",
  id: string,
  name: string,
  parentId: string,
  createdAt: string,
  updatedAt: string,
  datasets?: ModelDatasetConnection,
  parent?: Folder,
  subFolders?: ModelFolderConnection,
};

export type ModelDatasetConnection = {
  __typename?: "ModelDatasetConnection",
  items:  Array<Dataset >,
  nextToken?: string,
};

export type ModelFolderConnection = {
  __typename?: "ModelFolderConnection",
  items:  Array<Folder >,
  nextToken?: string,
};

export type Integration = {
  __typename?: "Integration",
  id: string,
  name: string,
  type: IntegrationType,
  category: IntegrationCategory,
  description?: string,
  dataSourceSupport: boolean,
  flowActions?: Array< string >,
  fake?: ColumnDataType,
  createdAt: string,
  updatedAt: string,
};

export enum IntegrationType {
  FIRST_PARTY = "FIRST_PARTY",
  THIRD_PARTY = "THIRD_PARTY",
}


export enum IntegrationCategory {
  accounting = "accounting",
  analytics = "analytics",
  business = "business",
  customer_support = "customer_support",
  crm = "crm",
  database = "database",
  email_newsletter = "email_newsletter",
  forms_surveys = "forms_surveys",
  project_management = "project_management",
  marketing_automation = "marketing_automation",
  task_management = "task_management",
}


export enum ColumnDataType {
  SMALLINT = "SMALLINT",
  INT2 = "INT2",
  INTEGER = "INTEGER",
  INT = "INT",
  INT4 = "INT4",
  BIGINT = "BIGINT",
  INT8 = "INT8",
  DECIMAL = "DECIMAL",
  NUMERIC = "NUMERIC",
  TINYINT = "TINYINT",
  REAL = "REAL",
  FLOAT4 = "FLOAT4",
  DOUBLE_PRECISION = "DOUBLE_PRECISION",
  FLOAT8 = "FLOAT8",
  FLOAT = "FLOAT",
  DOUBLE = "DOUBLE",
  BOOLEAN = "BOOLEAN",
  BOOL = "BOOL",
  CHAR = "CHAR",
  CHARACTER = "CHARACTER",
  NCHAR = "NCHAR",
  BPCHAR = "BPCHAR",
  VARCHAR = "VARCHAR",
  CHARACTER_VARYING = "CHARACTER_VARYING",
  NVARCHAR = "NVARCHAR",
  TEXT = "TEXT",
  STRING = "STRING",
  DATE = "DATE",
  TIMESTAMP = "TIMESTAMP",
  TIMESTAMP_WITHOUT_TIME_ZONE = "TIMESTAMP_WITHOUT_TIME_ZONE",
  TIMESTAMPTZ = "TIMESTAMPTZ",
  TIMESTAMP_WITH_TIME_ZONE = "TIMESTAMP_WITH_TIME_ZONE",
  TIME = "TIME",
  TIME_WITHOUT_TIME_ZONE = "TIME_WITHOUT_TIME_ZONE",
  TIMETZ = "TIMETZ",
  TIME_WITH_TIME_ZONE = "TIME_WITH_TIME_ZONE",
  VARBYTE = "VARBYTE",
  VARBINARY = "VARBINARY",
  BINARY_VARYING = "BINARY_VARYING",
  BINARY = "BINARY",
  GEOMETRY = "GEOMETRY",
  GEOGRAPHY = "GEOGRAPHY",
  HLLSKETCH = "HLLSKETCH",
  SUPER = "SUPER",
  ARRAY = "ARRAY",
  MAP = "MAP",
  STRUCT = "STRUCT",
  UNKNOWN = "UNKNOWN",
}


export type FivetranConnection = {
  __typename?: "FivetranConnection",
  id: string,
  name: string,
  serviceName: string,
  glueDatabaseName: string,
  integrationName: string,
  isDeployed?: boolean,
  isDeleted?: boolean,
  createdAt: string,
  updatedAt: string,
};

export type Credentials = {
  __typename?: "Credentials",
  id: string,
  username: string,
  password: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelDatasetShareFromWorkspaceWithWorkspaceLinkConnection = {
  __typename?: "ModelDatasetShareFromWorkspaceWithWorkspaceLinkConnection",
  items:  Array<DatasetShareFromWorkspaceWithWorkspaceLink >,
  nextToken?: string,
};

export type DatasetShareFromWorkspaceWithWorkspaceLink = {
  __typename?: "DatasetShareFromWorkspaceWithWorkspaceLink",
  id: string,
  fromWorkspaceId: string,
  withWorkspaceId: string,
  createdAt: string,
  updatedAt: string,
  fromWorkspace: Workspace,
  withWorkspace: Workspace,
};

export type UpdateUserInput = {
  id: string,
  email?: string,
  firstName?: string,
  lastName?: string,
};

export type CreateOrganizationInput = {
  id?: string,
  name: string,
  color: string,
  workspaceLimit: number,
  warehouseRetention?: number,
  timezone: string,
  cloudResourceId: string,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput,
  color?: ModelStringInput,
  workspaceLimit?: ModelIntInput,
  warehouseRetention?: ModelIntInput,
  timezone?: ModelStringInput,
  cloudResourceId?: ModelStringInput,
  and?: Array< ModelOrganizationConditionInput >,
  or?: Array< ModelOrganizationConditionInput >,
  not?: ModelOrganizationConditionInput,
};

export type ModelIntInput = {
  ne?: number,
  eq?: number,
  le?: number,
  lt?: number,
  ge?: number,
  gt?: number,
  between?: Array< number >,
  attributeExists?: boolean,
  attributeType?: ModelAttributeTypes,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string,
  color?: string,
  workspaceLimit?: number,
  warehouseRetention?: number,
  timezone?: string,
  cloudResourceId?: string,
};

export type CreateOrganizationUserInput = {
  id?: string,
  organizationId: string,
  userId: string,
  hasWarehouseCredentials?: boolean,
};

export type ModelOrganizationUserConditionInput = {
  hasWarehouseCredentials?: ModelBooleanInput,
  and?: Array< ModelOrganizationUserConditionInput >,
  or?: Array< ModelOrganizationUserConditionInput >,
  not?: ModelOrganizationUserConditionInput,
};

export type ModelBooleanInput = {
  ne?: boolean,
  eq?: boolean,
  attributeExists?: boolean,
  attributeType?: ModelAttributeTypes,
};

export type UpdateOrganizationUserInput = {
  id: string,
  organizationId?: string,
  userId?: string,
  hasWarehouseCredentials?: boolean,
};

export type DeleteOrganizationUserInput = {
  id: string,
};

export type CreateOrganizationFivetranInput = {
  id?: string,
  groupId: string,
  groupName: string,
};

export type ModelOrganizationFivetranConditionInput = {
  groupId?: ModelStringInput,
  groupName?: ModelStringInput,
  and?: Array< ModelOrganizationFivetranConditionInput >,
  or?: Array< ModelOrganizationFivetranConditionInput >,
  not?: ModelOrganizationFivetranConditionInput,
};

export type OrganizationFivetran = {
  __typename?: "OrganizationFivetran",
  id: string,
  groupId: string,
  groupName: string,
  createdAt: string,
  updatedAt: string,
};

export type CreateWorkspaceInput = {
  id?: string,
  organizationId: string,
  name: string,
  seq: number,
};

export type ModelWorkspaceConditionInput = {
  organizationId?: ModelIDInput,
  name?: ModelStringInput,
  seq?: ModelIntInput,
  and?: Array< ModelWorkspaceConditionInput >,
  or?: Array< ModelWorkspaceConditionInput >,
  not?: ModelWorkspaceConditionInput,
};

export type ModelIDInput = {
  ne?: string,
  eq?: string,
  le?: string,
  lt?: string,
  ge?: string,
  gt?: string,
  contains?: string,
  notContains?: string,
  between?: Array< string >,
  beginsWith?: string,
  attributeExists?: boolean,
  attributeType?: ModelAttributeTypes,
  size?: ModelSizeInput,
};

export type UpdateWorkspaceInput = {
  id: string,
  organizationId?: string,
  name?: string,
  seq?: number,
};

export type CreateWorkspaceUserInput = {
  id?: string,
  workspaceId: string,
  userId: string,
};

export type ModelWorkspaceUserConditionInput = {
  workspaceId?: ModelIDInput,
  and?: Array< ModelWorkspaceUserConditionInput >,
  or?: Array< ModelWorkspaceUserConditionInput >,
  not?: ModelWorkspaceUserConditionInput,
};

export type DeleteWorkspaceUserInput = {
  id: string,
};

export type CreateSourceInput = {
  id?: string,
  name: string,
  description?: string,
  connectionId: string,
  workspaceId: string,
  cloudResourceId: string,
};

export type ModelSourceConditionInput = {
  name?: ModelStringInput,
  description?: ModelStringInput,
  connectionId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  and?: Array< ModelSourceConditionInput >,
  or?: Array< ModelSourceConditionInput >,
  not?: ModelSourceConditionInput,
};

export type UpdateSourceInput = {
  id: string,
  name?: string,
  description?: string,
  connectionId?: string,
  workspaceId?: string,
  cloudResourceId?: string,
};

export type DeleteSourceInput = {
  id: string,
};

export type CreateSourceWorkspaceShareInput = {
  id?: string,
  sourceId: string,
  workspaceId: string,
};

export type ModelSourceWorkspaceShareConditionInput = {
  sourceId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  and?: Array< ModelSourceWorkspaceShareConditionInput >,
  or?: Array< ModelSourceWorkspaceShareConditionInput >,
  not?: ModelSourceWorkspaceShareConditionInput,
};

export type SourceWorkspaceShare = {
  __typename?: "SourceWorkspaceShare",
  id: string,
  sourceId: string,
  workspaceId: string,
  createdAt: string,
  updatedAt: string,
  workspace: Workspace,
  source: Source,
};

export type CreateDataObjectInput = {
  id?: string,
  sourceId: string,
  name: string,
  viewName: string,
  workspaceId: string,
  cloudResourceId: string,
  cloudResourceInfo?: string,
};

export type ModelDataObjectConditionInput = {
  sourceId?: ModelIDInput,
  name?: ModelStringInput,
  viewName?: ModelStringInput,
  workspaceId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  and?: Array< ModelDataObjectConditionInput >,
  or?: Array< ModelDataObjectConditionInput >,
  not?: ModelDataObjectConditionInput,
};

export type UpdateDataObjectInput = {
  id: string,
  sourceId?: string,
  name?: string,
  viewName?: string,
  workspaceId?: string,
  cloudResourceId?: string,
  cloudResourceInfo?: string,
};

export type DeleteDataObjectInput = {
  id: string,
};

export type CreateDatasetInput = {
  id?: string,
  folderId: string,
  name: string,
  viewName: string,
  isDraft: boolean,
  description?: string,
  comments?: string,
  columnsDocumentation?: string,
  trigger: Trigger,
  triggerDetails?: string,
  ownerId: string,
  workspaceId: string,
  sharedWithWorkspaceIds?: Array< string >,
  cloudResourceId: string,
  cloudResourceInfo?: string,
  parents?: string,
  enableSearchByAI?: boolean,
};

export type ModelDatasetConditionInput = {
  folderId?: ModelIDInput,
  name?: ModelStringInput,
  viewName?: ModelStringInput,
  isDraft?: ModelBooleanInput,
  description?: ModelStringInput,
  comments?: ModelStringInput,
  columnsDocumentation?: ModelStringInput,
  trigger?: ModelTriggerInput,
  triggerDetails?: ModelStringInput,
  ownerId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  parents?: ModelStringInput,
  enableSearchByAI?: ModelBooleanInput,
  and?: Array< ModelDatasetConditionInput >,
  or?: Array< ModelDatasetConditionInput >,
  not?: ModelDatasetConditionInput,
};

export type ModelTriggerInput = {
  eq?: Trigger,
  ne?: Trigger,
};

export type UpdateDatasetInput = {
  id: string,
  folderId?: string,
  name?: string,
  viewName?: string,
  isDraft?: boolean,
  description?: string,
  comments?: string,
  columnsDocumentation?: string,
  trigger?: Trigger,
  triggerDetails?: string,
  ownerId?: string,
  workspaceId?: string,
  sharedWithWorkspaceIds?: Array< string >,
  cloudResourceId?: string,
  cloudResourceInfo?: string,
  parents?: string,
  enableSearchByAI?: boolean,
};

export type DeleteDatasetInput = {
  id: string,
};

export type CreateDatasetTestInput = {
  id?: string,
  datasetId: string,
  name: string,
  isDraft: boolean,
  ownerId: string,
  workspaceId: string,
  cloudResourceId: string,
  cloudResourceInfo?: string,
};

export type ModelDatasetTestConditionInput = {
  datasetId?: ModelIDInput,
  name?: ModelStringInput,
  isDraft?: ModelBooleanInput,
  ownerId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  and?: Array< ModelDatasetTestConditionInput >,
  or?: Array< ModelDatasetTestConditionInput >,
  not?: ModelDatasetTestConditionInput,
};

export type DatasetTest = {
  __typename?: "DatasetTest",
  id: string,
  datasetId: string,
  name: string,
  isDraft: boolean,
  ownerId: string,
  workspaceId: string,
  cloudResourceId: string,
  cloudResourceInfo?: string,
  createdAt: string,
  updatedAt: string,
  owner: User,
  dataset: Dataset,
};

export type UpdateDatasetTestInput = {
  id: string,
  datasetId?: string,
  name?: string,
  isDraft?: boolean,
  ownerId?: string,
  workspaceId?: string,
  cloudResourceId?: string,
  cloudResourceInfo?: string,
};

export type DeleteDatasetTestInput = {
  id: string,
};

export type CreateDatasetShareFromWorkspaceWithWorkspaceLinkInput = {
  id?: string,
  fromWorkspaceId: string,
  withWorkspaceId: string,
};

export type ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput = {
  fromWorkspaceId?: ModelIDInput,
  withWorkspaceId?: ModelIDInput,
  and?: Array< ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput >,
  or?: Array< ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput >,
  not?: ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput,
};

export type DeleteDatasetShareFromWorkspaceWithWorkspaceLinkInput = {
  id: string,
};

export type CreateDatasetWorkspaceShareInput = {
  id?: string,
  datasetId: string,
  workspaceId: string,
  originWorkspaceId: string,
};

export type ModelDatasetWorkspaceShareConditionInput = {
  datasetId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  originWorkspaceId?: ModelIDInput,
  and?: Array< ModelDatasetWorkspaceShareConditionInput >,
  or?: Array< ModelDatasetWorkspaceShareConditionInput >,
  not?: ModelDatasetWorkspaceShareConditionInput,
};

export type DatasetWorkspaceShare = {
  __typename?: "DatasetWorkspaceShare",
  id: string,
  datasetId: string,
  workspaceId: string,
  originWorkspaceId: string,
  createdAt: string,
  updatedAt: string,
  workspace: Workspace,
  dataset: Dataset,
};

export type DeleteDatasetWorkspaceShareInput = {
  id: string,
};

export type CreateDatasetSqlQueryInput = {
  id?: string,
  datasetId: string,
  workspaceId?: string,
  sqlQuery: string,
  trigger?: Trigger,
  triggerDetails?: string,
  deployed?: boolean,
  createdAt?: string,
};

export type ModelDatasetSqlQueryConditionInput = {
  datasetId?: ModelIDInput,
  sqlQuery?: ModelStringInput,
  trigger?: ModelTriggerInput,
  triggerDetails?: ModelStringInput,
  deployed?: ModelBooleanInput,
  createdAt?: ModelStringInput,
  and?: Array< ModelDatasetSqlQueryConditionInput >,
  or?: Array< ModelDatasetSqlQueryConditionInput >,
  not?: ModelDatasetSqlQueryConditionInput,
};

export type DatasetSqlQuery = {
  __typename?: "DatasetSqlQuery",
  id: string,
  datasetId: string,
  workspaceId?: string,
  sqlQuery: string,
  trigger?: Trigger,
  triggerDetails?: string,
  deployed?: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDatasetSqlQueryInput = {
  id: string,
  datasetId?: string,
  workspaceId?: string,
  sqlQuery?: string,
  trigger?: Trigger,
  triggerDetails?: string,
  deployed?: boolean,
  createdAt?: string,
};

export type DeleteDatasetSqlQueryInput = {
  id: string,
};

export type CreateFlowInput = {
  id?: string,
  datasetId: string,
  name: string,
  enabled: boolean,
  trigger: Trigger,
  triggerDetails?: string,
  workspaceId: string,
  destinationId: string,
  cloudResourceId: string,
  cloudResourceInfo?: string,
};

export type ModelFlowConditionInput = {
  datasetId?: ModelIDInput,
  name?: ModelStringInput,
  enabled?: ModelBooleanInput,
  trigger?: ModelTriggerInput,
  triggerDetails?: ModelStringInput,
  workspaceId?: ModelIDInput,
  destinationId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  and?: Array< ModelFlowConditionInput >,
  or?: Array< ModelFlowConditionInput >,
  not?: ModelFlowConditionInput,
};

export type UpdateFlowInput = {
  id: string,
  datasetId?: string,
  name?: string,
  enabled?: boolean,
  trigger?: Trigger,
  triggerDetails?: string,
  workspaceId?: string,
  destinationId?: string,
  cloudResourceId?: string,
  cloudResourceInfo?: string,
};

export type DeleteFlowInput = {
  id: string,
};

export type CreateIntegrationInput = {
  id?: string,
  name: string,
  type: IntegrationType,
  category: IntegrationCategory,
  description?: string,
  dataSourceSupport: boolean,
  flowActions?: Array< string >,
  fake?: ColumnDataType,
};

export type ModelIntegrationConditionInput = {
  name?: ModelStringInput,
  type?: ModelIntegrationTypeInput,
  category?: ModelIntegrationCategoryInput,
  description?: ModelStringInput,
  dataSourceSupport?: ModelBooleanInput,
  flowActions?: ModelStringInput,
  fake?: ModelColumnDataTypeInput,
  and?: Array< ModelIntegrationConditionInput >,
  or?: Array< ModelIntegrationConditionInput >,
  not?: ModelIntegrationConditionInput,
};

export type ModelIntegrationTypeInput = {
  eq?: IntegrationType,
  ne?: IntegrationType,
};

export type ModelIntegrationCategoryInput = {
  eq?: IntegrationCategory,
  ne?: IntegrationCategory,
};

export type ModelColumnDataTypeInput = {
  eq?: ColumnDataType,
  ne?: ColumnDataType,
};

export type UpdateIntegrationInput = {
  id: string,
  name?: string,
  type?: IntegrationType,
  category?: IntegrationCategory,
  description?: string,
  dataSourceSupport?: boolean,
  flowActions?: Array< string >,
  fake?: ColumnDataType,
};

export type DeleteIntegrationInput = {
  id: string,
};

export type CreateConnectionInput = {
  id?: string,
  name?: string,
  organizationId: string,
  workspaceId: string,
  ownerId: string,
  fivetranConnectionId?: string,
  integrationId?: string,
  credentialsId?: string,
  isDeleted?: boolean,
};

export type ModelConnectionConditionInput = {
  name?: ModelStringInput,
  organizationId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  ownerId?: ModelIDInput,
  fivetranConnectionId?: ModelIDInput,
  integrationId?: ModelIDInput,
  credentialsId?: ModelIDInput,
  isDeleted?: ModelBooleanInput,
  and?: Array< ModelConnectionConditionInput >,
  or?: Array< ModelConnectionConditionInput >,
  not?: ModelConnectionConditionInput,
};

export type UpdateConnectionInput = {
  id: string,
  name?: string,
  organizationId?: string,
  workspaceId?: string,
  ownerId?: string,
  fivetranConnectionId?: string,
  integrationId?: string,
  credentialsId?: string,
  isDeleted?: boolean,
};

export type DeleteConnectionInput = {
  id: string,
};

export type CreateFivetranConnectionInput = {
  id?: string,
  name: string,
  serviceName: string,
  glueDatabaseName: string,
  integrationName: string,
  isDeployed?: boolean,
  isDeleted?: boolean,
};

export type ModelFivetranConnectionConditionInput = {
  name?: ModelStringInput,
  serviceName?: ModelStringInput,
  glueDatabaseName?: ModelStringInput,
  integrationName?: ModelStringInput,
  isDeployed?: ModelBooleanInput,
  isDeleted?: ModelBooleanInput,
  and?: Array< ModelFivetranConnectionConditionInput >,
  or?: Array< ModelFivetranConnectionConditionInput >,
  not?: ModelFivetranConnectionConditionInput,
};

export type UpdateFivetranConnectionInput = {
  id: string,
  name?: string,
  serviceName?: string,
  glueDatabaseName?: string,
  integrationName?: string,
  isDeployed?: boolean,
  isDeleted?: boolean,
};

export type DeleteFivetranConnectionInput = {
  id: string,
};

export type CreateCredentialsInput = {
  id?: string,
  username: string,
  password: string,
};

export type ModelCredentialsConditionInput = {
  username?: ModelStringInput,
  password?: ModelStringInput,
  and?: Array< ModelCredentialsConditionInput >,
  or?: Array< ModelCredentialsConditionInput >,
  not?: ModelCredentialsConditionInput,
};

export type UpdateCredentialsInput = {
  id: string,
  username?: string,
  password?: string,
};

export type DeleteCredentialsInput = {
  id: string,
};

export type CreateFolderInput = {
  id?: string,
  name: string,
  parentId: string,
};

export type ModelFolderConditionInput = {
  name?: ModelStringInput,
  parentId?: ModelIDInput,
  and?: Array< ModelFolderConditionInput >,
  or?: Array< ModelFolderConditionInput >,
  not?: ModelFolderConditionInput,
};

export type UpdateFolderInput = {
  id: string,
  name?: string,
  parentId?: string,
};

export type DeleteFolderInput = {
  id: string,
};

export type CreateDownloadInput = {
  id?: string,
  dataId: string,
  dataTimeStamp: number,
  requesterId?: string,
  requestTimeStamp: number,
  signedDownloadUrl: string,
};

export type ModelDownloadConditionInput = {
  dataId?: ModelIDInput,
  dataTimeStamp?: ModelIntInput,
  requestTimeStamp?: ModelIntInput,
  signedDownloadUrl?: ModelStringInput,
  and?: Array< ModelDownloadConditionInput >,
  or?: Array< ModelDownloadConditionInput >,
  not?: ModelDownloadConditionInput,
};

export type UpdateDownloadInput = {
  id: string,
  dataId?: string,
  dataTimeStamp?: number,
  requesterId?: string,
  requestTimeStamp?: number,
  signedDownloadUrl?: string,
};

export type DeleteDownloadInput = {
  id: string,
};

export type CreateRoleInput = {
  id?: string,
  name: string,
  organizationId: string,
  users?: Array< string >,
};

export type ModelRoleConditionInput = {
  name?: ModelStringInput,
  organizationId?: ModelIDInput,
  users?: ModelStringInput,
  and?: Array< ModelRoleConditionInput >,
  or?: Array< ModelRoleConditionInput >,
  not?: ModelRoleConditionInput,
};

export type Role = {
  __typename?: "Role",
  id: string,
  name: string,
  organizationId: string,
  users?: Array< string >,
  createdAt: string,
  updatedAt: string,
  organization: Organization,
  permissions?: ModelPermissionConnection,
};

export type ModelPermissionConnection = {
  __typename?: "ModelPermissionConnection",
  items:  Array<Permission >,
  nextToken?: string,
};

export type Permission = {
  __typename?: "Permission",
  id: string,
  roleId: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  role: Role,
};

export type UpdateRoleInput = {
  id: string,
  name?: string,
  organizationId?: string,
  users?: Array< string >,
};

export type DeleteRoleInput = {
  id: string,
};

export type CreatePermissionInput = {
  id?: string,
  roleId: string,
  name: string,
};

export type ModelPermissionConditionInput = {
  roleId?: ModelIDInput,
  name?: ModelStringInput,
  and?: Array< ModelPermissionConditionInput >,
  or?: Array< ModelPermissionConditionInput >,
  not?: ModelPermissionConditionInput,
};

export type UpdatePermissionInput = {
  id: string,
  roleId?: string,
  name?: string,
};

export type DeletePermissionInput = {
  id: string,
};

export type InvokeDownloadInput = {
  orgId?: string,
  wsSeq?: string,
  dataType: string,
  dataId: string,
  dataName: string,
  dataCloudResourceId: string,
  fileType: string,
  filters: string,
  sortings: string,
};

export type DeployDatasetSqlQueryInput = {
  orgId?: string,
  wsSeq?: string,
  id?: string,
  datasetId: string,
  datasetCloudResourceId: string,
  workspaceId: string,
  sqlQuery: string,
  trigger: Trigger,
  triggerDetails?: string,
  deployed: boolean,
  dbSchemaName?: string,
  dbViewName?: string,
};

export type FivetranCreateGroupInput = {
  id: string,
  orgId?: string,
};

export type ModifyFlowInput = {
  cloudResourceId: string,
  datasetNameEnvVar?: string,
  cron?: string,
  timezone?: string,
  enabled?: boolean,
};

export type ModifyFlowResponse = {
  __typename?: "ModifyFlowResponse",
  placeholder?: FlowRunStatus,
};

export enum FlowRunStatus {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}


export type UpdateIDatasetUserInput = {
  id: string,
  datasetId?: string,
  userId?: string,
  options?: string,
};

export type IDatasetUser = {
  __typename?: "IDatasetUser",
  id: string,
  datasetId: string,
  userId: string,
  options: string,
  createdAt: string,
  updatedAt: string,
};

export type CreateIDatasetUserInput = {
  id?: string,
  datasetId: string,
  userId: string,
  options: string,
};

export type DeleteUserInput = {
  id: string,
};

export type DeleteIDatasetUserInput = {
  id: string,
};

export type ModelIDatasetUserConditionInput = {
  datasetId?: ModelIDInput,
  options?: ModelStringInput,
  and?: Array< ModelIDatasetUserConditionInput >,
  or?: Array< ModelIDatasetUserConditionInput >,
  not?: ModelIDatasetUserConditionInput,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput,
  email?: ModelStringInput,
  firstName?: ModelStringInput,
  lastName?: ModelStringInput,
  and?: Array< ModelUserFilterInput >,
  or?: Array< ModelUserFilterInput >,
  not?: ModelUserFilterInput,
};

export type ModelUserConnection = {
  __typename?: "ModelUserConnection",
  items:  Array<User >,
  nextToken?: string,
};

export type ModelOrganizationUserFilterInput = {
  id?: ModelIDInput,
  organizationId?: ModelIDInput,
  userId?: ModelIDInput,
  hasWarehouseCredentials?: ModelBooleanInput,
  and?: Array< ModelOrganizationUserFilterInput >,
  or?: Array< ModelOrganizationUserFilterInput >,
  not?: ModelOrganizationUserFilterInput,
};

export type ModelWorkspaceUserFilterInput = {
  id?: ModelIDInput,
  workspaceId?: ModelIDInput,
  userId?: ModelIDInput,
  and?: Array< ModelWorkspaceUserFilterInput >,
  or?: Array< ModelWorkspaceUserFilterInput >,
  not?: ModelWorkspaceUserFilterInput,
};

export type ModelIDatasetUserFilterInput = {
  id?: ModelIDInput,
  datasetId?: ModelIDInput,
  userId?: ModelIDInput,
  options?: ModelStringInput,
  and?: Array< ModelIDatasetUserFilterInput >,
  or?: Array< ModelIDatasetUserFilterInput >,
  not?: ModelIDatasetUserFilterInput,
};

export type ModelIDatasetUserConnection = {
  __typename?: "ModelIDatasetUserConnection",
  items:  Array<IDatasetUser >,
  nextToken?: string,
};

export type ModelDatasetFilterInput = {
  id?: ModelIDInput,
  folderId?: ModelIDInput,
  name?: ModelStringInput,
  viewName?: ModelStringInput,
  isDraft?: ModelBooleanInput,
  description?: ModelStringInput,
  comments?: ModelStringInput,
  columnsDocumentation?: ModelStringInput,
  trigger?: ModelTriggerInput,
  triggerDetails?: ModelStringInput,
  ownerId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  sharedWithWorkspaceIds?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  parents?: ModelStringInput,
  enableSearchByAI?: ModelBooleanInput,
  and?: Array< ModelDatasetFilterInput >,
  or?: Array< ModelDatasetFilterInput >,
  not?: ModelDatasetFilterInput,
};

export type ModelDatasetTestFilterInput = {
  id?: ModelIDInput,
  datasetId?: ModelIDInput,
  name?: ModelStringInput,
  isDraft?: ModelBooleanInput,
  ownerId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  and?: Array< ModelDatasetTestFilterInput >,
  or?: Array< ModelDatasetTestFilterInput >,
  not?: ModelDatasetTestFilterInput,
};

export type ModelDatasetTestConnection = {
  __typename?: "ModelDatasetTestConnection",
  items:  Array<DatasetTest >,
  nextToken?: string,
};

export type ModelConnectionFilterInput = {
  id?: ModelIDInput,
  name?: ModelStringInput,
  organizationId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  ownerId?: ModelIDInput,
  fivetranConnectionId?: ModelIDInput,
  integrationId?: ModelIDInput,
  credentialsId?: ModelIDInput,
  isDeleted?: ModelBooleanInput,
  and?: Array< ModelConnectionFilterInput >,
  or?: Array< ModelConnectionFilterInput >,
  not?: ModelConnectionFilterInput,
};

export type ModelConnectionConnection = {
  __typename?: "ModelConnectionConnection",
  items:  Array<Connection >,
  nextToken?: string,
};

export type ModelIDKeyConditionInput = {
  eq?: string,
  le?: string,
  lt?: string,
  ge?: string,
  gt?: string,
  between?: Array< string >,
  beginsWith?: string,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type OrganizationChargebee = {
  __typename?: "OrganizationChargebee",
  id: string,
  subscriptionId: string,
  allowance: number,
  billingCycleDate: number,
  createdAt: string,
  updatedAt: string,
};

export type ModelOrganizationChargebeeFilterInput = {
  id?: ModelIDInput,
  subscriptionId?: ModelStringInput,
  allowance?: ModelIntInput,
  billingCycleDate?: ModelIntInput,
  and?: Array< ModelOrganizationChargebeeFilterInput >,
  or?: Array< ModelOrganizationChargebeeFilterInput >,
  not?: ModelOrganizationChargebeeFilterInput,
};

export type ModelOrganizationChargebeeConnection = {
  __typename?: "ModelOrganizationChargebeeConnection",
  items:  Array<OrganizationChargebee >,
  nextToken?: string,
};

export type OrganizationDPU = {
  __typename?: "OrganizationDPU",
  id: string,
  organizationId: string,
  date: string,
  dpu: number,
  createdAt: string,
  updatedAt: string,
};

export type ModelOrganizationDPUFilterInput = {
  id?: ModelIDInput,
  organizationId?: ModelIDInput,
  date?: ModelStringInput,
  dpu?: ModelIntInput,
  and?: Array< ModelOrganizationDPUFilterInput >,
  or?: Array< ModelOrganizationDPUFilterInput >,
  not?: ModelOrganizationDPUFilterInput,
};

export type ModelOrganizationDPUConnection = {
  __typename?: "ModelOrganizationDPUConnection",
  items:  Array<OrganizationDPU >,
  nextToken?: string,
};

export type ModelStringKeyConditionInput = {
  eq?: string,
  le?: string,
  lt?: string,
  ge?: string,
  gt?: string,
  between?: Array< string >,
  beginsWith?: string,
};

export type ModelWorkspaceFilterInput = {
  id?: ModelIDInput,
  organizationId?: ModelIDInput,
  name?: ModelStringInput,
  seq?: ModelIntInput,
  and?: Array< ModelWorkspaceFilterInput >,
  or?: Array< ModelWorkspaceFilterInput >,
  not?: ModelWorkspaceFilterInput,
};

export type ModelSourceFilterInput = {
  id?: ModelIDInput,
  name?: ModelStringInput,
  description?: ModelStringInput,
  connectionId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  and?: Array< ModelSourceFilterInput >,
  or?: Array< ModelSourceFilterInput >,
  not?: ModelSourceFilterInput,
};

export type ModelSourceWorkspaceShareFilterInput = {
  id?: ModelIDInput,
  sourceId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  and?: Array< ModelSourceWorkspaceShareFilterInput >,
  or?: Array< ModelSourceWorkspaceShareFilterInput >,
  not?: ModelSourceWorkspaceShareFilterInput,
};

export type ModelSourceWorkspaceShareConnection = {
  __typename?: "ModelSourceWorkspaceShareConnection",
  items:  Array<SourceWorkspaceShare >,
  nextToken?: string,
};

export type ModelDataObjectFilterInput = {
  id?: ModelIDInput,
  sourceId?: ModelIDInput,
  name?: ModelStringInput,
  viewName?: ModelStringInput,
  workspaceId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  and?: Array< ModelDataObjectFilterInput >,
  or?: Array< ModelDataObjectFilterInput >,
  not?: ModelDataObjectFilterInput,
};

export type ModelDatasetShareFromWorkspaceWithWorkspaceLinkFilterInput = {
  id?: ModelIDInput,
  fromWorkspaceId?: ModelIDInput,
  withWorkspaceId?: ModelIDInput,
  and?: Array< ModelDatasetShareFromWorkspaceWithWorkspaceLinkFilterInput >,
  or?: Array< ModelDatasetShareFromWorkspaceWithWorkspaceLinkFilterInput >,
  not?: ModelDatasetShareFromWorkspaceWithWorkspaceLinkFilterInput,
};

export type ModelDatasetWorkspaceShareFilterInput = {
  id?: ModelIDInput,
  datasetId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  originWorkspaceId?: ModelIDInput,
  and?: Array< ModelDatasetWorkspaceShareFilterInput >,
  or?: Array< ModelDatasetWorkspaceShareFilterInput >,
  not?: ModelDatasetWorkspaceShareFilterInput,
};

export type ModelDatasetWorkspaceShareConnection = {
  __typename?: "ModelDatasetWorkspaceShareConnection",
  items:  Array<DatasetWorkspaceShare >,
  nextToken?: string,
};

export type ModelFlowFilterInput = {
  id?: ModelIDInput,
  datasetId?: ModelIDInput,
  name?: ModelStringInput,
  enabled?: ModelBooleanInput,
  trigger?: ModelTriggerInput,
  triggerDetails?: ModelStringInput,
  workspaceId?: ModelIDInput,
  destinationId?: ModelIDInput,
  cloudResourceId?: ModelStringInput,
  cloudResourceInfo?: ModelStringInput,
  and?: Array< ModelFlowFilterInput >,
  or?: Array< ModelFlowFilterInput >,
  not?: ModelFlowFilterInput,
};

export type ModelIntegrationFilterInput = {
  id?: ModelIDInput,
  name?: ModelStringInput,
  type?: ModelIntegrationTypeInput,
  category?: ModelIntegrationCategoryInput,
  description?: ModelStringInput,
  dataSourceSupport?: ModelBooleanInput,
  flowActions?: ModelStringInput,
  fake?: ModelColumnDataTypeInput,
  and?: Array< ModelIntegrationFilterInput >,
  or?: Array< ModelIntegrationFilterInput >,
  not?: ModelIntegrationFilterInput,
};

export type ModelIntegrationConnection = {
  __typename?: "ModelIntegrationConnection",
  items:  Array<Integration >,
  nextToken?: string,
};

export type ModelFolderFilterInput = {
  id?: ModelIDInput,
  name?: ModelStringInput,
  parentId?: ModelIDInput,
  and?: Array< ModelFolderFilterInput >,
  or?: Array< ModelFolderFilterInput >,
  not?: ModelFolderFilterInput,
};

export type ModelIntKeyConditionInput = {
  eq?: number,
  le?: number,
  lt?: number,
  ge?: number,
  gt?: number,
  between?: Array< number >,
};

export type ModelDownloadFilterInput = {
  id?: ModelIDInput,
  dataId?: ModelIDInput,
  dataTimeStamp?: ModelIntInput,
  requesterId?: ModelIDInput,
  requestTimeStamp?: ModelIntInput,
  signedDownloadUrl?: ModelStringInput,
  and?: Array< ModelDownloadFilterInput >,
  or?: Array< ModelDownloadFilterInput >,
  not?: ModelDownloadFilterInput,
};

export type ModelDatasetSqlQueryFilterInput = {
  id?: ModelIDInput,
  datasetId?: ModelIDInput,
  workspaceId?: ModelIDInput,
  sqlQuery?: ModelStringInput,
  trigger?: ModelTriggerInput,
  triggerDetails?: ModelStringInput,
  deployed?: ModelBooleanInput,
  createdAt?: ModelStringInput,
  and?: Array< ModelDatasetSqlQueryFilterInput >,
  or?: Array< ModelDatasetSqlQueryFilterInput >,
  not?: ModelDatasetSqlQueryFilterInput,
};

export type ModelDatasetSqlQueryConnection = {
  __typename?: "ModelDatasetSqlQueryConnection",
  items:  Array<DatasetSqlQuery >,
  nextToken?: string,
};

export type ModelPipelineResultFilterInput = {
  id?: ModelIDInput,
  recordCloudResourceId?: ModelStringInput,
  timestamp?: ModelIntInput,
  status?: ModelPipelineResultStatusInput,
  info?: ModelStringInput,
  and?: Array< ModelPipelineResultFilterInput >,
  or?: Array< ModelPipelineResultFilterInput >,
  not?: ModelPipelineResultFilterInput,
};

export type ModelPipelineResultStatusInput = {
  eq?: PipelineResultStatus,
  ne?: PipelineResultStatus,
};

export enum PipelineResultStatus {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  ERRORED = "ERRORED",
}


export type ModelPipelineResultConnection = {
  __typename?: "ModelPipelineResultConnection",
  items:  Array<PipelineResult >,
  nextToken?: string,
};

export type PipelineResult = {
  __typename?: "PipelineResult",
  id: string,
  recordCloudResourceId: string,
  timestamp: number,
  status: PipelineResultStatus,
  info?: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput,
  message?: ModelStringInput,
  level?: ModelEventLevelInput,
  userId?: ModelIDInput,
  actionType?: ModelEventActionTypeInput,
  actionSourceId?: ModelIDInput,
  actionSourceName?: ModelStringInput,
  actionSourceType?: ModelEventObjectTypeInput,
  actionSourceWorkspaceId?: ModelIDInput,
  actionData?: ModelStringInput,
  actionRecordedAt?: ModelStringInput,
  ttl?: ModelIntInput,
  and?: Array< ModelEventFilterInput >,
  or?: Array< ModelEventFilterInput >,
  not?: ModelEventFilterInput,
};

export type ModelEventLevelInput = {
  eq?: EventLevel,
  ne?: EventLevel,
};

export enum EventLevel {
  INFO = "INFO",
  ERROR = "ERROR",
  DEBUG = "DEBUG",
}


export type ModelEventActionTypeInput = {
  eq?: EventActionType,
  ne?: EventActionType,
};

export enum EventActionType {
  CATALOG_SOURCE_SCHEDULED_START = "CATALOG_SOURCE_SCHEDULED_START",
  CATALOG_SOURCE_SCHEDULED_SUCCESS = "CATALOG_SOURCE_SCHEDULED_SUCCESS",
  CATALOG_SOURCE_SCHEDULED_FAIL = "CATALOG_SOURCE_SCHEDULED_FAIL",
  HUB_DATASET_SCHEDULED_START = "HUB_DATASET_SCHEDULED_START",
  HUB_DATASET_SCHEDULED_SUCCESS = "HUB_DATASET_SCHEDULED_SUCCESS",
  HUB_DATASET_SCHEDULED_TEST_BLOCKING = "HUB_DATASET_SCHEDULED_TEST_BLOCKING",
  HUB_DATASET_SCHEDULED_TEST_NONBLOCK = "HUB_DATASET_SCHEDULED_TEST_NONBLOCK",
  HUB_DATASET_SCHEDULED_FAIL = "HUB_DATASET_SCHEDULED_FAIL",
  HUB_DATASET_MANUAL_START = "HUB_DATASET_MANUAL_START",
  HUB_DATASET_MANUAL_SUCCESS = "HUB_DATASET_MANUAL_SUCCESS",
  HUB_DATASET_MANUAL_TEST_BLOCKING = "HUB_DATASET_MANUAL_TEST_BLOCKING",
  HUB_DATASET_MANUAL_TEST_NONBLOCK = "HUB_DATASET_MANUAL_TEST_NONBLOCK",
  HUB_DATASET_MANUAL_FAIL = "HUB_DATASET_MANUAL_FAIL",
  FLOW_SCHEDULED_START = "FLOW_SCHEDULED_START",
  FLOW_SCHEDULED_SUCCESS = "FLOW_SCHEDULED_SUCCESS",
  FLOW_SCHEDULED_FAIL = "FLOW_SCHEDULED_FAIL",
  FLOW_MANUAL_START = "FLOW_MANUAL_START",
  FLOW_MANUAL_SUCCESS = "FLOW_MANUAL_SUCCESS",
  FLOW_MANUAL_FAIL = "FLOW_MANUAL_FAIL",
}


export type ModelEventObjectTypeInput = {
  eq?: EventObjectType,
  ne?: EventObjectType,
};

export enum EventObjectType {
  SOURCE = "SOURCE",
  DATA_OBJECT = "DATA_OBJECT",
  DATASET = "DATASET",
  FLOW = "FLOW",
}


export type ModelEventConnection = {
  __typename?: "ModelEventConnection",
  items:  Array<Event >,
  nextToken?: string,
};

export type Event = {
  __typename?: "Event",
  id: string,
  message: string,
  level: EventLevel,
  userId?: string,
  actionType: EventActionType,
  actionSourceId: string,
  actionSourceName: string,
  actionSourceType: EventObjectType,
  actionSourceWorkspaceId: string,
  actionData: string,
  actionRecordedAt: string,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type GetDataObjectContentQueryInput = {
  orgId?: string,
  wsSeq?: string,
  cloudResourceId: string,
  version: string,
  pageSize: number,
  startIndex: number,
  filters: string,
  sortings: string,
};

export type GetDataObjectContentQueryOutput = {
  __typename?: "GetDataObjectContentQueryOutput",
  executionId: string,
};

export type GetDatasetInfoQueryInput = {
  orgId?: string,
  wsSeq?: string,
  cloudResourceId: string,
  version: string,
};

export type GetDatasetInfoQueryOutput = {
  __typename?: "GetDatasetInfoQueryOutput",
  info: string,
};

export type GetDatasetContentQueryInput = {
  orgId?: string,
  wsSeq?: string,
  cloudResourceId: string,
  version: string,
  pageSize: number,
  startIndex: number,
  filters: string,
  sortings: string,
};

export type GetDatasetContentQueryOutput = {
  __typename?: "GetDatasetContentQueryOutput",
  executionId: string,
};

export type GetDatasetConsolidatedTestResultsQueryInput = {
  orgId?: string,
  wsSeq?: string,
  cloudResourceId: string,
  version: string,
  pageSize: number,
  startIndex: number,
};

export type GetDatasetConsolidatedTestResultsQueryOutput = {
  __typename?: "GetDatasetConsolidatedTestResultsQueryOutput",
  executionId: string,
};

export type ModifyDatasetQueryInput = {
  orgId?: string,
  wsSeq?: string,
  cloudResourceId: string,
  previousCron?: string,
  previousTimezone?: string,
  cron?: string,
  timezone?: string,
  dbSharedWith?: Array< string >,
  testDatasetIds?: Array< string >,
};

export type ModifyDatasetQueryOutput = {
  __typename?: "ModifyDatasetQueryOutput",
  dummy?: boolean,
};

export type DeleteDatasetHistoricalDataQueryInput = {
  orgId?: string,
  wsSeq?: string,
  cloudResourceId: string,
};

export type DeleteDatasetHistoricalDataQueryOutput = {
  __typename?: "DeleteDatasetHistoricalDataQueryOutput",
  dummy?: boolean,
};

export type GetFlowInfoQueryInput = {
  orgId?: string,
  wsSeq?: string,
  cloudResourceId: string,
};

export type GetFlowInfoQueryOutput = {
  __typename?: "GetFlowInfoQueryOutput",
  info: string,
};

export type CheckRedshiftQueryInput = {
  orgId?: string,
  wsSeq?: string,
  executionId: string,
};

export type CheckRedshiftQueryOutput = {
  __typename?: "CheckRedshiftQueryOutput",
  status: string,
  rows?: string,
  error?: string,
};

export type CancelRedshiftQueryInput = {
  orgId?: string,
  wsSeq?: string,
  executionId: string,
};

export type CancelRedshiftQueryOutput = {
  __typename?: "CancelRedshiftQueryOutput",
  status: boolean,
};

export type InsightConverseInput = {
  orgId?: string,
  wsSeq?: string,
  datasets: string,
  prompt: string,
  messages: string,
};

export type InsightConverseOutput = {
  __typename?: "InsightConverseOutput",
  messages: string,
  metadata: string,
};

export type InsightGetSampleDataStartQueryInput = {
  orgId?: string,
  wsSeq?: string,
  datasets: string,
};

export type InsightGetSampleDataStartQueryOutput = {
  __typename?: "InsightGetSampleDataStartQueryOutput",
  executionIds: Array< string >,
};

export type InsightGetSampleDataCheckQueryInput = {
  orgId?: string,
  wsSeq?: string,
  executions: string,
};

export type InsightGetSampleDataCheckQueryOutput = {
  __typename?: "InsightGetSampleDataCheckQueryOutput",
  executions: string,
};

export type InsightGetQueryFromBedrockQueryInput = {
  orgId?: string,
  wsSeq?: string,
  completedGetSampleDataExecutionIds: string,
  datasets: string,
  prompt: string,
  messages: string,
};

export type InsightGetQueryFromBedrockQueryOutput = {
  __typename?: "InsightGetQueryFromBedrockQueryOutput",
  query: string,
  messages: string,
};

export type InsightExecuteBedrockQueryStartQueryInput = {
  orgId?: string,
  wsSeq?: string,
  bedrockQuery: string,
};

export type InsightExecuteBedrockQueryStartQueryOutput = {
  __typename?: "InsightExecuteBedrockQueryStartQueryOutput",
  executionId: string,
};

export type InsightExecuteBedrockQueryCheckQueryInput = {
  orgId?: string,
  wsSeq?: string,
  executionId: string,
};

export type InsightExecuteBedrockQueryCheckQueryOutput = {
  __typename?: "InsightExecuteBedrockQueryCheckQueryOutput",
  status: string,
};

export type InsightInterpretResultQueryInput = {
  orgId?: string,
  wsSeq?: string,
  completedBedrockQueryExecutionId: string,
  messages: string,
};

export type InsightInterpretResultQueryOutput = {
  __typename?: "InsightInterpretResultQueryOutput",
  result: string,
  data?: string,
};

export type FivetranFetchConnectionsInput = {
  connectorType?: string,
};

export type FivetranFetchConnectionsOutput = {
  __typename?: "FivetranFetchConnectionsOutput",
  response: string,
};

export type TestDatasetStartQueryInput = {
  orgId: string,
  wsSeq: string,
  sqlQuery: string,
};

export type TestDatasetStartQueryResponse = {
  __typename?: "TestDatasetStartQueryResponse",
  executionId: string,
};

export type RefreshDatasetQueryInput = {
  cloudResourceId: string,
};

export type RefreshDatasetQueryOutput = {
  __typename?: "RefreshDatasetQueryOutput",
  success: boolean,
};

export type ValidateIntegrationCredentialsQueryInput = {
  orgId: string,
  integrationName: string,
  apiUrl: string,
  apiKey: string,
};

export type UpdateOrganizationSettingsInput = {
  orgId: string,
  daysToRetainData?: number,
};

export type UpdateOrganizationSettingsOutput = {
  __typename?: "UpdateOrganizationSettingsOutput",
  success: boolean,
};

export type GetWarehouseStorageInfoInput = {
  orgId: string,
};

export type GetWarehouseStorageInfoOutput = {
  __typename?: "GetWarehouseStorageInfoOutput",
  totalStorageCapacityInMegaBytes: number,
  totalStorageUsedInMegaBytes: number,
};

export type GetWarehouseConnectionInfoInput = {
  orgId: string,
};

export type GetWarehouseConnectionInfoOutput = {
  __typename?: "GetWarehouseConnectionInfoOutput",
  host: string,
  port: string,
  database: string,
};

export type ConfigureNewWorkspaceInTheWarehouseInput = {
  orgId: string,
  workspaceSeq: string,
};

export type ConfigureNewWorkspaceInTheWarehouseOutput = {
  __typename?: "ConfigureNewWorkspaceInTheWarehouseOutput",
  success: boolean,
};

export type UpsertUserWarehouseCredentialsInput = {
  orgId: string,
  userEmail: string,
  workspaceSeqs?: Array< string >,
};

export type UpsertUserWarehouseCredentialsOutput = {
  __typename?: "UpsertUserWarehouseCredentialsOutput",
  password: string,
};

export type CreateCognitoUserInput = {
  email: string,
};

export type CreateCognitoUserOutput = {
  __typename?: "CreateCognitoUserOutput",
  id: string,
};

export type FivetranCreateConnectCardInput = {
  connectorId: string,
  redirectUrl: string,
  groupId: string,
  schema: string,
};

export type FivetranCreateConnectCardOutput = {
  __typename?: "FivetranCreateConnectCardOutput",
  response: string,
};

export type FivetranRecreateConnectCardInput = {
  connectorId: string,
  redirectUrl: string,
};

export type FivetranRecreateConnectCardOutput = {
  __typename?: "FivetranRecreateConnectCardOutput",
  response: string,
};

export type FivetranDeployConnectorQueryInput = {
  orgId?: string,
  wsSeq?: string,
  fivetranServiceName: string,
  fivetranConnectionId: string,
  fivetranConnectionName: string,
  glueDatabaseName: string,
  workspaceSeq: string,
  dynamoDbSourceId: string,
  dynamoDbWorkspaceId: string,
};

export type FivetranDeployConnectorQueryOutput = {
  __typename?: "FivetranDeployConnectorQueryOutput",
  dummy?: boolean,
};

export type FivetranUndeployConnectorQueryInput = {
  orgId?: string,
  wsSeq?: string,
  glueDatabaseName: string,
};

export type FivetranUndeployConnectorQueryOutput = {
  __typename?: "FivetranUndeployConnectorQueryOutput",
  dummy?: boolean,
};

export type AdminLogoutUserInput = {
  userId: string,
  orgId?: string,
  wsSeq?: string,
};

export type AdminLogoutUserOutput = {
  __typename?: "AdminLogoutUserOutput",
  dummy?: boolean,
};

export type FivetranDeleteConnectorInput = {
  connectorId: string,
};

export type FivetranDeleteConnectorOutput = {
  __typename?: "FivetranDeleteConnectorOutput",
  code: string,
  message: string,
};

export type Auth0CreateUserInput = {
  email: string,
  userMetadata: string,
};

export type Auth0CreateUserOutput = {
  __typename?: "Auth0CreateUserOutput",
  id: string,
};

export type Auth0ChangePasswordInput = {
  email: string,
};

export type Auth0ChangePasswordOutput = {
  __typename?: "Auth0ChangePasswordOutput",
  dummy?: boolean,
};

export type Auth0UpdateUserMetadataInput = {
  username: string,
  userMetadata: string,
};

export type Auth0UpdateUserMetadataOutput = {
  __typename?: "Auth0UpdateUserMetadataOutput",
  dummy?: boolean,
};

export type InsightMessage = {
  __typename?: "InsightMessage",
  id: string,
  workspaceUserId: string,
  userId: string,
  createdAt: string,
  role: InsightMessageRole,
  content: string,
  metadata?: string,
  ttl: number,
  updatedAt: string,
};

export enum InsightMessageRole {
  USER = "USER",
  ASSISTANT = "ASSISTANT",
}


export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput,
};

export type CreateUserMutation = {
  createUser:  {
    __typename?: "User",
    id: string,
  },
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename?: "User",
    id: string,
  },
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput,
};

export type CreateOrganizationMutation = {
  createOrganization:  {
    __typename?: "Organization",
    id: string,
  },
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput,
};

export type UpdateOrganizationMutation = {
  updateOrganization:  {
    __typename?: "Organization",
    id: string,
  },
};

export type CreateOrganizationUserMutationVariables = {
  input: CreateOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput,
};

export type CreateOrganizationUserMutation = {
  createOrganizationUser:  {
    __typename?: "OrganizationUser",
    id: string,
  },
};

export type UpdateOrganizationUserMutationVariables = {
  input: UpdateOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput,
};

export type UpdateOrganizationUserMutation = {
  updateOrganizationUser:  {
    __typename?: "OrganizationUser",
    id: string,
  },
};

export type DeleteOrganizationUserMutationVariables = {
  input: DeleteOrganizationUserInput,
  condition?: ModelOrganizationUserConditionInput,
};

export type DeleteOrganizationUserMutation = {
  deleteOrganizationUser:  {
    __typename?: "OrganizationUser",
    id: string,
  },
};

export type CreateOrganizationFivetranMutationVariables = {
  input: CreateOrganizationFivetranInput,
  condition?: ModelOrganizationFivetranConditionInput,
};

export type CreateOrganizationFivetranMutation = {
  createOrganizationFivetran:  {
    __typename?: "OrganizationFivetran",
    id: string,
  },
};

export type CreateWorkspaceMutationVariables = {
  input: CreateWorkspaceInput,
  condition?: ModelWorkspaceConditionInput,
};

export type CreateWorkspaceMutation = {
  createWorkspace:  {
    __typename?: "Workspace",
    id: string,
  },
};

export type UpdateWorkspaceMutationVariables = {
  input: UpdateWorkspaceInput,
  condition?: ModelWorkspaceConditionInput,
};

export type UpdateWorkspaceMutation = {
  updateWorkspace:  {
    __typename?: "Workspace",
    id: string,
  },
};

export type CreateWorkspaceUserMutationVariables = {
  input: CreateWorkspaceUserInput,
  condition?: ModelWorkspaceUserConditionInput,
};

export type CreateWorkspaceUserMutation = {
  createWorkspaceUser:  {
    __typename?: "WorkspaceUser",
    id: string,
  },
};

export type DeleteWorkspaceUserMutationVariables = {
  input: DeleteWorkspaceUserInput,
  condition?: ModelWorkspaceUserConditionInput,
};

export type DeleteWorkspaceUserMutation = {
  deleteWorkspaceUser:  {
    __typename?: "WorkspaceUser",
    id: string,
  },
};

export type CreateSourceMutationVariables = {
  input: CreateSourceInput,
  condition?: ModelSourceConditionInput,
};

export type CreateSourceMutation = {
  createSource:  {
    __typename?: "Source",
    id: string,
  },
};

export type UpdateSourceMutationVariables = {
  input: UpdateSourceInput,
  condition?: ModelSourceConditionInput,
};

export type UpdateSourceMutation = {
  updateSource:  {
    __typename?: "Source",
    id: string,
  },
};

export type DeleteSourceMutationVariables = {
  input: DeleteSourceInput,
  condition?: ModelSourceConditionInput,
};

export type DeleteSourceMutation = {
  deleteSource:  {
    __typename?: "Source",
    id: string,
  },
};

export type CreateSourceWorkspaceShareMutationVariables = {
  input: CreateSourceWorkspaceShareInput,
  condition?: ModelSourceWorkspaceShareConditionInput,
};

export type CreateSourceWorkspaceShareMutation = {
  createSourceWorkspaceShare:  {
    __typename?: "SourceWorkspaceShare",
    id: string,
  },
};

export type CreateDataObjectMutationVariables = {
  input: CreateDataObjectInput,
  condition?: ModelDataObjectConditionInput,
};

export type CreateDataObjectMutation = {
  createDataObject:  {
    __typename?: "DataObject",
    id: string,
  },
};

export type UpdateDataObjectMutationVariables = {
  input: UpdateDataObjectInput,
  condition?: ModelDataObjectConditionInput,
};

export type UpdateDataObjectMutation = {
  updateDataObject:  {
    __typename?: "DataObject",
    id: string,
  },
};

export type DeleteDataObjectMutationVariables = {
  input: DeleteDataObjectInput,
  condition?: ModelDataObjectConditionInput,
};

export type DeleteDataObjectMutation = {
  deleteDataObject:  {
    __typename?: "DataObject",
    id: string,
  },
};

export type CreateDatasetMutationVariables = {
  input: CreateDatasetInput,
  condition?: ModelDatasetConditionInput,
};

export type CreateDatasetMutation = {
  createDataset:  {
    __typename?: "Dataset",
    id: string,
  },
};

export type UpdateDatasetMutationVariables = {
  input: UpdateDatasetInput,
  condition?: ModelDatasetConditionInput,
};

export type UpdateDatasetMutation = {
  updateDataset:  {
    __typename?: "Dataset",
    id: string,
  },
};

export type DeleteDatasetMutationVariables = {
  input: DeleteDatasetInput,
  condition?: ModelDatasetConditionInput,
};

export type DeleteDatasetMutation = {
  deleteDataset:  {
    __typename?: "Dataset",
    id: string,
  },
};

export type CreateDatasetTestMutationVariables = {
  input: CreateDatasetTestInput,
  condition?: ModelDatasetTestConditionInput,
};

export type CreateDatasetTestMutation = {
  createDatasetTest:  {
    __typename?: "DatasetTest",
    id: string,
  },
};

export type UpdateDatasetTestMutationVariables = {
  input: UpdateDatasetTestInput,
  condition?: ModelDatasetTestConditionInput,
};

export type UpdateDatasetTestMutation = {
  updateDatasetTest:  {
    __typename?: "DatasetTest",
    id: string,
  },
};

export type DeleteDatasetTestMutationVariables = {
  input: DeleteDatasetTestInput,
  condition?: ModelDatasetTestConditionInput,
};

export type DeleteDatasetTestMutation = {
  deleteDatasetTest:  {
    __typename?: "DatasetTest",
    id: string,
  },
};

export type CreateDatasetShareFromWorkspaceWithWorkspaceLinkMutationVariables = {
  input: CreateDatasetShareFromWorkspaceWithWorkspaceLinkInput,
  condition?: ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput,
};

export type CreateDatasetShareFromWorkspaceWithWorkspaceLinkMutation = {
  createDatasetShareFromWorkspaceWithWorkspaceLink:  {
    __typename?: "DatasetShareFromWorkspaceWithWorkspaceLink",
    id: string,
  },
};

export type DeleteDatasetShareFromWorkspaceWithWorkspaceLinkMutationVariables = {
  input: DeleteDatasetShareFromWorkspaceWithWorkspaceLinkInput,
  condition?: ModelDatasetShareFromWorkspaceWithWorkspaceLinkConditionInput,
};

export type DeleteDatasetShareFromWorkspaceWithWorkspaceLinkMutation = {
  deleteDatasetShareFromWorkspaceWithWorkspaceLink:  {
    __typename?: "DatasetShareFromWorkspaceWithWorkspaceLink",
    id: string,
  },
};

export type CreateDatasetWorkspaceShareMutationVariables = {
  input: CreateDatasetWorkspaceShareInput,
  condition?: ModelDatasetWorkspaceShareConditionInput,
};

export type CreateDatasetWorkspaceShareMutation = {
  createDatasetWorkspaceShare:  {
    __typename?: "DatasetWorkspaceShare",
    id: string,
  },
};

export type DeleteDatasetWorkspaceShareMutationVariables = {
  input: DeleteDatasetWorkspaceShareInput,
  condition?: ModelDatasetWorkspaceShareConditionInput,
};

export type DeleteDatasetWorkspaceShareMutation = {
  deleteDatasetWorkspaceShare:  {
    __typename?: "DatasetWorkspaceShare",
    id: string,
  },
};

export type CreateDatasetSqlQueryMutationVariables = {
  input: CreateDatasetSqlQueryInput,
  condition?: ModelDatasetSqlQueryConditionInput,
};

export type CreateDatasetSqlQueryMutation = {
  createDatasetSqlQuery:  {
    __typename?: "DatasetSqlQuery",
    id: string,
  },
};

export type UpdateDatasetSqlQueryMutationVariables = {
  input: UpdateDatasetSqlQueryInput,
  condition?: ModelDatasetSqlQueryConditionInput,
};

export type UpdateDatasetSqlQueryMutation = {
  updateDatasetSqlQuery:  {
    __typename?: "DatasetSqlQuery",
    id: string,
  },
};

export type DeleteDatasetSqlQueryMutationVariables = {
  input: DeleteDatasetSqlQueryInput,
  condition?: ModelDatasetSqlQueryConditionInput,
};

export type DeleteDatasetSqlQueryMutation = {
  deleteDatasetSqlQuery:  {
    __typename?: "DatasetSqlQuery",
    id: string,
  },
};

export type CreateFlowMutationVariables = {
  input: CreateFlowInput,
  condition?: ModelFlowConditionInput,
};

export type CreateFlowMutation = {
  createFlow:  {
    __typename?: "Flow",
    id: string,
  },
};

export type UpdateFlowMutationVariables = {
  input: UpdateFlowInput,
  condition?: ModelFlowConditionInput,
};

export type UpdateFlowMutation = {
  updateFlow:  {
    __typename?: "Flow",
    id: string,
  },
};

export type DeleteFlowMutationVariables = {
  input: DeleteFlowInput,
  condition?: ModelFlowConditionInput,
};

export type DeleteFlowMutation = {
  deleteFlow:  {
    __typename?: "Flow",
    id: string,
  },
};

export type CreateIntegrationMutationVariables = {
  input: CreateIntegrationInput,
  condition?: ModelIntegrationConditionInput,
};

export type CreateIntegrationMutation = {
  createIntegration:  {
    __typename?: "Integration",
    id: string,
  },
};

export type UpdateIntegrationMutationVariables = {
  input: UpdateIntegrationInput,
  condition?: ModelIntegrationConditionInput,
};

export type UpdateIntegrationMutation = {
  updateIntegration:  {
    __typename?: "Integration",
    id: string,
  },
};

export type DeleteIntegrationMutationVariables = {
  input: DeleteIntegrationInput,
  condition?: ModelIntegrationConditionInput,
};

export type DeleteIntegrationMutation = {
  deleteIntegration:  {
    __typename?: "Integration",
    id: string,
  },
};

export type CreateConnectionMutationVariables = {
  input: CreateConnectionInput,
  condition?: ModelConnectionConditionInput,
};

export type CreateConnectionMutation = {
  createConnection:  {
    __typename?: "Connection",
    id: string,
  },
};

export type UpdateConnectionMutationVariables = {
  input: UpdateConnectionInput,
  condition?: ModelConnectionConditionInput,
};

export type UpdateConnectionMutation = {
  updateConnection:  {
    __typename?: "Connection",
    id: string,
  },
};

export type DeleteConnectionMutationVariables = {
  input: DeleteConnectionInput,
  condition?: ModelConnectionConditionInput,
};

export type DeleteConnectionMutation = {
  deleteConnection:  {
    __typename?: "Connection",
    id: string,
  },
};

export type CreateFivetranConnectionMutationVariables = {
  input: CreateFivetranConnectionInput,
  condition?: ModelFivetranConnectionConditionInput,
};

export type CreateFivetranConnectionMutation = {
  createFivetranConnection:  {
    __typename?: "FivetranConnection",
    id: string,
  },
};

export type UpdateFivetranConnectionMutationVariables = {
  input: UpdateFivetranConnectionInput,
  condition?: ModelFivetranConnectionConditionInput,
};

export type UpdateFivetranConnectionMutation = {
  updateFivetranConnection:  {
    __typename?: "FivetranConnection",
    id: string,
  },
};

export type DeleteFivetranConnectionMutationVariables = {
  input: DeleteFivetranConnectionInput,
  condition?: ModelFivetranConnectionConditionInput,
};

export type DeleteFivetranConnectionMutation = {
  deleteFivetranConnection:  {
    __typename?: "FivetranConnection",
    id: string,
  },
};

export type CreateCredentialsMutationVariables = {
  input: CreateCredentialsInput,
  condition?: ModelCredentialsConditionInput,
};

export type CreateCredentialsMutation = {
  createCredentials:  {
    __typename?: "Credentials",
    id: string,
  },
};

export type UpdateCredentialsMutationVariables = {
  input: UpdateCredentialsInput,
  condition?: ModelCredentialsConditionInput,
};

export type UpdateCredentialsMutation = {
  updateCredentials:  {
    __typename?: "Credentials",
    id: string,
  },
};

export type DeleteCredentialsMutationVariables = {
  input: DeleteCredentialsInput,
  condition?: ModelCredentialsConditionInput,
};

export type DeleteCredentialsMutation = {
  deleteCredentials:  {
    __typename?: "Credentials",
    id: string,
  },
};

export type CreateFolderMutationVariables = {
  input: CreateFolderInput,
  condition?: ModelFolderConditionInput,
};

export type CreateFolderMutation = {
  createFolder:  {
    __typename?: "Folder",
    id: string,
  },
};

export type UpdateFolderMutationVariables = {
  input: UpdateFolderInput,
  condition?: ModelFolderConditionInput,
};

export type UpdateFolderMutation = {
  updateFolder:  {
    __typename?: "Folder",
    id: string,
  },
};

export type DeleteFolderMutationVariables = {
  input: DeleteFolderInput,
  condition?: ModelFolderConditionInput,
};

export type DeleteFolderMutation = {
  deleteFolder:  {
    __typename?: "Folder",
    id: string,
  },
};

export type CreateDownloadMutationVariables = {
  input: CreateDownloadInput,
  condition?: ModelDownloadConditionInput,
};

export type CreateDownloadMutation = {
  createDownload:  {
    __typename?: "Download",
    id: string,
  },
};

export type UpdateDownloadMutationVariables = {
  input: UpdateDownloadInput,
  condition?: ModelDownloadConditionInput,
};

export type UpdateDownloadMutation = {
  updateDownload:  {
    __typename?: "Download",
    id: string,
  },
};

export type DeleteDownloadMutationVariables = {
  input: DeleteDownloadInput,
  condition?: ModelDownloadConditionInput,
};

export type DeleteDownloadMutation = {
  deleteDownload:  {
    __typename?: "Download",
    id: string,
  },
};

export type CreateRoleMutationVariables = {
  input: CreateRoleInput,
  condition?: ModelRoleConditionInput,
};

export type CreateRoleMutation = {
  createRole:  {
    __typename?: "Role",
    id: string,
  },
};

export type UpdateRoleMutationVariables = {
  input: UpdateRoleInput,
  condition?: ModelRoleConditionInput,
};

export type UpdateRoleMutation = {
  updateRole:  {
    __typename?: "Role",
    id: string,
  },
};

export type DeleteRoleMutationVariables = {
  input: DeleteRoleInput,
  condition?: ModelRoleConditionInput,
};

export type DeleteRoleMutation = {
  deleteRole:  {
    __typename?: "Role",
    id: string,
  },
};

export type CreatePermissionMutationVariables = {
  input: CreatePermissionInput,
  condition?: ModelPermissionConditionInput,
};

export type CreatePermissionMutation = {
  createPermission:  {
    __typename?: "Permission",
    id: string,
  },
};

export type UpdatePermissionMutationVariables = {
  input: UpdatePermissionInput,
  condition?: ModelPermissionConditionInput,
};

export type UpdatePermissionMutation = {
  updatePermission:  {
    __typename?: "Permission",
    id: string,
  },
};

export type DeletePermissionMutationVariables = {
  input: DeletePermissionInput,
  condition?: ModelPermissionConditionInput,
};

export type DeletePermissionMutation = {
  deletePermission:  {
    __typename?: "Permission",
    id: string,
  },
};

export type BeemAgentInvokeDownloadMutationVariables = {
  input: InvokeDownloadInput,
};

export type BeemAgentInvokeDownloadMutation = {
  beemAgentInvokeDownload:  {
    __typename?: "Download",
    id: string,
    dataId: string,
    dataTimeStamp: number,
    requesterId?: string,
    requester:  {
      __typename?: "User",
      id: string,
      email: string,
      firstName?: string,
      lastName?: string,
    },
    requestTimeStamp: number,
    signedDownloadUrl: string,
  },
};

export type BeemAgentDeployDatasetSqlQueryMutationVariables = {
  input: DeployDatasetSqlQueryInput,
};

export type BeemAgentDeployDatasetSqlQueryMutation = {
  beemAgentDeployDatasetSqlQuery:  {
    __typename?: "DatasetSqlQuery",
    id: string,
  },
};

export type FivetranCreateGroupMutationVariables = {
  input: FivetranCreateGroupInput,
};

export type FivetranCreateGroupMutation = {
  fivetranCreateGroup:  {
    __typename?: "OrganizationFivetran",
    id: string,
    groupId: string,
  },
};

export type ModifyFlowMutationVariables = {
  input?: ModifyFlowInput,
};

export type ModifyFlowMutation = {
  modifyFlow:  {
    __typename?: "ModifyFlowResponse",
    placeholder?: FlowRunStatus,
  },
};

export type UpdateIDatasetUserMutationVariables = {
  input: UpdateIDatasetUserInput,
};

export type UpdateIDatasetUserMutation = {
  updateIDatasetUser:  {
    __typename?: "IDatasetUser",
    id: string,
  },
};

export type CreateIDatasetUserMutationVariables = {
  input: CreateIDatasetUserInput,
};

export type CreateIDatasetUserMutation = {
  createIDatasetUser:  {
    __typename?: "IDatasetUser",
    id: string,
  },
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename?: "User",
    id: string,
  },
};

export type DeleteIDatasetUserMutationVariables = {
  input: DeleteIDatasetUserInput,
  condition?: ModelIDatasetUserConditionInput,
};

export type DeleteIDatasetUserMutation = {
  deleteIDatasetUser:  {
    __typename?: "IDatasetUser",
    id: string,
  },
};

export type ListUsersOnlyAuth0MigrationQueryVariables = {
  filter?: ModelUserFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListUsersOnlyAuth0MigrationQuery = {
  listUsers:  {
    __typename?: "ModelUserConnection",
    items:  Array< {
      __typename?: "User",
      id: string,
      email: string,
      firstName?: string,
      lastName?: string,
    } >,
    nextToken?: string,
  },
};

export type ListOrganizationUsersOnlyAuth0MigrationQueryVariables = {
  filter?: ModelOrganizationUserFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListOrganizationUsersOnlyAuth0MigrationQuery = {
  listOrganizationUsers:  {
    __typename?: "ModelOrganizationUserConnection",
    items:  Array< {
      __typename?: "OrganizationUser",
      id: string,
      organizationId: string,
      userId: string,
      hasWarehouseCredentials?: boolean,
    } >,
    nextToken?: string,
  },
};

export type ListWorkspaceUsersOnlyAuth0MigrationQueryVariables = {
  filter?: ModelWorkspaceUserFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListWorkspaceUsersOnlyAuth0MigrationQuery = {
  listWorkspaceUsers:  {
    __typename?: "ModelWorkspaceUserConnection",
    items:  Array< {
      __typename?: "WorkspaceUser",
      id: string,
      workspaceId: string,
      userId: string,
    } >,
    nextToken?: string,
  },
};

export type ListIDatasetUsersOnlyAuth0MigrationQueryVariables = {
  filter?: ModelIDatasetUserFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListIDatasetUsersOnlyAuth0MigrationQuery = {
  listIDatasetUsers:  {
    __typename?: "ModelIDatasetUserConnection",
    items:  Array< {
      __typename?: "IDatasetUser",
      id: string,
      datasetId: string,
      userId: string,
      options: string,
    } >,
    nextToken?: string,
  },
};

export type ListDatasetsOnlyAuth0MigrationQueryVariables = {
  filter?: ModelDatasetFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetsOnlyAuth0MigrationQuery = {
  listDatasets:  {
    __typename?: "ModelDatasetConnection",
    items:  Array< {
      __typename?: "Dataset",
      id: string,
      ownerId: string,
    } >,
    nextToken?: string,
  },
};

export type ListDatasetTestsOnlyAuth0MigrationQueryVariables = {
  filter?: ModelDatasetTestFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetTestsOnlyAuth0MigrationQuery = {
  listDatasetTests:  {
    __typename?: "ModelDatasetTestConnection",
    items:  Array< {
      __typename?: "DatasetTest",
      id: string,
      ownerId: string,
    } >,
    nextToken?: string,
  },
};

export type ListConnectionsOnlyAuth0MigrationQueryVariables = {
  filter?: ModelConnectionFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListConnectionsOnlyAuth0MigrationQuery = {
  listConnections:  {
    __typename?: "ModelConnectionConnection",
    items:  Array< {
      __typename?: "Connection",
      id: string,
      ownerId: string,
    } >,
    nextToken?: string,
  },
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename?: "User",
    id: string,
    email: string,
    firstName?: string,
    lastName?: string,
    workspaces?:  {
      __typename?: "ModelWorkspaceUserConnection",
      items:  Array< {
        __typename?: "WorkspaceUser",
        workspace:  {
          __typename?: "Workspace",
          id: string,
          name: string,
          organizationId: string,
        },
      } >,
    },
    organizations?:  {
      __typename?: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename?: "OrganizationUser",
        id: string,
        organizationId: string,
      } >,
    },
  },
};

export type ListUsersByEmailQueryVariables = {
  email?: string,
  id?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelUserFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListUsersByEmailQuery = {
  listUsersByEmail:  {
    __typename?: "ModelUserConnection",
    items:  Array< {
      __typename?: "User",
      id: string,
      email: string,
      firstName?: string,
      lastName?: string,
    } >,
    nextToken?: string,
  },
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization:  {
    __typename?: "Organization",
    id: string,
    name: string,
    color: string,
    workspaceLimit: number,
    warehouseRetention?: number,
    timezone: string,
    users?:  {
      __typename?: "ModelOrganizationUserConnection",
      items:  Array< {
        __typename?: "OrganizationUser",
        user:  {
          __typename?: "User",
          id: string,
          email: string,
          firstName?: string,
          lastName?: string,
          workspaces?:  {
            __typename?: "ModelWorkspaceUserConnection",
            items:  Array< {
              __typename?: "WorkspaceUser",
              workspace:  {
                __typename?: "Workspace",
                id: string,
                name: string,
                organizationId: string,
              },
            } >,
          },
          organizations?:  {
            __typename?: "ModelOrganizationUserConnection",
            items:  Array< {
              __typename?: "OrganizationUser",
              id: string,
              organizationId: string,
            } >,
          },
        },
      } >,
      nextToken?: string,
    },
    cloudResourceId: string,
    workspaces?:  {
      __typename?: "ModelWorkspaceConnection",
      items:  Array< {
        __typename?: "Workspace",
        id: string,
        name: string,
      } >,
    },
  },
};

export type ListOrganizationUsersByUserQueryVariables = {
  userId?: string,
  organizationId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelOrganizationUserFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListOrganizationUsersByUserQuery = {
  listOrganizationUsersByUser:  {
    __typename?: "ModelOrganizationUserConnection",
    items:  Array< {
      __typename?: "OrganizationUser",
      id: string,
      organizationId: string,
      hasWarehouseCredentials?: boolean,
    } >,
    nextToken?: string,
  },
};

export type GetOrganizationFivetranQueryVariables = {
  id: string,
};

export type GetOrganizationFivetranQuery = {
  getOrganizationFivetran:  {
    __typename?: "OrganizationFivetran",
    id: string,
    groupId: string,
  },
};

export type GetOrganizationChargebeeQueryVariables = {
  id: string,
};

export type GetOrganizationChargebeeQuery = {
  getOrganizationChargebee:  {
    __typename?: "OrganizationChargebee",
    id: string,
    subscriptionId: string,
    allowance: number,
    billingCycleDate: number,
    createdAt: string,
    updatedAt: string,
  },
};

export type ListOrganizationChargebeesQueryVariables = {
  filter?: ModelOrganizationChargebeeFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListOrganizationChargebeesQuery = {
  listOrganizationChargebees:  {
    __typename?: "ModelOrganizationChargebeeConnection",
    items:  Array< {
      __typename?: "OrganizationChargebee",
      id: string,
      subscriptionId: string,
      allowance: number,
      billingCycleDate: number,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string,
  },
};

export type GetOrganizationDPUQueryVariables = {
  id: string,
};

export type GetOrganizationDPUQuery = {
  getOrganizationDPU:  {
    __typename?: "OrganizationDPU",
    id: string,
    organizationId: string,
    date: string,
    dpu: number,
    createdAt: string,
    updatedAt: string,
  },
};

export type ListOrganizationDPUsQueryVariables = {
  filter?: ModelOrganizationDPUFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListOrganizationDPUsQuery = {
  listOrganizationDPUs:  {
    __typename?: "ModelOrganizationDPUConnection",
    items:  Array< {
      __typename?: "OrganizationDPU",
      id: string,
      organizationId: string,
      date: string,
      dpu: number,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string,
  },
};

export type ListOrganizationDPUsByOrganizationQueryVariables = {
  organizationId?: string,
  date?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelOrganizationDPUFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListOrganizationDPUsByOrganizationQuery = {
  listOrganizationDPUsByOrganization:  {
    __typename?: "ModelOrganizationDPUConnection",
    items:  Array< {
      __typename?: "OrganizationDPU",
      id: string,
      organizationId: string,
      date: string,
      dpu: number,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string,
  },
};

export type GetWorkspaceQueryVariables = {
  id: string,
};

export type GetWorkspaceQuery = {
  getWorkspace:  {
    __typename?: "Workspace",
    id: string,
    name: string,
    seq: number,
    organization:  {
      __typename?: "Organization",
      id: string,
      name: string,
      color: string,
      workspaceLimit: number,
      warehouseRetention?: number,
      timezone: string,
      cloudResourceId: string,
    },
    users?:  {
      __typename?: "ModelWorkspaceUserConnection",
      items:  Array< {
        __typename?: "WorkspaceUser",
        user:  {
          __typename?: "User",
          id: string,
          email: string,
          firstName?: string,
          lastName?: string,
        },
      } >,
      nextToken?: string,
    },
  },
};

export type ListWorkspacesByOrganizationQueryVariables = {
  organizationId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelWorkspaceFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListWorkspacesByOrganizationQuery = {
  listWorkspacesByOrganization:  {
    __typename?: "ModelWorkspaceConnection",
    items:  Array< {
      __typename?: "Workspace",
      id: string,
      name: string,
      seq: number,
      organization:  {
        __typename?: "Organization",
        id: string,
        name: string,
        color: string,
        workspaceLimit: number,
        warehouseRetention?: number,
        timezone: string,
        cloudResourceId: string,
      },
    } >,
    nextToken?: string,
  },
};

export type ListWorkspaceUsersByUserQueryVariables = {
  userId?: string,
  workspaceId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelWorkspaceUserFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListWorkspaceUsersByUserQuery = {
  listWorkspaceUsersByUser:  {
    __typename?: "ModelWorkspaceUserConnection",
    items:  Array< {
      __typename?: "WorkspaceUser",
      id: string,
      user:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      workspace:  {
        __typename?: "Workspace",
        id: string,
        name: string,
        seq: number,
        organization:  {
          __typename?: "Organization",
          id: string,
          name: string,
          color: string,
          workspaceLimit: number,
          warehouseRetention?: number,
          timezone: string,
          cloudResourceId: string,
          workspaces?:  {
            __typename?: "ModelWorkspaceConnection",
            items:  Array< {
              __typename?: "Workspace",
              id: string,
              name: string,
            } >,
          },
        },
      },
    } >,
    nextToken?: string,
  },
};

export type GetSourceQueryVariables = {
  id: string,
};

export type GetSourceQuery = {
  getSource:  {
    __typename?: "Source",
    id: string,
    name: string,
    description?: string,
    connectionId: string,
    workspaceId: string,
    cloudResourceId: string,
  },
};

export type ListSourcesByWorkspaceQueryVariables = {
  workspaceId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelSourceFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListSourcesByWorkspaceQuery = {
  listSourcesByWorkspace:  {
    __typename?: "ModelSourceConnection",
    items:  Array< {
      __typename?: "Source",
      id: string,
      name: string,
      description?: string,
      connectionId: string,
      workspaceId: string,
      cloudResourceId: string,
    } >,
    nextToken?: string,
  },
};

export type ListSourcesByConnectionQueryVariables = {
  connectionId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelSourceFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListSourcesByConnectionQuery = {
  listSourcesByConnection:  {
    __typename?: "ModelSourceConnection",
    items:  Array< {
      __typename?: "Source",
      id: string,
    } >,
    nextToken?: string,
  },
};

export type ListSourcesByCloudResourceIdQueryVariables = {
  cloudResourceId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelSourceFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListSourcesByCloudResourceIdQuery = {
  listSourcesByCloudResourceId:  {
    __typename?: "ModelSourceConnection",
    items:  Array< {
      __typename?: "Source",
      id: string,
    } >,
    nextToken?: string,
  },
};

export type ListSourceWorkspaceSharesBySourceQueryVariables = {
  sourceId?: string,
  workspaceId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelSourceWorkspaceShareFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListSourceWorkspaceSharesBySourceQuery = {
  listSourceWorkspaceSharesBySource:  {
    __typename?: "ModelSourceWorkspaceShareConnection",
    items:  Array< {
      __typename?: "SourceWorkspaceShare",
      workspaceId: string,
    } >,
    nextToken?: string,
  },
};

export type ListSourceWorkspaceSharesByWorkspaceQueryVariables = {
  workspaceId?: string,
  sourceId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelSourceWorkspaceShareFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListSourceWorkspaceSharesByWorkspaceQuery = {
  listSourceWorkspaceSharesByWorkspace:  {
    __typename?: "ModelSourceWorkspaceShareConnection",
    items:  Array< {
      __typename?: "SourceWorkspaceShare",
      source:  {
        __typename?: "Source",
        id: string,
        name: string,
        description?: string,
        connectionId: string,
        workspaceId: string,
        cloudResourceId: string,
      },
    } >,
    nextToken?: string,
  },
};

export type GetDataObjectQueryVariables = {
  id: string,
};

export type GetDataObjectQuery = {
  getDataObject:  {
    __typename?: "DataObject",
    id: string,
    name: string,
    viewName: string,
    sourceId: string,
    source:  {
      __typename?: "Source",
      workspace:  {
        __typename?: "Workspace",
        seq: number,
      },
    },
    cloudResourceId: string,
    cloudResourceInfo?: string,
  },
};

export type ListDataObjectsBySourceQueryVariables = {
  sourceId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDataObjectFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDataObjectsBySourceQuery = {
  listDataObjectsBySource:  {
    __typename?: "ModelDataObjectConnection",
    items:  Array< {
      __typename?: "DataObject",
      id: string,
      name: string,
      viewName: string,
      sourceId: string,
      source:  {
        __typename?: "Source",
        workspace:  {
          __typename?: "Workspace",
          seq: number,
        },
      },
      cloudResourceId: string,
      cloudResourceInfo?: string,
    } >,
    nextToken?: string,
  },
};

export type ListDataObjectsByViewNameQueryVariables = {
  viewName?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDataObjectFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDataObjectsByViewNameQuery = {
  listDataObjectsByViewName:  {
    __typename?: "ModelDataObjectConnection",
    items:  Array< {
      __typename?: "DataObject",
      id: string,
      name: string,
      viewName: string,
      sourceId: string,
      source:  {
        __typename?: "Source",
        workspace:  {
          __typename?: "Workspace",
          seq: number,
        },
      },
      cloudResourceId: string,
      cloudResourceInfo?: string,
    } >,
    nextToken?: string,
  },
};

export type GetDatasetQueryVariables = {
  id: string,
};

export type GetDatasetQuery = {
  getDataset:  {
    __typename?: "Dataset",
    id: string,
    ownerId: string,
    owner:  {
      __typename?: "User",
      id: string,
      email: string,
      firstName?: string,
      lastName?: string,
    },
    name: string,
    description?: string,
    enableSearchByAI?: boolean,
    viewName: string,
    isDraft: boolean,
    folderId: string,
    trigger: Trigger,
    triggerDetails?: string,
    workspaceId: string,
    workspace:  {
      __typename?: "Workspace",
      seq: number,
      organizationId: string,
    },
    cloudResourceId: string,
    cloudResourceInfo?: string,
  },
};

export type GetDatasetOnlySharedWithWorkspaceIdsQueryVariables = {
  id: string,
};

export type GetDatasetOnlySharedWithWorkspaceIdsQuery = {
  getDataset:  {
    __typename?: "Dataset",
    sharedWithWorkspaceIds?: Array< string >,
  },
};

export type ListDatasetsByFolderQueryVariables = {
  folderId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetsByFolderQuery = {
  listDatasetsByFolder:  {
    __typename?: "ModelDatasetConnection",
    items:  Array< {
      __typename?: "Dataset",
      id: string,
      ownerId: string,
      owner:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      name: string,
      description?: string,
      enableSearchByAI?: boolean,
      viewName: string,
      isDraft: boolean,
      folderId: string,
      trigger: Trigger,
      triggerDetails?: string,
      workspaceId: string,
      workspace:  {
        __typename?: "Workspace",
        seq: number,
        organizationId: string,
      },
      cloudResourceId: string,
      cloudResourceInfo?: string,
    } >,
    nextToken?: string,
  },
};

export type ListDatasetsByViewNameQueryVariables = {
  viewName?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetsByViewNameQuery = {
  listDatasetsByViewName:  {
    __typename?: "ModelDatasetConnection",
    items:  Array< {
      __typename?: "Dataset",
      id: string,
      ownerId: string,
      owner:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      name: string,
      description?: string,
      enableSearchByAI?: boolean,
      viewName: string,
      isDraft: boolean,
      folderId: string,
      trigger: Trigger,
      triggerDetails?: string,
      workspaceId: string,
      workspace:  {
        __typename?: "Workspace",
        seq: number,
        organizationId: string,
      },
      cloudResourceId: string,
      cloudResourceInfo?: string,
    } >,
    nextToken?: string,
  },
};

export type ListDatasetsByWorkspaceQueryVariables = {
  workspaceId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetsByWorkspaceQuery = {
  listDatasetsByWorkspace:  {
    __typename?: "ModelDatasetConnection",
    items:  Array< {
      __typename?: "Dataset",
      id: string,
      ownerId: string,
      owner:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      name: string,
      description?: string,
      enableSearchByAI?: boolean,
      viewName: string,
      isDraft: boolean,
      folderId: string,
      trigger: Trigger,
      triggerDetails?: string,
      workspaceId: string,
      workspace:  {
        __typename?: "Workspace",
        seq: number,
        organizationId: string,
      },
      cloudResourceId: string,
      cloudResourceInfo?: string,
    } >,
    nextToken?: string,
  },
};

export type ListDatasetsByWorkspaceOnlyIdAndNameQueryVariables = {
  workspaceId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetsByWorkspaceOnlyIdAndNameQuery = {
  listDatasetsByWorkspace:  {
    __typename?: "ModelDatasetConnection",
    items:  Array< {
      __typename?: "Dataset",
      id: string,
      name: string,
    } >,
    nextToken?: string,
  },
};

export type GetDatasetTestQueryVariables = {
  id: string,
};

export type GetDatasetTestQuery = {
  getDatasetTest:  {
    __typename?: "DatasetTest",
    id: string,
    datasetId: string,
    ownerId: string,
    name: string,
    isDraft: boolean,
    workspaceId: string,
    cloudResourceId: string,
    cloudResourceInfo?: string,
  },
};

export type ListDatasetTestsByDatasetQueryVariables = {
  datasetId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetTestFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetTestsByDatasetQuery = {
  listDatasetTestsByDataset:  {
    __typename?: "ModelDatasetTestConnection",
    items:  Array< {
      __typename?: "DatasetTest",
      id: string,
      datasetId: string,
      ownerId: string,
      name: string,
      isDraft: boolean,
      workspaceId: string,
      cloudResourceId: string,
      cloudResourceInfo?: string,
    } >,
  },
};

export type GetDatasetShareFromWorkspaceWithWorkspaceLinkQueryVariables = {
  id: string,
};

export type GetDatasetShareFromWorkspaceWithWorkspaceLinkQuery = {
  getDatasetShareFromWorkspaceWithWorkspaceLink:  {
    __typename?: "DatasetShareFromWorkspaceWithWorkspaceLink",
    id: string,
  },
};

export type ListDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceIdQueryVariables = {
  withWorkspaceId?: string,
  fromWorkspaceId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetShareFromWorkspaceWithWorkspaceLinkFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceIdQuery = {
  listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId:  {
    __typename?: "ModelDatasetShareFromWorkspaceWithWorkspaceLinkConnection",
    items:  Array< {
      __typename?: "DatasetShareFromWorkspaceWithWorkspaceLink",
      fromWorkspace:  {
        __typename?: "Workspace",
        id: string,
        name: string,
      },
    } >,
    nextToken?: string,
  },
};

export type ListDatasetWorkspaceSharesByDatasetQueryVariables = {
  datasetId?: string,
  workspaceId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetWorkspaceShareFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetWorkspaceSharesByDatasetQuery = {
  listDatasetWorkspaceSharesByDataset:  {
    __typename?: "ModelDatasetWorkspaceShareConnection",
    items:  Array< {
      __typename?: "DatasetWorkspaceShare",
      id: string,
      workspaceId: string,
    } >,
    nextToken?: string,
  },
};

export type ListDatasetWorkspaceSharesByWorkspaceAndOriginWorkspaceQueryVariables = {
  workspaceId?: string,
  originWorkspaceId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetWorkspaceShareFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDatasetWorkspaceSharesByWorkspaceAndOriginWorkspaceQuery = {
  listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace:  {
    __typename?: "ModelDatasetWorkspaceShareConnection",
    items:  Array< {
      __typename?: "DatasetWorkspaceShare",
      dataset:  {
        __typename?: "Dataset",
        id: string,
        ownerId: string,
        owner:  {
          __typename?: "User",
          id: string,
          email: string,
          firstName?: string,
          lastName?: string,
        },
        name: string,
        viewName: string,
        isDraft: boolean,
        folderId: string,
        trigger: Trigger,
        triggerDetails?: string,
        workspaceId: string,
        workspace:  {
          __typename?: "Workspace",
          seq: number,
          organizationId: string,
        },
        cloudResourceId: string,
        cloudResourceInfo?: string,
      },
    } >,
    nextToken?: string,
  },
};

export type GetFlowQueryVariables = {
  id: string,
};

export type GetFlowQuery = {
  getFlow:  {
    __typename?: "Flow",
    id: string,
    name: string,
    enabled: boolean,
    trigger: Trigger,
    triggerDetails?: string,
    workspaceId: string,
    createdAt: string,
    datasetId: string,
    dataset:  {
      __typename?: "Dataset",
      name: string,
    },
    destinationId: string,
    destination:  {
      __typename?: "Connection",
      integration?:  {
        __typename?: "Integration",
        name: string,
      },
    },
    cloudResourceId: string,
    cloudResourceInfo?: string,
  },
};

export type ListFlowsByWorkspaceQueryVariables = {
  workspaceId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelFlowFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListFlowsByWorkspaceQuery = {
  listFlowsByWorkspace:  {
    __typename?: "ModelFlowConnection",
    items:  Array< {
      __typename?: "Flow",
      id: string,
      name: string,
      enabled: boolean,
      trigger: Trigger,
      triggerDetails?: string,
      workspaceId: string,
      createdAt: string,
      datasetId: string,
      dataset:  {
        __typename?: "Dataset",
        name: string,
      },
      destinationId: string,
      destination:  {
        __typename?: "Connection",
        integration?:  {
          __typename?: "Integration",
          name: string,
        },
      },
      cloudResourceId: string,
      cloudResourceInfo?: string,
    } >,
    nextToken?: string,
  },
};

export type GetIntegrationQueryVariables = {
  id: string,
};

export type GetIntegrationQuery = {
  getIntegration:  {
    __typename?: "Integration",
    id: string,
    name: string,
    type: IntegrationType,
    category: IntegrationCategory,
    description?: string,
    dataSourceSupport: boolean,
    flowActions?: Array< string >,
    createdAt: string,
  },
};

export type ListIntegrationsQueryVariables = {
  filter?: ModelIntegrationFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListIntegrationsQuery = {
  listIntegrations:  {
    __typename?: "ModelIntegrationConnection",
    items:  Array< {
      __typename?: "Integration",
      id: string,
      name: string,
      type: IntegrationType,
      category: IntegrationCategory,
      description?: string,
      dataSourceSupport: boolean,
      flowActions?: Array< string >,
      createdAt: string,
    } >,
    nextToken?: string,
  },
};

export type GetConnectionOnlyNativeQueryVariables = {
  id: string,
};

export type GetConnectionOnlyNativeQuery = {
  getConnection:  {
    __typename?: "Connection",
    id: string,
    name?: string,
    organizationId: string,
    workspaceId: string,
    owner:  {
      __typename?: "User",
      id: string,
      email: string,
      firstName?: string,
      lastName?: string,
    },
    integration?:  {
      __typename?: "Integration",
      id: string,
      name: string,
      type: IntegrationType,
      category: IntegrationCategory,
      description?: string,
      dataSourceSupport: boolean,
      flowActions?: Array< string >,
      createdAt: string,
    },
    credentialsId?: string,
  },
};

export type GetConnectionOnlyFivetranQueryVariables = {
  id: string,
};

export type GetConnectionOnlyFivetranQuery = {
  getConnection:  {
    __typename?: "Connection",
    id: string,
    name?: string,
    organizationId: string,
    workspaceId: string,
    owner:  {
      __typename?: "User",
      id: string,
      email: string,
      firstName?: string,
      lastName?: string,
    },
    fivetranConnection?:  {
      __typename?: "FivetranConnection",
      id: string,
      name: string,
      serviceName: string,
      glueDatabaseName: string,
      integrationName: string,
      isDeployed?: boolean,
    },
  },
};

export type ListConnectionsByWorkspaceNativeQueryVariables = {
  workspaceId?: string,
  integrationId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelConnectionFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListConnectionsByWorkspaceNativeQuery = {
  listConnectionsByWorkspaceNative:  {
    __typename?: "ModelConnectionConnection",
    items:  Array< {
      __typename?: "Connection",
      id: string,
      name?: string,
      organizationId: string,
      workspaceId: string,
      owner:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      integration?:  {
        __typename?: "Integration",
        id: string,
        name: string,
        type: IntegrationType,
        category: IntegrationCategory,
        description?: string,
        dataSourceSupport: boolean,
        flowActions?: Array< string >,
        createdAt: string,
      },
      credentialsId?: string,
    } >,
    nextToken?: string,
  },
};

export type ListConnectionsByWorkspaceFivetranQueryVariables = {
  workspaceId?: string,
  fivetranConnectionId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelConnectionFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListConnectionsByWorkspaceFivetranQuery = {
  listConnectionsByWorkspaceFivetran:  {
    __typename?: "ModelConnectionConnection",
    items:  Array< {
      __typename?: "Connection",
      id: string,
      name?: string,
      organizationId: string,
      workspaceId: string,
      owner:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      fivetranConnection?:  {
        __typename?: "FivetranConnection",
        id: string,
        name: string,
        serviceName: string,
        glueDatabaseName: string,
        integrationName: string,
        isDeployed?: boolean,
      },
    } >,
    nextToken?: string,
  },
};

export type ListConnectionsByOrganizationFivetranQueryVariables = {
  organizationId?: string,
  fivetranConnectionId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelConnectionFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListConnectionsByOrganizationFivetranQuery = {
  listConnectionsByOrganizationFivetran:  {
    __typename?: "ModelConnectionConnection",
    items:  Array< {
      __typename?: "Connection",
      id: string,
      name?: string,
      organizationId: string,
      workspaceId: string,
      owner:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      fivetranConnection?:  {
        __typename?: "FivetranConnection",
        id: string,
        name: string,
        serviceName: string,
        glueDatabaseName: string,
        integrationName: string,
        isDeployed?: boolean,
      },
    } >,
    nextToken?: string,
  },
};

export type ListConnectionsByOrganizationNativeQueryVariables = {
  organizationId?: string,
  integrationId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelConnectionFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListConnectionsByOrganizationNativeQuery = {
  listConnectionsByOrganizationNative:  {
    __typename?: "ModelConnectionConnection",
    items:  Array< {
      __typename?: "Connection",
      id: string,
      name?: string,
      organizationId: string,
      workspaceId: string,
      owner:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      integration?:  {
        __typename?: "Integration",
        id: string,
        name: string,
        type: IntegrationType,
        category: IntegrationCategory,
        description?: string,
        dataSourceSupport: boolean,
        flowActions?: Array< string >,
        createdAt: string,
      },
      credentialsId?: string,
    } >,
    nextToken?: string,
  },
};

export type ListConnectionsByOrganizationNativeOnlyIdAndNameQueryVariables = {
  organizationId?: string,
  integrationId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelConnectionFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListConnectionsByOrganizationNativeOnlyIdAndNameQuery = {
  listConnectionsByOrganizationNative:  {
    __typename?: "ModelConnectionConnection",
    items:  Array< {
      __typename?: "Connection",
      id: string,
      integration?:  {
        __typename?: "Integration",
        name: string,
      },
    } >,
    nextToken?: string,
  },
};

export type ListConnectionsByFivetranConnectionQueryVariables = {
  fivetranConnectionId?: string,
  organizationId?: ModelIDKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelConnectionFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListConnectionsByFivetranConnectionQuery = {
  listConnectionsByFivetranConnection:  {
    __typename?: "ModelConnectionConnection",
    items:  Array< {
      __typename?: "Connection",
      id: string,
    } >,
    nextToken?: string,
  },
};

export type GetFivetranConnectionQueryVariables = {
  id: string,
};

export type GetFivetranConnectionQuery = {
  getFivetranConnection:  {
    __typename?: "FivetranConnection",
    id: string,
    name: string,
    serviceName: string,
    glueDatabaseName: string,
    integrationName: string,
    isDeployed?: boolean,
  },
};

export type GetCredentialsQueryVariables = {
  id: string,
};

export type GetCredentialsQuery = {
  getCredentials:  {
    __typename?: "Credentials",
    id: string,
    username: string,
    password: string,
  },
};

export type GetFolderQueryVariables = {
  id: string,
};

export type GetFolderQuery = {
  getFolder:  {
    __typename?: "Folder",
    id: string,
    name: string,
    parentId: string,
    parent?:  {
      __typename?: "Folder",
      id: string,
      name: string,
    },
    subFolders?:  {
      __typename?: "ModelFolderConnection",
      items:  Array< {
        __typename?: "Folder",
        id: string,
        name: string,
      } >,
      nextToken?: string,
    },
    datasets?:  {
      __typename?: "ModelDatasetConnection",
      items:  Array< {
        __typename?: "Dataset",
        id: string,
        name: string,
      } >,
      nextToken?: string,
    },
  },
};

export type ListFoldersByParentQueryVariables = {
  parentId?: string,
  name?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelFolderFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListFoldersByParentQuery = {
  listFoldersByParent:  {
    __typename?: "ModelFolderConnection",
    items:  Array< {
      __typename?: "Folder",
      id: string,
      name: string,
      parentId: string,
      parent?:  {
        __typename?: "Folder",
        id: string,
        name: string,
      },
    } >,
    nextToken?: string,
  },
};

export type GetDownloadQueryVariables = {
  id: string,
};

export type GetDownloadQuery = {
  getDownload:  {
    __typename?: "Download",
    id: string,
    dataId: string,
    dataTimeStamp: number,
    requesterId?: string,
    requester:  {
      __typename?: "User",
      id: string,
      email: string,
      firstName?: string,
      lastName?: string,
    },
    requestTimeStamp: number,
    signedDownloadUrl: string,
  },
};

export type ListDownloadsByDataQueryVariables = {
  dataId?: string,
  dataTimeStamp?: ModelIntKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDownloadFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListDownloadsByDataQuery = {
  listDownloadsByData:  {
    __typename?: "ModelDownloadConnection",
    items:  Array< {
      __typename?: "Download",
      id: string,
      dataId: string,
      dataTimeStamp: number,
      requesterId?: string,
      requester:  {
        __typename?: "User",
        id: string,
        email: string,
        firstName?: string,
        lastName?: string,
      },
      requestTimeStamp: number,
      signedDownloadUrl: string,
    } >,
    nextToken?: string,
  },
};

export type SqlQueriesByDatasetQueryVariables = {
  datasetId?: string,
  createdAt?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelDatasetSqlQueryFilterInput,
  limit?: number,
  nextToken?: string,
};

export type SqlQueriesByDatasetQuery = {
  sqlQueriesByDataset:  {
    __typename?: "ModelDatasetSqlQueryConnection",
    items:  Array< {
      __typename?: "DatasetSqlQuery",
      id: string,
      datasetId: string,
      workspaceId?: string,
      sqlQuery: string,
      trigger?: Trigger,
      triggerDetails?: string,
      deployed?: boolean,
      createdAt: string,
      updatedAt: string,
    } >,
    nextToken?: string,
  },
};

export type PipelineResultsByRecordCloudResourceIdQueryVariables = {
  recordCloudResourceId?: string,
  timestamp?: ModelIntKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelPipelineResultFilterInput,
  limit?: number,
  nextToken?: string,
};

export type PipelineResultsByRecordCloudResourceIdQuery = {
  pipelineResultsByRecordCloudResourceId:  {
    __typename?: "ModelPipelineResultConnection",
    items:  Array< {
      __typename?: "PipelineResult",
      id: string,
      recordCloudResourceId: string,
      timestamp: number,
      status: PipelineResultStatus,
      info?: string,
    } >,
    nextToken?: string,
  },
};

export type ListEventsByWorkspaceQueryVariables = {
  actionSourceWorkspaceId?: string,
  actionRecordedAt?: ModelStringKeyConditionInput,
  sortDirection?: ModelSortDirection,
  filter?: ModelEventFilterInput,
  limit?: number,
  nextToken?: string,
};

export type ListEventsByWorkspaceQuery = {
  listEventsByWorkspace:  {
    __typename?: "ModelEventConnection",
    items:  Array< {
      __typename?: "Event",
      id: string,
      message: string,
      level: EventLevel,
      userId?: string,
      actionType: EventActionType,
      actionSourceId: string,
      actionSourceName: string,
      actionSourceType: EventObjectType,
      actionSourceWorkspaceId: string,
      actionData: string,
      actionRecordedAt: string,
      createdAt: string,
    } >,
    nextToken?: string,
  },
};

export type BeemAgentGetDataObjectContentQueryVariables = {
  query?: GetDataObjectContentQueryInput,
};

export type BeemAgentGetDataObjectContentQuery = {
  beemAgentGetDataObjectContent:  {
    __typename?: "GetDataObjectContentQueryOutput",
    executionId: string,
  },
};

export type BeemAgentGetDatasetInfoQueryVariables = {
  query?: GetDatasetInfoQueryInput,
};

export type BeemAgentGetDatasetInfoQuery = {
  beemAgentGetDatasetInfo:  {
    __typename?: "GetDatasetInfoQueryOutput",
    info: string,
  },
};

export type BeemAgentGetDatasetContentQueryVariables = {
  query?: GetDatasetContentQueryInput,
};

export type BeemAgentGetDatasetContentQuery = {
  beemAgentGetDatasetContent:  {
    __typename?: "GetDatasetContentQueryOutput",
    executionId: string,
  },
};

export type BeemAgentGetDatasetConsolidatedTestResultsQueryVariables = {
  query?: GetDatasetConsolidatedTestResultsQueryInput,
};

export type BeemAgentGetDatasetConsolidatedTestResultsQuery = {
  beemAgentGetDatasetConsolidatedTestResults:  {
    __typename?: "GetDatasetConsolidatedTestResultsQueryOutput",
    executionId: string,
  },
};

export type BeemAgentModifyDatasetQueryVariables = {
  query?: ModifyDatasetQueryInput,
};

export type BeemAgentModifyDatasetQuery = {
  beemAgentModifyDataset:  {
    __typename?: "ModifyDatasetQueryOutput",
    dummy?: boolean,
  },
};

export type BeemAgentDeleteDatasetHistoricalDataQueryVariables = {
  query?: DeleteDatasetHistoricalDataQueryInput,
};

export type BeemAgentDeleteDatasetHistoricalDataQuery = {
  beemAgentDeleteDatasetHistoricalData:  {
    __typename?: "DeleteDatasetHistoricalDataQueryOutput",
    dummy?: boolean,
  },
};

export type BeemAgentGetFlowInfoQueryVariables = {
  query?: GetFlowInfoQueryInput,
};

export type BeemAgentGetFlowInfoQuery = {
  beemAgentGetFlowInfo:  {
    __typename?: "GetFlowInfoQueryOutput",
    info: string,
  },
};

export type BeemAgentCheckRedshiftQueryQueryVariables = {
  query?: CheckRedshiftQueryInput,
};

export type BeemAgentCheckRedshiftQueryQuery = {
  beemAgentCheckRedshiftQuery:  {
    __typename?: "CheckRedshiftQueryOutput",
    status: string,
    rows?: string,
    error?: string,
  },
};

export type BeemAgentCancelRedshiftQueryQueryVariables = {
  query?: CancelRedshiftQueryInput,
};

export type BeemAgentCancelRedshiftQueryQuery = {
  beemAgentCancelRedshiftQuery:  {
    __typename?: "CancelRedshiftQueryOutput",
    status: boolean,
  },
};

export type BeemAgentInsightConverseQueryVariables = {
  query?: InsightConverseInput,
};

export type BeemAgentInsightConverseQuery = {
  beemAgentInsightConverse:  {
    __typename?: "InsightConverseOutput",
    messages: string,
    metadata: string,
  },
};

export type BeemAgentInsightGetSampleDataStartQueryVariables = {
  query?: InsightGetSampleDataStartQueryInput,
};

export type BeemAgentInsightGetSampleDataStartQuery = {
  beemAgentInsightGetSampleDataStart:  {
    __typename?: "InsightGetSampleDataStartQueryOutput",
    executionIds: Array< string >,
  },
};

export type BeemAgentInsightGetSampleDataCheckQueryVariables = {
  query?: InsightGetSampleDataCheckQueryInput,
};

export type BeemAgentInsightGetSampleDataCheckQuery = {
  beemAgentInsightGetSampleDataCheck:  {
    __typename?: "InsightGetSampleDataCheckQueryOutput",
    executions: string,
  },
};

export type BeemAgentInsightGetQueryFromBedrockQueryVariables = {
  query?: InsightGetQueryFromBedrockQueryInput,
};

export type BeemAgentInsightGetQueryFromBedrockQuery = {
  beemAgentInsightGetQueryFromBedrock:  {
    __typename?: "InsightGetQueryFromBedrockQueryOutput",
    query: string,
    messages: string,
  },
};

export type BeemAgentInsightExecuteBedrockQueryStartQueryVariables = {
  query?: InsightExecuteBedrockQueryStartQueryInput,
};

export type BeemAgentInsightExecuteBedrockQueryStartQuery = {
  beemAgentInsightExecuteBedrockQueryStart:  {
    __typename?: "InsightExecuteBedrockQueryStartQueryOutput",
    executionId: string,
  },
};

export type BeemAgentInsightExecuteBedrockQueryCheckQueryVariables = {
  query?: InsightExecuteBedrockQueryCheckQueryInput,
};

export type BeemAgentInsightExecuteBedrockQueryCheckQuery = {
  beemAgentInsightExecuteBedrockQueryCheck:  {
    __typename?: "InsightExecuteBedrockQueryCheckQueryOutput",
    status: string,
  },
};

export type BeemAgentInsightInterpretResultQueryVariables = {
  query?: InsightInterpretResultQueryInput,
};

export type BeemAgentInsightInterpretResultQuery = {
  beemAgentInsightInterpretResult:  {
    __typename?: "InsightInterpretResultQueryOutput",
    result: string,
    data?: string,
  },
};

export type FivetranFetchConnectionsQueryVariables = {
  query?: FivetranFetchConnectionsInput,
};

export type FivetranFetchConnectionsQuery = {
  fivetranFetchConnections:  {
    __typename?: "FivetranFetchConnectionsOutput",
    response: string,
  },
};

export type TestDatasetStartQueryVariables = {
  query?: TestDatasetStartQueryInput,
};

export type TestDatasetStartQuery = {
  testDatasetStart:  {
    __typename?: "TestDatasetStartQueryResponse",
    executionId: string,
  },
};

export type RefreshDatasetQueryVariables = {
  query?: RefreshDatasetQueryInput,
};

export type RefreshDatasetQuery = {
  refreshDataset:  {
    __typename?: "RefreshDatasetQueryOutput",
    success: boolean,
  },
};

export type ValidateIntegrationCredentialsQueryVariables = {
  query?: ValidateIntegrationCredentialsQueryInput,
};

export type ValidateIntegrationCredentialsQuery = {
  validateIntegrationCredentials: string,
};

export type UpdateOrganizationSettingsQueryVariables = {
  query?: UpdateOrganizationSettingsInput,
};

export type UpdateOrganizationSettingsQuery = {
  updateOrganizationSettings:  {
    __typename?: "UpdateOrganizationSettingsOutput",
    success: boolean,
  },
};

export type GetWarehouseStorageInfoQueryVariables = {
  query?: GetWarehouseStorageInfoInput,
};

export type GetWarehouseStorageInfoQuery = {
  getWarehouseStorageInfo:  {
    __typename?: "GetWarehouseStorageInfoOutput",
    totalStorageCapacityInMegaBytes: number,
    totalStorageUsedInMegaBytes: number,
  },
};

export type GetWarehouseConnectionInfoQueryVariables = {
  query?: GetWarehouseConnectionInfoInput,
};

export type GetWarehouseConnectionInfoQuery = {
  getWarehouseConnectionInfo:  {
    __typename?: "GetWarehouseConnectionInfoOutput",
    host: string,
    port: string,
    database: string,
  },
};

export type ConfigureNewWorkspaceInTheWarehouseQueryVariables = {
  query?: ConfigureNewWorkspaceInTheWarehouseInput,
};

export type ConfigureNewWorkspaceInTheWarehouseQuery = {
  configureNewWorkspaceInTheWarehouse:  {
    __typename?: "ConfigureNewWorkspaceInTheWarehouseOutput",
    success: boolean,
  },
};

export type UpsertUserWarehouseCredentialsQueryVariables = {
  query?: UpsertUserWarehouseCredentialsInput,
};

export type UpsertUserWarehouseCredentialsQuery = {
  upsertUserWarehouseCredentials:  {
    __typename?: "UpsertUserWarehouseCredentialsOutput",
    password: string,
  },
};

export type CreateCognitoUserQueryVariables = {
  query?: CreateCognitoUserInput,
};

export type CreateCognitoUserQuery = {
  createCognitoUser:  {
    __typename?: "CreateCognitoUserOutput",
    id: string,
  },
};

export type FivetranCreateConnectCardQueryVariables = {
  query?: FivetranCreateConnectCardInput,
};

export type FivetranCreateConnectCardQuery = {
  fivetranCreateConnectCard:  {
    __typename?: "FivetranCreateConnectCardOutput",
    response: string,
  },
};

export type FivetranRecreateConnectCardQueryVariables = {
  query?: FivetranRecreateConnectCardInput,
};

export type FivetranRecreateConnectCardQuery = {
  fivetranRecreateConnectCard:  {
    __typename?: "FivetranRecreateConnectCardOutput",
    response: string,
  },
};

export type BeemAgentFivetranDeployConnectorQueryVariables = {
  query?: FivetranDeployConnectorQueryInput,
};

export type BeemAgentFivetranDeployConnectorQuery = {
  beemAgentFivetranDeployConnector:  {
    __typename?: "FivetranDeployConnectorQueryOutput",
    dummy?: boolean,
  },
};

export type BeemAgentFivetranUndeployConnectorQueryVariables = {
  query?: FivetranUndeployConnectorQueryInput,
};

export type BeemAgentFivetranUndeployConnectorQuery = {
  beemAgentFivetranUndeployConnector:  {
    __typename?: "FivetranUndeployConnectorQueryOutput",
    dummy?: boolean,
  },
};

export type BeemAgentAdminLogoutUserQueryVariables = {
  query?: AdminLogoutUserInput,
};

export type BeemAgentAdminLogoutUserQuery = {
  beemAgentAdminLogoutUser:  {
    __typename?: "AdminLogoutUserOutput",
    dummy?: boolean,
  },
};

export type FivetranDeleteConnectorQueryVariables = {
  query?: FivetranDeleteConnectorInput,
};

export type FivetranDeleteConnectorQuery = {
  fivetranDeleteConnector:  {
    __typename?: "FivetranDeleteConnectorOutput",
    code: string,
    message: string,
  },
};

export type GetIDatasetUserQueryVariables = {
  id: string,
};

export type GetIDatasetUserQuery = {
  getIDatasetUser:  {
    __typename?: "IDatasetUser",
    id: string,
    datasetId: string,
    userId: string,
    options: string,
  },
};

export type Auth0CreateUserQueryVariables = {
  query?: Auth0CreateUserInput,
};

export type Auth0CreateUserQuery = {
  auth0CreateUser:  {
    __typename?: "Auth0CreateUserOutput",
    id: string,
  },
};

export type Auth0ChangePasswordQueryVariables = {
  query?: Auth0ChangePasswordInput,
};

export type Auth0ChangePasswordQuery = {
  auth0ChangePassword:  {
    __typename?: "Auth0ChangePasswordOutput",
    dummy?: boolean,
  },
};

export type Auth0UpdateUserMetadataQueryVariables = {
  query?: Auth0UpdateUserMetadataInput,
};

export type Auth0UpdateUserMetadataQuery = {
  auth0UpdateUserMetadata:  {
    __typename?: "Auth0UpdateUserMetadataOutput",
    dummy?: boolean,
  },
};

export type OnCreatePipelineResultSubscriptionVariables = {
};

export type OnCreatePipelineResultSubscription = {
  onCreatePipelineResult:  {
    __typename?: "PipelineResult",
    id: string,
    recordCloudResourceId: string,
    timestamp: number,
    status: PipelineResultStatus,
    info?: string,
  },
};

export type OnCreateInsightMessageSubscriptionVariables = {
  userId?: string,
};

export type OnCreateInsightMessageSubscription = {
  onCreateInsightMessage:  {
    __typename?: "InsightMessage",
    id: string,
    workspaceUserId: string,
    userId: string,
    createdAt: string,
    role: InsightMessageRole,
    content: string,
  },
};

type Query0 =
  | 'listUsersOnlyAuth0Migration'
  | 'listOrganizationUsersOnlyAuth0Migration'
  | 'listWorkspaceUsersOnlyAuth0Migration'
  | 'listIDatasetUsersOnlyAuth0Migration'
  | 'listDatasetsOnlyAuth0Migration'
  | 'listDatasetTestsOnlyAuth0Migration'
  | 'listConnectionsOnlyAuth0Migration'
  | 'getUser'
  | 'listUsersByEmail'
  | 'getOrganization';

type Query1 =
  | 'listOrganizationUsersByUser'
  | 'getOrganizationFivetran'
  | 'getOrganizationChargebee'
  | 'listOrganizationChargebees'
  | 'getOrganizationDPU'
  | 'listOrganizationDPUs'
  | 'listOrganizationDPUsByOrganization'
  | 'getWorkspace'
  | 'listWorkspacesByOrganization'
  | 'listWorkspaceUsersByUser';

type Query2 =
  | 'getSource'
  | 'listSourcesByWorkspace'
  | 'listSourcesByConnection'
  | 'listSourcesByCloudResourceId'
  | 'listSourceWorkspaceSharesBySource'
  | 'listSourceWorkspaceSharesByWorkspace'
  | 'getDataObject'
  | 'listDataObjectsBySource'
  | 'listDataObjectsByViewName'
  | 'getDataset';

type Query3 =
  | 'getDatasetOnlySharedWithWorkspaceIds'
  | 'listDatasetsByFolder'
  | 'listDatasetsByViewName'
  | 'listDatasetsByWorkspace'
  | 'listDatasetsByWorkspaceOnlyIdAndName'
  | 'getDatasetTest'
  | 'listDatasetTestsByDataset'
  | 'getDatasetShareFromWorkspaceWithWorkspaceLink'
  | 'listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId'
  | 'listDatasetWorkspaceSharesByDataset';

type Query4 =
  | 'listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace'
  | 'getFlow'
  | 'listFlowsByWorkspace'
  | 'getIntegration'
  | 'listIntegrations'
  | 'getConnectionOnlyNative'
  | 'getConnectionOnlyFivetran'
  | 'listConnectionsByWorkspaceNative'
  | 'listConnectionsByWorkspaceFivetran'
  | 'listConnectionsByOrganizationFivetran';

type Query5 =
  | 'listConnectionsByOrganizationNative'
  | 'listConnectionsByOrganizationNativeOnlyIdAndName'
  | 'listConnectionsByFivetranConnection'
  | 'getFivetranConnection'
  | 'getCredentials'
  | 'getFolder'
  | 'listFoldersByParent'
  | 'getDownload'
  | 'listDownloadsByData'
  | 'sqlQueriesByDataset';

type Query6 =
  | 'pipelineResultsByRecordCloudResourceId'
  | 'listEventsByWorkspace'
  | 'beemAgentGetDataObjectContent'
  | 'beemAgentGetDatasetInfo'
  | 'beemAgentGetDatasetContent'
  | 'beemAgentGetDatasetConsolidatedTestResults'
  | 'beemAgentModifyDataset'
  | 'beemAgentDeleteDatasetHistoricalData'
  | 'beemAgentGetFlowInfo'
  | 'beemAgentCheckRedshiftQuery';

type Query7 =
  | 'beemAgentCancelRedshiftQuery'
  | 'beemAgentInsightConverse'
  | 'beemAgentInsightGetSampleDataStart'
  | 'beemAgentInsightGetSampleDataCheck'
  | 'beemAgentInsightGetQueryFromBedrock'
  | 'beemAgentInsightExecuteBedrockQueryStart'
  | 'beemAgentInsightExecuteBedrockQueryCheck'
  | 'beemAgentInsightInterpretResult'
  | 'fivetranFetchConnections'
  | 'testDatasetStart';

type Query8 =
  | 'refreshDataset'
  | 'validateIntegrationCredentials'
  | 'updateOrganizationSettings'
  | 'getWarehouseStorageInfo'
  | 'getWarehouseConnectionInfo'
  | 'configureNewWorkspaceInTheWarehouse'
  | 'upsertUserWarehouseCredentials'
  | 'createCognitoUser'
  | 'fivetranCreateConnectCard'
  | 'fivetranRecreateConnectCard';

type Query9 =
  | 'beemAgentFivetranDeployConnector'
  | 'beemAgentFivetranUndeployConnector'
  | 'beemAgentAdminLogoutUser'
  | 'fivetranDeleteConnector'
  | 'getIDatasetUser'
  | 'auth0CreateUser'
  | 'auth0ChangePassword'
  | 'auth0UpdateUserMetadata';

type Mutation0 =
  | 'createUser'
  | 'updateUser'
  | 'createOrganization'
  | 'updateOrganization'
  | 'createOrganizationUser'
  | 'updateOrganizationUser'
  | 'deleteOrganizationUser'
  | 'createOrganizationFivetran'
  | 'createWorkspace'
  | 'updateWorkspace';

type Mutation1 =
  | 'createWorkspaceUser'
  | 'deleteWorkspaceUser'
  | 'createSource'
  | 'updateSource'
  | 'deleteSource'
  | 'createSourceWorkspaceShare'
  | 'createDataObject'
  | 'updateDataObject'
  | 'deleteDataObject'
  | 'createDataset';

type Mutation2 =
  | 'updateDataset'
  | 'deleteDataset'
  | 'createDatasetTest'
  | 'updateDatasetTest'
  | 'deleteDatasetTest'
  | 'createDatasetShareFromWorkspaceWithWorkspaceLink'
  | 'deleteDatasetShareFromWorkspaceWithWorkspaceLink'
  | 'createDatasetWorkspaceShare'
  | 'deleteDatasetWorkspaceShare'
  | 'createDatasetSqlQuery';

type Mutation3 =
  | 'updateDatasetSqlQuery'
  | 'deleteDatasetSqlQuery'
  | 'createFlow'
  | 'updateFlow'
  | 'deleteFlow'
  | 'createIntegration'
  | 'updateIntegration'
  | 'deleteIntegration'
  | 'createConnection'
  | 'updateConnection';

type Mutation4 =
  | 'deleteConnection'
  | 'createFivetranConnection'
  | 'updateFivetranConnection'
  | 'deleteFivetranConnection'
  | 'createCredentials'
  | 'updateCredentials'
  | 'deleteCredentials'
  | 'createFolder'
  | 'updateFolder'
  | 'deleteFolder';

type Mutation5 =
  | 'createDownload'
  | 'updateDownload'
  | 'deleteDownload'
  | 'createRole'
  | 'updateRole'
  | 'deleteRole'
  | 'createPermission'
  | 'updatePermission'
  | 'deletePermission'
  | 'beemAgentInvokeDownload';

type Mutation6 =
  | 'beemAgentDeployDatasetSqlQuery'
  | 'fivetranCreateGroup'
  | 'modifyFlow'
  | 'updateIDatasetUser'
  | 'createIDatasetUser'
  | 'deleteUser'
  | 'deleteIDatasetUser';

type Subscription0 =
  | 'onCreatePipelineResult'
  | 'onCreateInsightMessage';

export type Query = Query0 | Query1 | Query2 | Query3 | Query4 | Query5 | Query6 | Query7 | Query8 | Query9;

export type Mutation = Mutation0 | Mutation1 | Mutation2 | Mutation3 | Mutation4 | Mutation5 | Mutation6;

export type Subscription = Subscription0;

type VariablesQuery0<T extends Query0> = T extends 'listUsersOnlyAuth0Migration'
  ? ListUsersOnlyAuth0MigrationQueryVariables
  : T extends 'listOrganizationUsersOnlyAuth0Migration'
  ? ListOrganizationUsersOnlyAuth0MigrationQueryVariables
  : T extends 'listWorkspaceUsersOnlyAuth0Migration'
  ? ListWorkspaceUsersOnlyAuth0MigrationQueryVariables
  : T extends 'listIDatasetUsersOnlyAuth0Migration'
  ? ListIDatasetUsersOnlyAuth0MigrationQueryVariables
  : T extends 'listDatasetsOnlyAuth0Migration'
  ? ListDatasetsOnlyAuth0MigrationQueryVariables
  : T extends 'listDatasetTestsOnlyAuth0Migration'
  ? ListDatasetTestsOnlyAuth0MigrationQueryVariables
  : T extends 'listConnectionsOnlyAuth0Migration'
  ? ListConnectionsOnlyAuth0MigrationQueryVariables
  : T extends 'getUser'
  ? GetUserQueryVariables
  : T extends 'listUsersByEmail'
  ? ListUsersByEmailQueryVariables
  : T extends 'getOrganization'
  ? GetOrganizationQueryVariables
  : never;

type VariablesQuery1<T extends Query1> = T extends 'listOrganizationUsersByUser'
  ? ListOrganizationUsersByUserQueryVariables
  : T extends 'getOrganizationFivetran'
  ? GetOrganizationFivetranQueryVariables
  : T extends 'getOrganizationChargebee'
  ? GetOrganizationChargebeeQueryVariables
  : T extends 'listOrganizationChargebees'
  ? ListOrganizationChargebeesQueryVariables
  : T extends 'getOrganizationDPU'
  ? GetOrganizationDPUQueryVariables
  : T extends 'listOrganizationDPUs'
  ? ListOrganizationDPUsQueryVariables
  : T extends 'listOrganizationDPUsByOrganization'
  ? ListOrganizationDPUsByOrganizationQueryVariables
  : T extends 'getWorkspace'
  ? GetWorkspaceQueryVariables
  : T extends 'listWorkspacesByOrganization'
  ? ListWorkspacesByOrganizationQueryVariables
  : T extends 'listWorkspaceUsersByUser'
  ? ListWorkspaceUsersByUserQueryVariables
  : never;

type VariablesQuery2<T extends Query2> = T extends 'getSource'
  ? GetSourceQueryVariables
  : T extends 'listSourcesByWorkspace'
  ? ListSourcesByWorkspaceQueryVariables
  : T extends 'listSourcesByConnection'
  ? ListSourcesByConnectionQueryVariables
  : T extends 'listSourcesByCloudResourceId'
  ? ListSourcesByCloudResourceIdQueryVariables
  : T extends 'listSourceWorkspaceSharesBySource'
  ? ListSourceWorkspaceSharesBySourceQueryVariables
  : T extends 'listSourceWorkspaceSharesByWorkspace'
  ? ListSourceWorkspaceSharesByWorkspaceQueryVariables
  : T extends 'getDataObject'
  ? GetDataObjectQueryVariables
  : T extends 'listDataObjectsBySource'
  ? ListDataObjectsBySourceQueryVariables
  : T extends 'listDataObjectsByViewName'
  ? ListDataObjectsByViewNameQueryVariables
  : T extends 'getDataset'
  ? GetDatasetQueryVariables
  : never;

type VariablesQuery3<T extends Query3> = T extends 'getDatasetOnlySharedWithWorkspaceIds'
  ? GetDatasetOnlySharedWithWorkspaceIdsQueryVariables
  : T extends 'listDatasetsByFolder'
  ? ListDatasetsByFolderQueryVariables
  : T extends 'listDatasetsByViewName'
  ? ListDatasetsByViewNameQueryVariables
  : T extends 'listDatasetsByWorkspace'
  ? ListDatasetsByWorkspaceQueryVariables
  : T extends 'listDatasetsByWorkspaceOnlyIdAndName'
  ? ListDatasetsByWorkspaceOnlyIdAndNameQueryVariables
  : T extends 'getDatasetTest'
  ? GetDatasetTestQueryVariables
  : T extends 'listDatasetTestsByDataset'
  ? ListDatasetTestsByDatasetQueryVariables
  : T extends 'getDatasetShareFromWorkspaceWithWorkspaceLink'
  ? GetDatasetShareFromWorkspaceWithWorkspaceLinkQueryVariables
  : T extends 'listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId'
  ? ListDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceIdQueryVariables
  : T extends 'listDatasetWorkspaceSharesByDataset'
  ? ListDatasetWorkspaceSharesByDatasetQueryVariables
  : never;

type VariablesQuery4<T extends Query4> = T extends 'listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace'
  ? ListDatasetWorkspaceSharesByWorkspaceAndOriginWorkspaceQueryVariables
  : T extends 'getFlow'
  ? GetFlowQueryVariables
  : T extends 'listFlowsByWorkspace'
  ? ListFlowsByWorkspaceQueryVariables
  : T extends 'getIntegration'
  ? GetIntegrationQueryVariables
  : T extends 'listIntegrations'
  ? ListIntegrationsQueryVariables
  : T extends 'getConnectionOnlyNative'
  ? GetConnectionOnlyNativeQueryVariables
  : T extends 'getConnectionOnlyFivetran'
  ? GetConnectionOnlyFivetranQueryVariables
  : T extends 'listConnectionsByWorkspaceNative'
  ? ListConnectionsByWorkspaceNativeQueryVariables
  : T extends 'listConnectionsByWorkspaceFivetran'
  ? ListConnectionsByWorkspaceFivetranQueryVariables
  : T extends 'listConnectionsByOrganizationFivetran'
  ? ListConnectionsByOrganizationFivetranQueryVariables
  : never;

type VariablesQuery5<T extends Query5> = T extends 'listConnectionsByOrganizationNative'
  ? ListConnectionsByOrganizationNativeQueryVariables
  : T extends 'listConnectionsByOrganizationNativeOnlyIdAndName'
  ? ListConnectionsByOrganizationNativeOnlyIdAndNameQueryVariables
  : T extends 'listConnectionsByFivetranConnection'
  ? ListConnectionsByFivetranConnectionQueryVariables
  : T extends 'getFivetranConnection'
  ? GetFivetranConnectionQueryVariables
  : T extends 'getCredentials'
  ? GetCredentialsQueryVariables
  : T extends 'getFolder'
  ? GetFolderQueryVariables
  : T extends 'listFoldersByParent'
  ? ListFoldersByParentQueryVariables
  : T extends 'getDownload'
  ? GetDownloadQueryVariables
  : T extends 'listDownloadsByData'
  ? ListDownloadsByDataQueryVariables
  : T extends 'sqlQueriesByDataset'
  ? SqlQueriesByDatasetQueryVariables
  : never;

type VariablesQuery6<T extends Query6> = T extends 'pipelineResultsByRecordCloudResourceId'
  ? PipelineResultsByRecordCloudResourceIdQueryVariables
  : T extends 'listEventsByWorkspace'
  ? ListEventsByWorkspaceQueryVariables
  : T extends 'beemAgentGetDataObjectContent'
  ? BeemAgentGetDataObjectContentQueryVariables
  : T extends 'beemAgentGetDatasetInfo'
  ? BeemAgentGetDatasetInfoQueryVariables
  : T extends 'beemAgentGetDatasetContent'
  ? BeemAgentGetDatasetContentQueryVariables
  : T extends 'beemAgentGetDatasetConsolidatedTestResults'
  ? BeemAgentGetDatasetConsolidatedTestResultsQueryVariables
  : T extends 'beemAgentModifyDataset'
  ? BeemAgentModifyDatasetQueryVariables
  : T extends 'beemAgentDeleteDatasetHistoricalData'
  ? BeemAgentDeleteDatasetHistoricalDataQueryVariables
  : T extends 'beemAgentGetFlowInfo'
  ? BeemAgentGetFlowInfoQueryVariables
  : T extends 'beemAgentCheckRedshiftQuery'
  ? BeemAgentCheckRedshiftQueryQueryVariables
  : never;

type VariablesQuery7<T extends Query7> = T extends 'beemAgentCancelRedshiftQuery'
  ? BeemAgentCancelRedshiftQueryQueryVariables
  : T extends 'beemAgentInsightConverse'
  ? BeemAgentInsightConverseQueryVariables
  : T extends 'beemAgentInsightGetSampleDataStart'
  ? BeemAgentInsightGetSampleDataStartQueryVariables
  : T extends 'beemAgentInsightGetSampleDataCheck'
  ? BeemAgentInsightGetSampleDataCheckQueryVariables
  : T extends 'beemAgentInsightGetQueryFromBedrock'
  ? BeemAgentInsightGetQueryFromBedrockQueryVariables
  : T extends 'beemAgentInsightExecuteBedrockQueryStart'
  ? BeemAgentInsightExecuteBedrockQueryStartQueryVariables
  : T extends 'beemAgentInsightExecuteBedrockQueryCheck'
  ? BeemAgentInsightExecuteBedrockQueryCheckQueryVariables
  : T extends 'beemAgentInsightInterpretResult'
  ? BeemAgentInsightInterpretResultQueryVariables
  : T extends 'fivetranFetchConnections'
  ? FivetranFetchConnectionsQueryVariables
  : T extends 'testDatasetStart'
  ? TestDatasetStartQueryVariables
  : never;

type VariablesQuery8<T extends Query8> = T extends 'refreshDataset'
  ? RefreshDatasetQueryVariables
  : T extends 'validateIntegrationCredentials'
  ? ValidateIntegrationCredentialsQueryVariables
  : T extends 'updateOrganizationSettings'
  ? UpdateOrganizationSettingsQueryVariables
  : T extends 'getWarehouseStorageInfo'
  ? GetWarehouseStorageInfoQueryVariables
  : T extends 'getWarehouseConnectionInfo'
  ? GetWarehouseConnectionInfoQueryVariables
  : T extends 'configureNewWorkspaceInTheWarehouse'
  ? ConfigureNewWorkspaceInTheWarehouseQueryVariables
  : T extends 'upsertUserWarehouseCredentials'
  ? UpsertUserWarehouseCredentialsQueryVariables
  : T extends 'createCognitoUser'
  ? CreateCognitoUserQueryVariables
  : T extends 'fivetranCreateConnectCard'
  ? FivetranCreateConnectCardQueryVariables
  : T extends 'fivetranRecreateConnectCard'
  ? FivetranRecreateConnectCardQueryVariables
  : never;

type VariablesQuery9<T extends Query9> = T extends 'beemAgentFivetranDeployConnector'
  ? BeemAgentFivetranDeployConnectorQueryVariables
  : T extends 'beemAgentFivetranUndeployConnector'
  ? BeemAgentFivetranUndeployConnectorQueryVariables
  : T extends 'beemAgentAdminLogoutUser'
  ? BeemAgentAdminLogoutUserQueryVariables
  : T extends 'fivetranDeleteConnector'
  ? FivetranDeleteConnectorQueryVariables
  : T extends 'getIDatasetUser'
  ? GetIDatasetUserQueryVariables
  : T extends 'auth0CreateUser'
  ? Auth0CreateUserQueryVariables
  : T extends 'auth0ChangePassword'
  ? Auth0ChangePasswordQueryVariables
  : T extends 'auth0UpdateUserMetadata'
  ? Auth0UpdateUserMetadataQueryVariables
  : never;

type VariablesMutation0<T extends Mutation0> = T extends 'createUser'
  ? CreateUserMutationVariables
  : T extends 'updateUser'
  ? UpdateUserMutationVariables
  : T extends 'createOrganization'
  ? CreateOrganizationMutationVariables
  : T extends 'updateOrganization'
  ? UpdateOrganizationMutationVariables
  : T extends 'createOrganizationUser'
  ? CreateOrganizationUserMutationVariables
  : T extends 'updateOrganizationUser'
  ? UpdateOrganizationUserMutationVariables
  : T extends 'deleteOrganizationUser'
  ? DeleteOrganizationUserMutationVariables
  : T extends 'createOrganizationFivetran'
  ? CreateOrganizationFivetranMutationVariables
  : T extends 'createWorkspace'
  ? CreateWorkspaceMutationVariables
  : T extends 'updateWorkspace'
  ? UpdateWorkspaceMutationVariables
  : never;

type VariablesMutation1<T extends Mutation1> = T extends 'createWorkspaceUser'
  ? CreateWorkspaceUserMutationVariables
  : T extends 'deleteWorkspaceUser'
  ? DeleteWorkspaceUserMutationVariables
  : T extends 'createSource'
  ? CreateSourceMutationVariables
  : T extends 'updateSource'
  ? UpdateSourceMutationVariables
  : T extends 'deleteSource'
  ? DeleteSourceMutationVariables
  : T extends 'createSourceWorkspaceShare'
  ? CreateSourceWorkspaceShareMutationVariables
  : T extends 'createDataObject'
  ? CreateDataObjectMutationVariables
  : T extends 'updateDataObject'
  ? UpdateDataObjectMutationVariables
  : T extends 'deleteDataObject'
  ? DeleteDataObjectMutationVariables
  : T extends 'createDataset'
  ? CreateDatasetMutationVariables
  : never;

type VariablesMutation2<T extends Mutation2> = T extends 'updateDataset'
  ? UpdateDatasetMutationVariables
  : T extends 'deleteDataset'
  ? DeleteDatasetMutationVariables
  : T extends 'createDatasetTest'
  ? CreateDatasetTestMutationVariables
  : T extends 'updateDatasetTest'
  ? UpdateDatasetTestMutationVariables
  : T extends 'deleteDatasetTest'
  ? DeleteDatasetTestMutationVariables
  : T extends 'createDatasetShareFromWorkspaceWithWorkspaceLink'
  ? CreateDatasetShareFromWorkspaceWithWorkspaceLinkMutationVariables
  : T extends 'deleteDatasetShareFromWorkspaceWithWorkspaceLink'
  ? DeleteDatasetShareFromWorkspaceWithWorkspaceLinkMutationVariables
  : T extends 'createDatasetWorkspaceShare'
  ? CreateDatasetWorkspaceShareMutationVariables
  : T extends 'deleteDatasetWorkspaceShare'
  ? DeleteDatasetWorkspaceShareMutationVariables
  : T extends 'createDatasetSqlQuery'
  ? CreateDatasetSqlQueryMutationVariables
  : never;

type VariablesMutation3<T extends Mutation3> = T extends 'updateDatasetSqlQuery'
  ? UpdateDatasetSqlQueryMutationVariables
  : T extends 'deleteDatasetSqlQuery'
  ? DeleteDatasetSqlQueryMutationVariables
  : T extends 'createFlow'
  ? CreateFlowMutationVariables
  : T extends 'updateFlow'
  ? UpdateFlowMutationVariables
  : T extends 'deleteFlow'
  ? DeleteFlowMutationVariables
  : T extends 'createIntegration'
  ? CreateIntegrationMutationVariables
  : T extends 'updateIntegration'
  ? UpdateIntegrationMutationVariables
  : T extends 'deleteIntegration'
  ? DeleteIntegrationMutationVariables
  : T extends 'createConnection'
  ? CreateConnectionMutationVariables
  : T extends 'updateConnection'
  ? UpdateConnectionMutationVariables
  : never;

type VariablesMutation4<T extends Mutation4> = T extends 'deleteConnection'
  ? DeleteConnectionMutationVariables
  : T extends 'createFivetranConnection'
  ? CreateFivetranConnectionMutationVariables
  : T extends 'updateFivetranConnection'
  ? UpdateFivetranConnectionMutationVariables
  : T extends 'deleteFivetranConnection'
  ? DeleteFivetranConnectionMutationVariables
  : T extends 'createCredentials'
  ? CreateCredentialsMutationVariables
  : T extends 'updateCredentials'
  ? UpdateCredentialsMutationVariables
  : T extends 'deleteCredentials'
  ? DeleteCredentialsMutationVariables
  : T extends 'createFolder'
  ? CreateFolderMutationVariables
  : T extends 'updateFolder'
  ? UpdateFolderMutationVariables
  : T extends 'deleteFolder'
  ? DeleteFolderMutationVariables
  : never;

type VariablesMutation5<T extends Mutation5> = T extends 'createDownload'
  ? CreateDownloadMutationVariables
  : T extends 'updateDownload'
  ? UpdateDownloadMutationVariables
  : T extends 'deleteDownload'
  ? DeleteDownloadMutationVariables
  : T extends 'createRole'
  ? CreateRoleMutationVariables
  : T extends 'updateRole'
  ? UpdateRoleMutationVariables
  : T extends 'deleteRole'
  ? DeleteRoleMutationVariables
  : T extends 'createPermission'
  ? CreatePermissionMutationVariables
  : T extends 'updatePermission'
  ? UpdatePermissionMutationVariables
  : T extends 'deletePermission'
  ? DeletePermissionMutationVariables
  : T extends 'beemAgentInvokeDownload'
  ? BeemAgentInvokeDownloadMutationVariables
  : never;

type VariablesMutation6<T extends Mutation6> = T extends 'beemAgentDeployDatasetSqlQuery'
  ? BeemAgentDeployDatasetSqlQueryMutationVariables
  : T extends 'fivetranCreateGroup'
  ? FivetranCreateGroupMutationVariables
  : T extends 'modifyFlow'
  ? ModifyFlowMutationVariables
  : T extends 'updateIDatasetUser'
  ? UpdateIDatasetUserMutationVariables
  : T extends 'createIDatasetUser'
  ? CreateIDatasetUserMutationVariables
  : T extends 'deleteUser'
  ? DeleteUserMutationVariables
  : T extends 'deleteIDatasetUser'
  ? DeleteIDatasetUserMutationVariables
  : never;

type VariablesSubscription0<T extends Subscription0> = T extends 'onCreatePipelineResult'
  ? OnCreatePipelineResultSubscriptionVariables
  : T extends 'onCreateInsightMessage'
  ? OnCreateInsightMessageSubscriptionVariables
  : never;

export type Variables<T extends Query | Mutation | Subscription> = T extends Query0
  ? VariablesQuery0<T>
  : T extends Query1
  ? VariablesQuery1<T>
  : T extends Query2
  ? VariablesQuery2<T>
  : T extends Query3
  ? VariablesQuery3<T>
  : T extends Query4
  ? VariablesQuery4<T>
  : T extends Query5
  ? VariablesQuery5<T>
  : T extends Query6
  ? VariablesQuery6<T>
  : T extends Query7
  ? VariablesQuery7<T>
  : T extends Query8
  ? VariablesQuery8<T>
  : T extends Query9
  ? VariablesQuery9<T>
  : T extends Mutation0
  ? VariablesMutation0<T>
  : T extends Mutation1
  ? VariablesMutation1<T>
  : T extends Mutation2
  ? VariablesMutation2<T>
  : T extends Mutation3
  ? VariablesMutation3<T>
  : T extends Mutation4
  ? VariablesMutation4<T>
  : T extends Mutation5
  ? VariablesMutation5<T>
  : T extends Mutation6
  ? VariablesMutation6<T>
  : T extends Subscription0
  ? VariablesSubscription0<T>
  : never;

type ResultQuery0<T extends Query0> = T extends 'listUsersOnlyAuth0Migration'
  ? ListUsersOnlyAuth0MigrationQuery['listUsers']
  : T extends 'listOrganizationUsersOnlyAuth0Migration'
  ? ListOrganizationUsersOnlyAuth0MigrationQuery['listOrganizationUsers']
  : T extends 'listWorkspaceUsersOnlyAuth0Migration'
  ? ListWorkspaceUsersOnlyAuth0MigrationQuery['listWorkspaceUsers']
  : T extends 'listIDatasetUsersOnlyAuth0Migration'
  ? ListIDatasetUsersOnlyAuth0MigrationQuery['listIDatasetUsers']
  : T extends 'listDatasetsOnlyAuth0Migration'
  ? ListDatasetsOnlyAuth0MigrationQuery['listDatasets']
  : T extends 'listDatasetTestsOnlyAuth0Migration'
  ? ListDatasetTestsOnlyAuth0MigrationQuery['listDatasetTests']
  : T extends 'listConnectionsOnlyAuth0Migration'
  ? ListConnectionsOnlyAuth0MigrationQuery['listConnections']
  : T extends 'getUser'
  ? GetUserQuery['getUser']
  : T extends 'listUsersByEmail'
  ? ListUsersByEmailQuery['listUsersByEmail']
  : T extends 'getOrganization'
  ? GetOrganizationQuery['getOrganization']
  : never;

type ResultQuery1<T extends Query1> = T extends 'listOrganizationUsersByUser'
  ? ListOrganizationUsersByUserQuery['listOrganizationUsersByUser']
  : T extends 'getOrganizationFivetran'
  ? GetOrganizationFivetranQuery['getOrganizationFivetran']
  : T extends 'getOrganizationChargebee'
  ? GetOrganizationChargebeeQuery['getOrganizationChargebee']
  : T extends 'listOrganizationChargebees'
  ? ListOrganizationChargebeesQuery['listOrganizationChargebees']
  : T extends 'getOrganizationDPU'
  ? GetOrganizationDPUQuery['getOrganizationDPU']
  : T extends 'listOrganizationDPUs'
  ? ListOrganizationDPUsQuery['listOrganizationDPUs']
  : T extends 'listOrganizationDPUsByOrganization'
  ? ListOrganizationDPUsByOrganizationQuery['listOrganizationDPUsByOrganization']
  : T extends 'getWorkspace'
  ? GetWorkspaceQuery['getWorkspace']
  : T extends 'listWorkspacesByOrganization'
  ? ListWorkspacesByOrganizationQuery['listWorkspacesByOrganization']
  : T extends 'listWorkspaceUsersByUser'
  ? ListWorkspaceUsersByUserQuery['listWorkspaceUsersByUser']
  : never;

type ResultQuery2<T extends Query2> = T extends 'getSource'
  ? GetSourceQuery['getSource']
  : T extends 'listSourcesByWorkspace'
  ? ListSourcesByWorkspaceQuery['listSourcesByWorkspace']
  : T extends 'listSourcesByConnection'
  ? ListSourcesByConnectionQuery['listSourcesByConnection']
  : T extends 'listSourcesByCloudResourceId'
  ? ListSourcesByCloudResourceIdQuery['listSourcesByCloudResourceId']
  : T extends 'listSourceWorkspaceSharesBySource'
  ? ListSourceWorkspaceSharesBySourceQuery['listSourceWorkspaceSharesBySource']
  : T extends 'listSourceWorkspaceSharesByWorkspace'
  ? ListSourceWorkspaceSharesByWorkspaceQuery['listSourceWorkspaceSharesByWorkspace']
  : T extends 'getDataObject'
  ? GetDataObjectQuery['getDataObject']
  : T extends 'listDataObjectsBySource'
  ? ListDataObjectsBySourceQuery['listDataObjectsBySource']
  : T extends 'listDataObjectsByViewName'
  ? ListDataObjectsByViewNameQuery['listDataObjectsByViewName']
  : T extends 'getDataset'
  ? GetDatasetQuery['getDataset']
  : never;

type ResultQuery3<T extends Query3> = T extends 'getDatasetOnlySharedWithWorkspaceIds'
  ? GetDatasetOnlySharedWithWorkspaceIdsQuery['getDataset']
  : T extends 'listDatasetsByFolder'
  ? ListDatasetsByFolderQuery['listDatasetsByFolder']
  : T extends 'listDatasetsByViewName'
  ? ListDatasetsByViewNameQuery['listDatasetsByViewName']
  : T extends 'listDatasetsByWorkspace'
  ? ListDatasetsByWorkspaceQuery['listDatasetsByWorkspace']
  : T extends 'listDatasetsByWorkspaceOnlyIdAndName'
  ? ListDatasetsByWorkspaceOnlyIdAndNameQuery['listDatasetsByWorkspace']
  : T extends 'getDatasetTest'
  ? GetDatasetTestQuery['getDatasetTest']
  : T extends 'listDatasetTestsByDataset'
  ? ListDatasetTestsByDatasetQuery['listDatasetTestsByDataset']
  : T extends 'getDatasetShareFromWorkspaceWithWorkspaceLink'
  ? GetDatasetShareFromWorkspaceWithWorkspaceLinkQuery['getDatasetShareFromWorkspaceWithWorkspaceLink']
  : T extends 'listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId'
  ? ListDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceIdQuery['listDatasetShareFromWorkspaceWithWorkspaceLinksByWithWorkspaceId']
  : T extends 'listDatasetWorkspaceSharesByDataset'
  ? ListDatasetWorkspaceSharesByDatasetQuery['listDatasetWorkspaceSharesByDataset']
  : never;

type ResultQuery4<T extends Query4> = T extends 'listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace'
  ? ListDatasetWorkspaceSharesByWorkspaceAndOriginWorkspaceQuery['listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace']
  : T extends 'getFlow'
  ? GetFlowQuery['getFlow']
  : T extends 'listFlowsByWorkspace'
  ? ListFlowsByWorkspaceQuery['listFlowsByWorkspace']
  : T extends 'getIntegration'
  ? GetIntegrationQuery['getIntegration']
  : T extends 'listIntegrations'
  ? ListIntegrationsQuery['listIntegrations']
  : T extends 'getConnectionOnlyNative'
  ? GetConnectionOnlyNativeQuery['getConnection']
  : T extends 'getConnectionOnlyFivetran'
  ? GetConnectionOnlyFivetranQuery['getConnection']
  : T extends 'listConnectionsByWorkspaceNative'
  ? ListConnectionsByWorkspaceNativeQuery['listConnectionsByWorkspaceNative']
  : T extends 'listConnectionsByWorkspaceFivetran'
  ? ListConnectionsByWorkspaceFivetranQuery['listConnectionsByWorkspaceFivetran']
  : T extends 'listConnectionsByOrganizationFivetran'
  ? ListConnectionsByOrganizationFivetranQuery['listConnectionsByOrganizationFivetran']
  : never;

type ResultQuery5<T extends Query5> = T extends 'listConnectionsByOrganizationNative'
  ? ListConnectionsByOrganizationNativeQuery['listConnectionsByOrganizationNative']
  : T extends 'listConnectionsByOrganizationNativeOnlyIdAndName'
  ? ListConnectionsByOrganizationNativeOnlyIdAndNameQuery['listConnectionsByOrganizationNative']
  : T extends 'listConnectionsByFivetranConnection'
  ? ListConnectionsByFivetranConnectionQuery['listConnectionsByFivetranConnection']
  : T extends 'getFivetranConnection'
  ? GetFivetranConnectionQuery['getFivetranConnection']
  : T extends 'getCredentials'
  ? GetCredentialsQuery['getCredentials']
  : T extends 'getFolder'
  ? GetFolderQuery['getFolder']
  : T extends 'listFoldersByParent'
  ? ListFoldersByParentQuery['listFoldersByParent']
  : T extends 'getDownload'
  ? GetDownloadQuery['getDownload']
  : T extends 'listDownloadsByData'
  ? ListDownloadsByDataQuery['listDownloadsByData']
  : T extends 'sqlQueriesByDataset'
  ? SqlQueriesByDatasetQuery['sqlQueriesByDataset']
  : never;

type ResultQuery6<T extends Query6> = T extends 'pipelineResultsByRecordCloudResourceId'
  ? PipelineResultsByRecordCloudResourceIdQuery['pipelineResultsByRecordCloudResourceId']
  : T extends 'listEventsByWorkspace'
  ? ListEventsByWorkspaceQuery['listEventsByWorkspace']
  : T extends 'beemAgentGetDataObjectContent'
  ? BeemAgentGetDataObjectContentQuery['beemAgentGetDataObjectContent']
  : T extends 'beemAgentGetDatasetInfo'
  ? BeemAgentGetDatasetInfoQuery['beemAgentGetDatasetInfo']
  : T extends 'beemAgentGetDatasetContent'
  ? BeemAgentGetDatasetContentQuery['beemAgentGetDatasetContent']
  : T extends 'beemAgentGetDatasetConsolidatedTestResults'
  ? BeemAgentGetDatasetConsolidatedTestResultsQuery['beemAgentGetDatasetConsolidatedTestResults']
  : T extends 'beemAgentModifyDataset'
  ? BeemAgentModifyDatasetQuery['beemAgentModifyDataset']
  : T extends 'beemAgentDeleteDatasetHistoricalData'
  ? BeemAgentDeleteDatasetHistoricalDataQuery['beemAgentDeleteDatasetHistoricalData']
  : T extends 'beemAgentGetFlowInfo'
  ? BeemAgentGetFlowInfoQuery['beemAgentGetFlowInfo']
  : T extends 'beemAgentCheckRedshiftQuery'
  ? BeemAgentCheckRedshiftQueryQuery['beemAgentCheckRedshiftQuery']
  : never;

type ResultQuery7<T extends Query7> = T extends 'beemAgentCancelRedshiftQuery'
  ? BeemAgentCancelRedshiftQueryQuery['beemAgentCancelRedshiftQuery']
  : T extends 'beemAgentInsightConverse'
  ? BeemAgentInsightConverseQuery['beemAgentInsightConverse']
  : T extends 'beemAgentInsightGetSampleDataStart'
  ? BeemAgentInsightGetSampleDataStartQuery['beemAgentInsightGetSampleDataStart']
  : T extends 'beemAgentInsightGetSampleDataCheck'
  ? BeemAgentInsightGetSampleDataCheckQuery['beemAgentInsightGetSampleDataCheck']
  : T extends 'beemAgentInsightGetQueryFromBedrock'
  ? BeemAgentInsightGetQueryFromBedrockQuery['beemAgentInsightGetQueryFromBedrock']
  : T extends 'beemAgentInsightExecuteBedrockQueryStart'
  ? BeemAgentInsightExecuteBedrockQueryStartQuery['beemAgentInsightExecuteBedrockQueryStart']
  : T extends 'beemAgentInsightExecuteBedrockQueryCheck'
  ? BeemAgentInsightExecuteBedrockQueryCheckQuery['beemAgentInsightExecuteBedrockQueryCheck']
  : T extends 'beemAgentInsightInterpretResult'
  ? BeemAgentInsightInterpretResultQuery['beemAgentInsightInterpretResult']
  : T extends 'fivetranFetchConnections'
  ? FivetranFetchConnectionsQuery['fivetranFetchConnections']
  : T extends 'testDatasetStart'
  ? TestDatasetStartQuery['testDatasetStart']
  : never;

type ResultQuery8<T extends Query8> = T extends 'refreshDataset'
  ? RefreshDatasetQuery['refreshDataset']
  : T extends 'validateIntegrationCredentials'
  ? ValidateIntegrationCredentialsQuery['validateIntegrationCredentials']
  : T extends 'updateOrganizationSettings'
  ? UpdateOrganizationSettingsQuery['updateOrganizationSettings']
  : T extends 'getWarehouseStorageInfo'
  ? GetWarehouseStorageInfoQuery['getWarehouseStorageInfo']
  : T extends 'getWarehouseConnectionInfo'
  ? GetWarehouseConnectionInfoQuery['getWarehouseConnectionInfo']
  : T extends 'configureNewWorkspaceInTheWarehouse'
  ? ConfigureNewWorkspaceInTheWarehouseQuery['configureNewWorkspaceInTheWarehouse']
  : T extends 'upsertUserWarehouseCredentials'
  ? UpsertUserWarehouseCredentialsQuery['upsertUserWarehouseCredentials']
  : T extends 'createCognitoUser'
  ? CreateCognitoUserQuery['createCognitoUser']
  : T extends 'fivetranCreateConnectCard'
  ? FivetranCreateConnectCardQuery['fivetranCreateConnectCard']
  : T extends 'fivetranRecreateConnectCard'
  ? FivetranRecreateConnectCardQuery['fivetranRecreateConnectCard']
  : never;

type ResultQuery9<T extends Query9> = T extends 'beemAgentFivetranDeployConnector'
  ? BeemAgentFivetranDeployConnectorQuery['beemAgentFivetranDeployConnector']
  : T extends 'beemAgentFivetranUndeployConnector'
  ? BeemAgentFivetranUndeployConnectorQuery['beemAgentFivetranUndeployConnector']
  : T extends 'beemAgentAdminLogoutUser'
  ? BeemAgentAdminLogoutUserQuery['beemAgentAdminLogoutUser']
  : T extends 'fivetranDeleteConnector'
  ? FivetranDeleteConnectorQuery['fivetranDeleteConnector']
  : T extends 'getIDatasetUser'
  ? GetIDatasetUserQuery['getIDatasetUser']
  : T extends 'auth0CreateUser'
  ? Auth0CreateUserQuery['auth0CreateUser']
  : T extends 'auth0ChangePassword'
  ? Auth0ChangePasswordQuery['auth0ChangePassword']
  : T extends 'auth0UpdateUserMetadata'
  ? Auth0UpdateUserMetadataQuery['auth0UpdateUserMetadata']
  : never;

type ResultMutation0<T extends Mutation0> = T extends 'createUser'
  ? CreateUserMutation['createUser']
  : T extends 'updateUser'
  ? UpdateUserMutation['updateUser']
  : T extends 'createOrganization'
  ? CreateOrganizationMutation['createOrganization']
  : T extends 'updateOrganization'
  ? UpdateOrganizationMutation['updateOrganization']
  : T extends 'createOrganizationUser'
  ? CreateOrganizationUserMutation['createOrganizationUser']
  : T extends 'updateOrganizationUser'
  ? UpdateOrganizationUserMutation['updateOrganizationUser']
  : T extends 'deleteOrganizationUser'
  ? DeleteOrganizationUserMutation['deleteOrganizationUser']
  : T extends 'createOrganizationFivetran'
  ? CreateOrganizationFivetranMutation['createOrganizationFivetran']
  : T extends 'createWorkspace'
  ? CreateWorkspaceMutation['createWorkspace']
  : T extends 'updateWorkspace'
  ? UpdateWorkspaceMutation['updateWorkspace']
  : never;

type ResultMutation1<T extends Mutation1> = T extends 'createWorkspaceUser'
  ? CreateWorkspaceUserMutation['createWorkspaceUser']
  : T extends 'deleteWorkspaceUser'
  ? DeleteWorkspaceUserMutation['deleteWorkspaceUser']
  : T extends 'createSource'
  ? CreateSourceMutation['createSource']
  : T extends 'updateSource'
  ? UpdateSourceMutation['updateSource']
  : T extends 'deleteSource'
  ? DeleteSourceMutation['deleteSource']
  : T extends 'createSourceWorkspaceShare'
  ? CreateSourceWorkspaceShareMutation['createSourceWorkspaceShare']
  : T extends 'createDataObject'
  ? CreateDataObjectMutation['createDataObject']
  : T extends 'updateDataObject'
  ? UpdateDataObjectMutation['updateDataObject']
  : T extends 'deleteDataObject'
  ? DeleteDataObjectMutation['deleteDataObject']
  : T extends 'createDataset'
  ? CreateDatasetMutation['createDataset']
  : never;

type ResultMutation2<T extends Mutation2> = T extends 'updateDataset'
  ? UpdateDatasetMutation['updateDataset']
  : T extends 'deleteDataset'
  ? DeleteDatasetMutation['deleteDataset']
  : T extends 'createDatasetTest'
  ? CreateDatasetTestMutation['createDatasetTest']
  : T extends 'updateDatasetTest'
  ? UpdateDatasetTestMutation['updateDatasetTest']
  : T extends 'deleteDatasetTest'
  ? DeleteDatasetTestMutation['deleteDatasetTest']
  : T extends 'createDatasetShareFromWorkspaceWithWorkspaceLink'
  ? CreateDatasetShareFromWorkspaceWithWorkspaceLinkMutation['createDatasetShareFromWorkspaceWithWorkspaceLink']
  : T extends 'deleteDatasetShareFromWorkspaceWithWorkspaceLink'
  ? DeleteDatasetShareFromWorkspaceWithWorkspaceLinkMutation['deleteDatasetShareFromWorkspaceWithWorkspaceLink']
  : T extends 'createDatasetWorkspaceShare'
  ? CreateDatasetWorkspaceShareMutation['createDatasetWorkspaceShare']
  : T extends 'deleteDatasetWorkspaceShare'
  ? DeleteDatasetWorkspaceShareMutation['deleteDatasetWorkspaceShare']
  : T extends 'createDatasetSqlQuery'
  ? CreateDatasetSqlQueryMutation['createDatasetSqlQuery']
  : never;

type ResultMutation3<T extends Mutation3> = T extends 'updateDatasetSqlQuery'
  ? UpdateDatasetSqlQueryMutation['updateDatasetSqlQuery']
  : T extends 'deleteDatasetSqlQuery'
  ? DeleteDatasetSqlQueryMutation['deleteDatasetSqlQuery']
  : T extends 'createFlow'
  ? CreateFlowMutation['createFlow']
  : T extends 'updateFlow'
  ? UpdateFlowMutation['updateFlow']
  : T extends 'deleteFlow'
  ? DeleteFlowMutation['deleteFlow']
  : T extends 'createIntegration'
  ? CreateIntegrationMutation['createIntegration']
  : T extends 'updateIntegration'
  ? UpdateIntegrationMutation['updateIntegration']
  : T extends 'deleteIntegration'
  ? DeleteIntegrationMutation['deleteIntegration']
  : T extends 'createConnection'
  ? CreateConnectionMutation['createConnection']
  : T extends 'updateConnection'
  ? UpdateConnectionMutation['updateConnection']
  : never;

type ResultMutation4<T extends Mutation4> = T extends 'deleteConnection'
  ? DeleteConnectionMutation['deleteConnection']
  : T extends 'createFivetranConnection'
  ? CreateFivetranConnectionMutation['createFivetranConnection']
  : T extends 'updateFivetranConnection'
  ? UpdateFivetranConnectionMutation['updateFivetranConnection']
  : T extends 'deleteFivetranConnection'
  ? DeleteFivetranConnectionMutation['deleteFivetranConnection']
  : T extends 'createCredentials'
  ? CreateCredentialsMutation['createCredentials']
  : T extends 'updateCredentials'
  ? UpdateCredentialsMutation['updateCredentials']
  : T extends 'deleteCredentials'
  ? DeleteCredentialsMutation['deleteCredentials']
  : T extends 'createFolder'
  ? CreateFolderMutation['createFolder']
  : T extends 'updateFolder'
  ? UpdateFolderMutation['updateFolder']
  : T extends 'deleteFolder'
  ? DeleteFolderMutation['deleteFolder']
  : never;

type ResultMutation5<T extends Mutation5> = T extends 'createDownload'
  ? CreateDownloadMutation['createDownload']
  : T extends 'updateDownload'
  ? UpdateDownloadMutation['updateDownload']
  : T extends 'deleteDownload'
  ? DeleteDownloadMutation['deleteDownload']
  : T extends 'createRole'
  ? CreateRoleMutation['createRole']
  : T extends 'updateRole'
  ? UpdateRoleMutation['updateRole']
  : T extends 'deleteRole'
  ? DeleteRoleMutation['deleteRole']
  : T extends 'createPermission'
  ? CreatePermissionMutation['createPermission']
  : T extends 'updatePermission'
  ? UpdatePermissionMutation['updatePermission']
  : T extends 'deletePermission'
  ? DeletePermissionMutation['deletePermission']
  : T extends 'beemAgentInvokeDownload'
  ? BeemAgentInvokeDownloadMutation['beemAgentInvokeDownload']
  : never;

type ResultMutation6<T extends Mutation6> = T extends 'beemAgentDeployDatasetSqlQuery'
  ? BeemAgentDeployDatasetSqlQueryMutation['beemAgentDeployDatasetSqlQuery']
  : T extends 'fivetranCreateGroup'
  ? FivetranCreateGroupMutation['fivetranCreateGroup']
  : T extends 'modifyFlow'
  ? ModifyFlowMutation['modifyFlow']
  : T extends 'updateIDatasetUser'
  ? UpdateIDatasetUserMutation['updateIDatasetUser']
  : T extends 'createIDatasetUser'
  ? CreateIDatasetUserMutation['createIDatasetUser']
  : T extends 'deleteUser'
  ? DeleteUserMutation['deleteUser']
  : T extends 'deleteIDatasetUser'
  ? DeleteIDatasetUserMutation['deleteIDatasetUser']
  : never;

type ResultSubscription0<T extends Subscription0> = T extends 'onCreatePipelineResult'
  ? OnCreatePipelineResultSubscription['onCreatePipelineResult']
  : T extends 'onCreateInsightMessage'
  ? OnCreateInsightMessageSubscription['onCreateInsightMessage']
  : never;

export type Result<T extends Query | Mutation | Subscription> = T extends Query0
  ? ResultQuery0<T>
  : T extends Query1
  ? ResultQuery1<T>
  : T extends Query2
  ? ResultQuery2<T>
  : T extends Query3
  ? ResultQuery3<T>
  : T extends Query4
  ? ResultQuery4<T>
  : T extends Query5
  ? ResultQuery5<T>
  : T extends Query6
  ? ResultQuery6<T>
  : T extends Query7
  ? ResultQuery7<T>
  : T extends Query8
  ? ResultQuery8<T>
  : T extends Query9
  ? ResultQuery9<T>
  : T extends Mutation0
  ? ResultMutation0<T>
  : T extends Mutation1
  ? ResultMutation1<T>
  : T extends Mutation2
  ? ResultMutation2<T>
  : T extends Mutation3
  ? ResultMutation3<T>
  : T extends Mutation4
  ? ResultMutation4<T>
  : T extends Mutation5
  ? ResultMutation5<T>
  : T extends Mutation6
  ? ResultMutation6<T>
  : T extends Subscription0
  ? ResultSubscription0<T>
  : never;
