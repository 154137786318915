// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/codebuild/output/src3887759557/src/app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelConnection0 from '/codebuild/output/src3887759557/src/app/src/models/connection.ts';
import ModelCredentials1 from '/codebuild/output/src3887759557/src/app/src/models/credentials.ts';
import ModelDataObjects2 from '/codebuild/output/src3887759557/src/app/src/models/dataObjects.ts';
import ModelDataset3 from '/codebuild/output/src3887759557/src/app/src/models/dataset.ts';
import ModelDatasetTest4 from '/codebuild/output/src3887759557/src/app/src/models/datasetTest.ts';
import ModelDownload5 from '/codebuild/output/src3887759557/src/app/src/models/download.ts';
import ModelEvents6 from '/codebuild/output/src3887759557/src/app/src/models/events.ts';
import ModelFlows7 from '/codebuild/output/src3887759557/src/app/src/models/flows.ts';
import ModelFolders8 from '/codebuild/output/src3887759557/src/app/src/models/folders.ts';
import ModelGlobal9 from '/codebuild/output/src3887759557/src/app/src/models/global.js';
import ModelHub10 from '/codebuild/output/src3887759557/src/app/src/models/hub.ts';
import ModelInsight11 from '/codebuild/output/src3887759557/src/app/src/models/insight.ts';
import ModelIntegration12 from '/codebuild/output/src3887759557/src/app/src/models/integration.js';
import ModelLogin13 from '/codebuild/output/src3887759557/src/app/src/models/login.ts';
import ModelNotifications14 from '/codebuild/output/src3887759557/src/app/src/models/notifications.ts';
import ModelOrganization15 from '/codebuild/output/src3887759557/src/app/src/models/organization.ts';
import ModelOverlay16 from '/codebuild/output/src3887759557/src/app/src/models/overlay.ts';
import ModelSetting17 from '/codebuild/output/src3887759557/src/app/src/models/setting.js';
import ModelSources18 from '/codebuild/output/src3887759557/src/app/src/models/sources.ts';
import ModelUser19 from '/codebuild/output/src3887759557/src/app/src/models/user.js';
import ModelUser220 from '/codebuild/output/src3887759557/src/app/src/models/user2.ts';
import ModelWorkspace21 from '/codebuild/output/src3887759557/src/app/src/models/workspace.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'connection', ...ModelConnection0 });
app.model({ namespace: 'credentials', ...ModelCredentials1 });
app.model({ namespace: 'dataObjects', ...ModelDataObjects2 });
app.model({ namespace: 'dataset', ...ModelDataset3 });
app.model({ namespace: 'datasetTest', ...ModelDatasetTest4 });
app.model({ namespace: 'download', ...ModelDownload5 });
app.model({ namespace: 'events', ...ModelEvents6 });
app.model({ namespace: 'flows', ...ModelFlows7 });
app.model({ namespace: 'folders', ...ModelFolders8 });
app.model({ namespace: 'global', ...ModelGlobal9 });
app.model({ namespace: 'hub', ...ModelHub10 });
app.model({ namespace: 'insight', ...ModelInsight11 });
app.model({ namespace: 'integration', ...ModelIntegration12 });
app.model({ namespace: 'login', ...ModelLogin13 });
app.model({ namespace: 'notifications', ...ModelNotifications14 });
app.model({ namespace: 'organization', ...ModelOrganization15 });
app.model({ namespace: 'overlay', ...ModelOverlay16 });
app.model({ namespace: 'setting', ...ModelSetting17 });
app.model({ namespace: 'sources', ...ModelSources18 });
app.model({ namespace: 'user', ...ModelUser19 });
app.model({ namespace: 'user2', ...ModelUser220 });
app.model({ namespace: 'workspace', ...ModelWorkspace21 });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (typeof window !== 'undefined') {
      _onCreate();
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
