import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { cognitoUserPoolId, cognitoUserPoolClientId } from '@/aws-config';

declare const REACT_APP_AUTH0_COGNITO_DOMAIN: string;
declare const REACT_APP_AUTH0_REDIRECT_URI: string;

const addUrlParams = (urlPath: string, redirect?: string): string => {
  let url = `${REACT_APP_AUTH0_COGNITO_DOMAIN}${urlPath}`;
  url = `${url}?client_id=${cognitoUserPoolClientId}`;
  url = `${url}&response_type=code`;
  url = `${url}&scope=aws.cognito.signin.user.admin+openid+email+profile`;
  url = `${url}&redirect_uri=${encodeURIComponent(REACT_APP_AUTH0_REDIRECT_URI)}`;
  if (redirect) url = `${url}&state=${encodeURIComponent(btoa(JSON.stringify({ redirect })))}`;
  return url;
};

export const getAuthorizeURL = (redirect?: string): string => {
  return addUrlParams('oauth2/authorize', redirect);
};

export const getLogoutURL = (redirect?: string): string => {
  return addUrlParams('logout', redirect);
};

type Tokens = {
  idToken: string;
  accessToken: string;
  refreshToken: string;
};

export const exchangeAuthorizationCodeForTokens = async (code: string): Promise<Tokens> => {
  const resp = await fetch(`${REACT_APP_AUTH0_COGNITO_DOMAIN}oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: cognitoUserPoolClientId,
      code,
      redirect_uri: REACT_APP_AUTH0_REDIRECT_URI,
    }),
  });
  const data = await resp.json();
  return {
    idToken: data.id_token,
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
  };
};

export const authorizeByTokens = async (
  tokens: Tokens,
): Promise<AmazonCognitoIdentity.CognitoUser> => {
  // create a CognitoAccessToken using the response accessToken
  const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: tokens.accessToken,
  });

  // create a CognitoIdToken using the response idToken
  const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: tokens.idToken,
  });

  // create a RefreshToken using the response refreshToken
  const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: tokens.refreshToken,
  });

  // create a session object with all the tokens
  const sessionData = {
    IdToken,
    AccessToken,
    RefreshToken,
  };

  // create the CognitoUserSession using the sessionData
  const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

  // create an object with the UserPoolId and ClientId
  const poolData = {
    UserPoolId: cognitoUserPoolId,
    ClientId: cognitoUserPoolClientId,
  };

  // pass the poolData object to CognitoUserPool
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  // create an object containing the username and user pool.
  // You can get the username from CognitoAccessToken object
  // we created above.
  const userData = {
    Username: AccessToken.payload.username,
    Pool: userPool,
  };

  // create a cognito user using the userData object
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  // set the cognito user session w/ the CognitoUserSession
  cognitoUser.setSignInUserSession(session);

  return cognitoUser;
};
