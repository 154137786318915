export default {
  "workspace": {
    "member_list": {
      "title": "{name} members",
      "name": "Members",
      "add_button": "Add member",
      "menu": {
        "remove": "Remove"
      }
    },
    "add_member_modal_dialog": {
      "title": "Add Members to {workspace}",
      "filter_input": "Filter organization members",
      "email_input": "Invite by email address",
      "add_existing_user": "Add Existing User",
      "invite_new_user": "Invite New User",
      "add_button": "Add {userAmount} user(s)",
      "invite_button": "Invite {userAmount} user(s)",
      "enter_email": "Enter user email and press enter",
      "no_result": "No Results.",
      "person_not_here": "The person you are looking for is not in your organization yet.",
      "recently_added": "Recently added to organization",
      "remove": "Remove",
      "add": "Add"
    }
  },
  "workspaceMember": {
    "delete_modal_dialog": {
      "title": "Remove member {name} ?",
      "description": "Are you sure you want to remove this member?",
      "submit_button": "Remove member"
    }
  }
};
